import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n, { fetchAllTranslations } from "../../../web/src/utilities/i18n";
const DEFAULT_LANGUAGE = "en-GB";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  isMobile?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLangSettingDrawerOpened: boolean;
  selectedLanguage: string,
  languageOptions:any;
  updatedLangCode:string;
  isLoading:boolean;
  isLangUpdate:boolean;
  displayName:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LanguageSupportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getLanguages:string="";
  updateLanguageRequestId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLangSettingDrawerOpened: false,
      selectedLanguage: DEFAULT_LANGUAGE,
      languageOptions:[],
      updatedLangCode:"",
      isLoading:false,
      isLangUpdate:false,
      displayName:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
          console.log(responseJson.error);
      } else {
        switch (apiRequestCallId) {
          case this.getLanguages:
            this.setState({languageOptions:responseJson});
            this.setState({isLoading: false})
            const lang = localStorage.getItem("lang") || DEFAULT_LANGUAGE;
            const selectedLang = responseJson.find((langObj:any) => langObj.code === lang);
            const languageName = selectedLang ? selectedLang.display_name : "English (UK)";
            this.setState({displayName:languageName})
            break; 
          case this.updateLanguageRequestId:
            this.updateLanguageResponse(responseJson)
            break;                        
          default:
            this.parseApiCatchErrorResponse(errorReponse);
            break;
        }
      }
    }
    // Customizable Area End
  } 
  // Customizable Area Start
  async componentDidMount() {
    this.getAllLanguages();
    const lang = localStorage.getItem("lang") || DEFAULT_LANGUAGE;
    await i18n.changeLanguage(lang);   
    this.setState({ selectedLanguage: lang})   
  }
  updateLanguageResponse= async (responseJson:any)=>{
    this.setState({updatedLangCode:responseJson.preferred_locale})
    localStorage.setItem('lang', responseJson.preferred_locale);
    await i18n.changeLanguage(responseJson.preferred_locale);
    fetchAllTranslations()
    this.setState({isLoading: false})
    this.handleLangPopupClose();
  }
  toggleLangSettingsDrawer = () => {
    this.setState({
      isLangSettingDrawerOpened: true,
    });
  };

  closeSettingDrawer = () => {
    this.setState({
      isLangSettingDrawerOpened: false,
    });
  };

  handleLanguageChange = (value:any,display_name:string) => {
    this.setState({ selectedLanguage: value , displayName: display_name });    
  };
  handleLangPopup = ()=>{
    this.setState({isLangUpdate:true})
  }
  handleLangPopupClose = ()=>{
    this.setState({isLangUpdate:false})
  }
  updateLanguage = ()=>{
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    let body={
      "data":{
          "preferred_locale":this.state.selectedLanguage
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateLanguageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTranslations
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({isLoading: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);   
  }

  getAllLanguages = () => {
      const header = {
        token: this.userToken.meta.token,
      };
      let endPoint = `${configJSON.translation}`;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getLanguages = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.setState({isLoading: true})
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  goBack = () => {
    window.history.go(-1); 
  };
  // Customizable Area End
}
