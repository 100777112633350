// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import {
  Typography,
  Modal,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Button,
  Switch,
} from "@material-ui/core";
import { configJSON } from "../../blocks/LiveChat2/src/LiveChat2Controller.web";
import {
  browse,
  close,
  etohSupportImg,
  invitemenberImg,
  sortby,
} from "../../blocks/LiveChat2/src/assets";
import CustomInputContent from "./CustomInput.web";
import { SubscribedUser } from "./ReusableEnums";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface CustomInputProps {
  modalOpen: boolean;
  modalClose: () => void;
  classes: Record<string, string>;
  subscribedUserData: Array<SubscribedUser>;
  t: any;
  handleAllInputChange: (event: any) => void;
  userSearch: string;
  userSortBy: string;
  openExternalChat: boolean;
  closeExternalChat: () => void;
  email: string;
  emailError: string;
  handleInviteMember: () => void;
  isSortModalOpen: boolean;
  createExternalChatEvent: () => void;
  openExternalChatEvent: () => void;
  closeEmailVarification: () => void;
  openEmailvarification: boolean;
  createExternalAndInternalChatEvent: (data:any,type:string)=>void;
  createEtohSupportChatEvent: ()=>void;
  inviteMemberEvent:()=> void;
  openSortModal?:()=>void;
  closeSortModal?:()=>void;
}

const NewChatModal = (props: CustomInputProps) => {
  const {
    modalOpen,
    closeExternalChat,
    createExternalChatEvent,
    handleInviteMember,
    openExternalChat,
    email,
    emailError,
    classes,
    modalClose,
    userSearch,
    subscribedUserData,
    handleAllInputChange,
    t,
    userSortBy,
    openExternalChatEvent,
    closeEmailVarification,
    openEmailvarification,
    createExternalAndInternalChatEvent,
    createEtohSupportChatEvent,
    inviteMemberEvent,
    openSortModal,
    closeSortModal,
    isSortModalOpen,
  } = props;
  const MessageText =
    subscribedUserData?.length >= 2
      ? t(`${configJSON.membersTxt}`)
      : t(`${configJSON.memberTxt}`);

  return (
    <>
      <Modal
        className={classes.modalCreateDialog}
        aria-labelledby="modal-modal-title"
        onClose={modalClose}
        open={modalOpen}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalCreateDialogBox}`}>
          <Box className="modal-content">
            <Box className="modal-wrapper">
              <Typography className="modal-headding">
                {t(`${configJSON.newChatBtn}`)}
              </Typography>
              <Box
                className="close-icon"
                onClick={modalClose}
                data-test-id="closeNewChat"
              >
                <img src={close} alt="closeGray" />
              </Box>
            </Box>
            <Typography className="desc">
              {t(`${configJSON.newChatSubTxt}`)}
            </Typography>
            <Box className="search-wrapper">
              <Box className="search-box">
                <CustomInputContent
                  type="text"
                  placeholder={t(`${configJSON.searchPlaceholder}`)}
                  data-test-id="userSearch"
                  name="userSearch"
                  value={userSearch}
                  onChange={handleAllInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon style={{color:"#225980"}} />
                    </InputAdornment>
                  }
                />
                <Box onClick={openSortModal} data-test-id="handleFiltersOPen" className="sort-by">
                  <img src={sortby} alt="bg-image" className="filter-image" />
                </Box>
              </Box>
              <Modal
                  open={isSortModalOpen}
                  onClose={closeSortModal}
                  aria-labelledby="sort-modal-title"
                  aria-describedby="sort-modal-description"
                >
                  <Box
                    className={`view-modal-filter ${classes.modalFilter}`}>
                    <Box className="filter-content">
                      <Box className="filter-wrapper">
                        <Box className="filter-heading">
                          <Box className="heading-center">
                            <Typography className="filter-reset">
                              Reset
                            </Typography>
                            <Box onClick={closeSortModal} >
                              <img src={close} alt="close" />
                            </Box>
                          </Box>
                        </Box>
                        <Typography className="filters">
                          Filters
                        </Typography>
                        <Typography className="filters-activity">
                          Adjust activity log filters
                        </Typography>
                        <Typography className="filters-assigned">
                          Assigned to this project
                        </Typography>
                        <Box className="search-wrapper">
                          <Box className="search-box">
                            <CustomInputContent
                              type="text"
                              placeholder="Search project name"   
                              endAdornment={
                                <InputAdornment position="end">
                                  <SearchIcon style={{ color: "#94A3B8" }} />
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Box>
                        <Typography className="filters-team">
                          Team
                        </Typography>
                        <Box className="search-wrapper">
                          <Box className="search-box">
                            <CustomInputContent
                              type="text"
                              placeholder="Search and select a team"
                              data-test-id=""        
                              endAdornment={
                                <InputAdornment position="end">
                                  <SearchIcon style={{ color: "#94A3B8" }} />
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Box>
                        <Box className="atatchments-container">
                          <Typography className="atatchments">
                            Message contains attachments
                          </Typography>
                          <Switch defaultChecked color="default" />
                        </Box>
                        <Button className="button-filter">Apply Filters</Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
            </Box>
            <Box className="etoh-header" onClick={createEtohSupportChatEvent}>
              <Box className="etoh-img">
                <img src={etohSupportImg} alt="EtOH Support" />
              </Box>
              <Box className="etoh-content">
                <Typography className="support-txt">EtOH {t(`${configJSON.supportChat}`)}</Typography>
                <Typography className="support-sub-txt">
                  {t(`${configJSON.etohSupportSubText}`)}
                </Typography>
              </Box>
            </Box>
            <Box className="etoh-header" onClick={openExternalChatEvent}>
              <Box className="etoh-img">
                <img src={browse} alt="External Chat" />
              </Box>
              <Box className="etoh-content">
                <Typography
                  className="support-txt"
                 
                >
                  {t(`${configJSON.extenalChat}`)}
                </Typography>
                <Typography className="support-sub-txt">
                  {t(`${configJSON.externalChatSubText}`)}
                </Typography>
              </Box>
            </Box>
            <Box className="sort-container">
              <Typography className="conversion-count-text">
                {subscribedUserData?.length}&nbsp;{MessageText}
              </Typography>
              <FormControl className="select-control">
                <Select
                  inputProps={{ IconComponent: () => null }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  value={userSortBy}
                  name="userSortBy"
                  onChange={handleAllInputChange}
                  renderValue={(value) => {
                    return (
                      <Box>
                        {value}
                        <ExpandMoreIcon />
                      </Box>
                    );
                  }}
                  data-test-id="userSortBy"
                >                
                  <MenuItem value="A-Z">
                    {t(`${configJSON.aDashZTxt}`)}
                  </MenuItem>
                  <MenuItem value="Z-A">
                    {t(`${configJSON.zDashATxt}`)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {subscribedUserData?.map((item: SubscribedUser) => {
              const userName =
                item.attributes.first_name + " " + item.attributes.last_name;
              const intitials = `${item.attributes.first_name
                .charAt(0)
                .toUpperCase()}${item.attributes.last_name
                .charAt(0)
                .toUpperCase()}`;
              return (
                <Box className="etoh-header-chat" key={item.id} onClick={()=> createExternalAndInternalChatEvent(item,"internal")}>
                  <Box className="etoh-name">{intitials}</Box>
                  <Box className="etoh-content">
                    <Typography className="support-txt">{userName}</Typography>
                    <Typography className="support-sub-txt">
                      {item.attributes.email}
                    </Typography>
                    <Box>
                    {item.attributes.team && item.attributes.team.length > 0 && 
typeof item.attributes.team[0] === 'object' && 'title' in item.attributes.team[0] && (
    <Chip
      className={classes.chipButton}
      label={(item.attributes.team[0] as { title: string }).title}
    />
)}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
      <Box className={classes.externalChatModal}>
        <Modal
          open={openExternalChat}
          onClose={closeExternalChat}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialogChat}
        >
          <Box className={classes.modalDialogBox}>
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {t(`${configJSON.externalChatSubText}`)}
              </Typography>
              <Box data-test-id="closeExternalChat" onClick={closeExternalChat}>
                <img src={close} alt="close external" />
              </Box>
            </Box>
            <Typography className="modal-sub-title" component="h2">
              {t(`${configJSON.extenalChatHeadding}`)}
            </Typography>
            <Box className="modal-description">
              <CustomInputContent
                type="email"
                label={t(`${configJSON.emailAddressTxt}`)}
                name="email"
                value={email}
                onChange={handleAllInputChange}
                data-test-id="email"
                errors={emailError}
              />
              {emailError === "Member profile not found" &&
                <Box className="modal-footer">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  onClick={() => handleInviteMember()}
                  data-test-id="handleInviteMember"
                >
                  {t(`${configJSON.inviteMemberTxt}`)}
                </Button>
              </Box>}

              <Box className="modal-footer">
                <Button
                  className={ emailError === "Member profile not found" || email==="" ? `primary-btn  ${classes.diabledPrimaryButton}`:`primary-btn  ${classes.primaryButton}`}
                  onClick={createExternalChatEvent}
                  data-test-id="createExternalChatEvent"
                  disabled={
                    emailError === "Member profile not found" || email==="" ? true : false
                  }
                >
                  {t(`${configJSON.startChatTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      {openEmailvarification && (
        <Box className={classes.externalChatModal}>
          <Modal
            open={openEmailvarification}
            onClose={closeEmailVarification}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialogChat}
          >
            <Box className={classes.modalDialogBox}>
              <Box className="modal-heading-invite">
               <img className="invite-member-img" src={invitemenberImg} alt="invitemenberImg"></img>
                <Box
                  data-test-id="closeEmailVarification"
                  onClick={closeEmailVarification}
                >
                  <img src={close} alt="close external" />
                </Box>
              </Box>            
              <Box className="modal-description">
                <CustomInputContent
                  type="email"
                  label={t(`${configJSON.emailAddressTxt}`)}
                  name="email"
                  value={email}
                  onChange={handleAllInputChange}
                  data-test-id="email"
                />
                <Typography className="member-criteria">
                  {t(`${configJSON.memberCriteriaTxt}`)}
                </Typography>
                <Typography className="confirm-text">                 
                  {t(`${configJSON.subTxtForInvitaion}`)}
                </Typography>
                <Typography className="confirm-text-1">
                  <div className="points" /> {t(`${configJSON.ownerTxt}`)}
                </Typography>

                <Typography className="confirm-text-1">
                  <div className="points" />
                  {t(`${configJSON.employeeTxt}`)}
                </Typography>
                <Box className="modal-footer">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={inviteMemberEvent}
                    data-test-id="handleInviteMember"
                  >
                    {t(`${configJSON.inviteMemberTxt}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default NewChatModal;
// Customizable Area End
