import React from "react";
// Customizable Area Start
import {
  Box,
  Drawer,
  Typography,
  Button,
  Grid,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import ProfileSidebar from "../../../components/src/CustomProfileSidebar";
import { withStyles } from "@material-ui/core/styles";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { defaultView,backArrowIcon } from "./assets";
import OrderHistoryController, {
  Props,
  configJSON,
} from "./OrderHistoryController.web";
import OrderMangementTable from "../../../components/src/OrderManagementTable";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Section from "../../../components/src/OrderFilterSection.web";
import { ViewOrderDetails } from "./ViewOrderDetails.web";
import FillOrderDetails from "./FillOrderDetails.web";
import WebLoader from "../../../components/src/WebLoader.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
// Customizable Area End

export class OrderHistory extends OrderHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile } = this.props;
    const orderCount = this.state.orderHistoryData.length;
    const orderText =
      orderCount > 1
        ? this.t(`${configJSON.ordersTxt}`)
        : this.t(`${configJSON.orderTxt}`);
    const message = `${orderCount} ${orderText}`;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        {this.state.orderListData.length === 0 ? (
          <Box
            className={`wrapper ${classes.wrapper} ${classes.OrderDetailsWrapper}`}
          >
            <div style={{ position: "fixed", width: "100%", zIndex: 2 }}>
              <GlobalHeaderWeb />
            </div>
            <Box 
              style={{marginTop: "100px"}}
              className={`inner-wrapper ${classes.innerWrapper}`}
            >
              <Box className={classes.profileInnerWrapper}>
                <Box className="main-heading">
                  <Box className="back-btn-wrap">
                  <em><img alt="Arrow Back Icon" src={backArrowIcon}  /></em> 
                  </Box>
                  <Typography className="heading" variant="h1">
                    {this.t(`${configJSON.orderHistoryTxt}`)}
                  </Typography>
                  <Box
                    className="profile-menu"
                    onClick={this.toggleProfileDrawer}
                    data-test-id="toggleProfileDrawer"
                  >
                    <MenuIcon />
                  </Box>
                </Box>
                <Box className={classes.drawerWrapper}>
                  <Box className={classes.drawer}>
                    <Drawer
                      classes={{
                        paper: classes.drawerPaper,
                        root: classes.drawerRootWrapper,
                      }}
                      variant={isMobile ? "temporary" : "permanent"}
                      open={isMobile ? this.state.isProfileDrawerOpened : true}
                    >
                      <ProfileSidebar
                        toggleMenu={this.closeProfileDrawer}
                        data-test-id="closeProfileDrawer"
                        t={this.t}
                      />
                    </Drawer>
                  </Box>
                  <Box className={classes.drawerContent}>
                    <Box
                      className={`${classes.profileBlockOuter} mvc-profile-outer`}
                    >
                      <Box className="profile-block-wrapper">
                        <Box>
                          <img
                            className="default-image"
                            src={defaultView}
                            alt="defaultView"
                          ></img>
                        </Box>
                        <Box className="container-2">
                          <Typography className="heading">
                            {this.t(`${configJSON.defaultHeading}`)}
                          </Typography>
                          <Typography className="sub-heading">
                            {this.t(`${configJSON.subTxtDefault}`)}
                          </Typography>

                          <Button
                            className={`primary-btn ${classes.primaryButton}`}
                            onClick={this.goToEtohShop}
                            data-test-id="add-to-cart-btn"
                          >
                            {this.t(`${configJSON.buttonDefault}`)}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            className={`wrapper ${classes.wrapper} ${classes.OrderDetailsWrapper}`}
          >
            <Box className={classes.secondaryHeader}>
              <Box className="container d">
                <Box
                  onClick={this.handleBackBtn}
                  data-test-id="handleBackBtn"
                  className="image-wrapper"
                >
                  <em><img alt="Arrow Back Icon" src={backArrowIcon}  /></em> 
                  <Typography className="back-text t">
                    {this.t(`${configJSON.backText}`)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={`inner-wrapper ${classes.innerWrapper}`}>
              <Box className={classes.profileInnerWrapper}>
                <Box className="main-wrapper">
                  <Box className="top-main-wrapper">
                    <Box className="title-section">
                      <Box>
                        <Typography className="choose-item-txt">
                          {this.t(`${configJSON.orderHistoryTxt}`)}
                        </Typography>
                        <Typography className="choose-item-sub-txt">
                          {this.t(`${configJSON.subTitleForTable}`)}
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          onClick={this.goToEtohShop}
                          data-test-id="goToEtohShop-2"
                        >
                          {this.t(`${configJSON.browseShopTxt}`)}
                        </Button>
                      </Box>
                    </Box>
                    <Box className="filter-section">
                      <Grid container className="filter-section">
                        <Grid item md={3} lg={3} sm={3} xs={3}>
                          <Box className="left-box">
                            <Typography className="filter-txt">
                              {this.t(`${configJSON.filtersTxt}`)}
                            </Typography>
                            <Button
                              className="clear-all-btn"
                              data-test-id="clearAll"
                              onClick={this.handleClearAll}
                            >
                              {this.t(`${configJSON.clearAllTxt}`)}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item md={9} lg={9} sm={9} xs={9}>
                          <Box className="right-box">
                            <Box className="check-box">
                              <Typography className="selected-txt">
                                {message}
                              </Typography>
                            </Box>
                            <Box className="search-sort-box">
                              <CustomInputWeb
                                type="search"
                                value={this.state.overallOrderSearch}
                                name="overallOrderSearch"
                                label=""
                                placeholder={this.t(`${configJSON.serachTxt}`)}
                                onChange={this.handleInputChangeEvent}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon
                                      style={{ color: "rgb(131 128 128)" }}
                                    />
                                  </InputAdornment>
                                }
                                data-test-id="search-box"
                              />
                              <FormControl className="select-control sortby-control">
                                <Select
                                  displayEmpty
                                  inputProps={{ IconComponent: () => null }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      horizontal: "left",
                                      vertical: "bottom",
                                    },
                                    transformOrigin: {
                                      horizontal: "left",
                                      vertical: "top",
                                    },
                                    classes: { paper: classes.dropdownStyle2 },
                                    getContentAnchorEl: null,
                                  }}
                                  defaultValue={this.t(
                                    `${configJSON.sortByTxt}`
                                  )}
                                  onChange={this.handleSortByChange}
                                  renderValue={(value) => {
                                    return (
                                      <Box>
                                        {value}
                                        <ArrowDropDownIcon />
                                      </Box>
                                    );
                                  }}
                                  data-test-id="change-sort-products"
                                >                                 
                                  <MenuItem
                                    value={configJSON.recentlyOrderedTxt}
                                  >
                                    {this.t(`${configJSON.recentlyOrderedTxt}`)}
                                  </MenuItem>
                                  <MenuItem
                                    value={configJSON.recentStantusChangeTxt}
                                  >
                                    {this.t(
                                      `${configJSON.recentStantusChangeTxt}`
                                    )}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="main-content-wrapper">
                    <Box className="right-wrapper">
                      <Grid container spacing={3} className="grid-container">
                        <Grid
                          item
                          md={3}
                          lg={3}
                          sm={3}
                          xs={3}
                          className="left-side-info"
                        >
                          <Box className="left-wrapper">
                            <Section
                              title="Order Status"
                              name="orderStaus"
                              selectedCheckBox={this.state.orderStatus}
                              itemList={configJSON.statuses}
                              handleChecked={(id) =>
                                this.handleCheckedSelection(id, "orderStatus")
                              }
                              data-test-id="order-status"
                              t={this.t}
                            />
                            <Section
                              title="Date Ordered"
                              name="dateOrdered"
                              selectedCheckBox={this.state.dateOrdered}
                              itemList={configJSON.dates}
                              handleChecked={(id) =>
                                this.handleCheckedSelection(id, "dateOrdered")
                              }
                              data-test-id="date-ordered"
                              t={this.t}
                            />
                            <Section
                              title="Product Type"
                              name="orderType"
                              selectedCheckBox={this.state.orderType}
                              itemList={configJSON.orderType}
                              handleChecked={(id) =>
                                this.handleCheckedSelection(id, "orderType")
                              }
                              data-test-id="order-type"
                              t={this.t}
                            />
                          </Box>
                        </Grid>                      
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                          <Grid
                            container
                            className="right-side-info"
                            spacing={3}
                          >
                            <OrderMangementTable
                              data={this.state.orderHistoryData}
                              handleOpenViewDetails={this.handleOpenViewDetails}
                              handleOpenFillDetails={this.handleOpenFillDetails}
                              t={this.t}
                              data-test-id="handleOpenViewDetails"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {this.state.isViewDetails && (
          <ViewOrderDetails
            navigation="viewDetails"
            id="viewDetails"
            classes={classes}
            handleCloseViewDetails={this.handleCloseViewDetails}
            viewDetailsID={this.state.viewDetailsID}
            isViewDetails={this.state.isViewDetails}
            data-test-id="viewDetails"
          />
        )}
        {this.state.isFillDetails && (
          <FillOrderDetails
            navigation="fillDetails"
            id="fillDetails"
            classes={classes}
            handleCloseFillDetails={this.handleCloseFillDetails}
            viewDetailsID={this.state.viewDetailsID}
            isFillDetails={this.state.isFillDetails}
            data-test-id="fillDetails"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(OrderHistory)
);
// Customizable Area End
