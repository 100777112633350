import React from "react";
// Customizable Area Start
import {
  Modal,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ViewOrderDetailsController, {
  Props,
  configJSON,
} from "./ViewOrderDetailsController.web";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { deliverd } from "./assets";
import FillOrderDetails from "./FillOrderDetails.web";
import { ShoppingCartOrders } from "../../shoppingcart/src/ShoppingCartOrders.web";
// Customizable Area End

export class ViewOrderDetails extends ViewOrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, handleCloseViewDetails, isViewDetails } = this.props;
    const { orderDetailsData } = this.state;
    const orderStatusHistory = orderDetailsData.attributes.status_history;
    
    let statusClass = "";

    switch (orderDetailsData.attributes.status) {
      case "In progress":
        statusClass = classes.inProgress;
        break;
      case "Order placed":
        statusClass = classes.orderPlaced;
        break;
      case "Shipped":
        statusClass = classes.shipped;
        break;
      case "Delivered":
        statusClass = classes.delivered;
        break;
      case "On hold":
        statusClass = classes.onHold;
        break;
      default:
        break;
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Modal
          open={isViewDetails}
          onClose={handleCloseViewDetails}
          className={classes.modalBoxCenter}
        >
          <Box className={classes.viewOrderDetailsModal}>
            <Box className="view-container">
              <Box className="header-container">
                <Box className="modal-title-box">
                  <Typography className="title-txt">
                    {this.translateText(`${configJSON.orderDetailsHeading}`)}
                  </Typography>
                </Box>
                <Box className="heading-bar">
                  <CloseIcon
                    className="close-icon"
                    onClick={handleCloseViewDetails}
                  />
                </Box>
              </Box>
              <Box className="order-heading-bar">
                <Box className="order-details">
                  <Typography className="order-id">
                    Order ID: {orderDetailsData.id}
                  </Typography>
                  <Box className="status-item">
                    <Box className={`${classes.status} ${statusClass}`} />
                    <Typography className="status-name">
                      {orderDetailsData.attributes.status}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography className="time-date">
                {orderDetailsData.attributes.total_items > 1
                  ? `${
                      orderDetailsData.attributes.total_items
                    } ${this.translateText(`${configJSON.itemsTxt}`)}`
                  : `${
                      orderDetailsData.attributes.total_items
                    } ${this.translateText(`${configJSON.itemTxt}`)}`}
                , €{orderDetailsData.attributes.total_fees?.toFixed(2)}&nbsp;
                {this.formatDate(orderDetailsData.attributes.created_at)}
              </Typography>
              {orderDetailsData.attributes.order_items.data.map(
                (item: {
                  id: string;
                  attributes: {
                    quantity: number;
                    catalogue_service: {
                      data: {
                        attributes: {
                          title: string;
                        };
                      };
                    };
                    price: number;
                  };
                }) => {
                  return (
                    <Box className="quantity-text" key={item.id}>
                      <Typography className="item">
                        {item.attributes.quantity}&nbsp;
                        {
                          item.attributes.catalogue_service.data.attributes
                            .title
                        }
                        &nbsp; X&nbsp;€{item.attributes.price?.toFixed(2)}
                      </Typography>
                      <Typography className="price">
                        €
                        {(
                          item.attributes.price * item.attributes.quantity
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  );
                }
              )}
              <Box className="line"></Box>
              <Box className="section-header">
                <Accordion data-test-id="accordian">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#94A3B8" }} />}
                    id="panel1a-header"
                    aria-controls="panel1a-content"
                  >
                    <Typography className="section-title">
                      {this.translateText(`Order Status`)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="accordion-details"
                    data-test-id="accordionDetails"
                  >
                    {Object.entries(orderStatusHistory)
                      .sort(([statusA], [statusB]) => {
                        const order = ["Order placed", "In progress", "On hold", "Cancelled", "Delivered"];
                        return order.indexOf(statusA) - order.indexOf(statusB);
                      })
                      .map(([status, date], index, array) => {
                        const [day, month, year] = date.split('-');
                        const parsedDate = new Date(`20${year}-${month}-${day}`);

                        const formattedDate = new Date(parsedDate).toLocaleDateString("en-GB", {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric'
                        });

                        let statusMessage = "";
                        switch (status) {
                          case "Order placed":
                            statusMessage = `Your order was placed on [ ${formattedDate} ].`;
                            break;
                          case "In progress":
                            statusMessage = `Our team started processing your order on [ ${formattedDate} ].`;
                            break;
                          case "On hold":
                            statusMessage = `Your order was put on hold on [ ${formattedDate} ].`;
                            break;
                          case "Cancelled":
                            statusMessage = `Your order was cancelled on [ ${formattedDate} ].`;
                            break;
                          case "Delivered":
                            statusMessage = `Your order was completed on [ ${formattedDate} ].`;
                            break;
                          default:
                            statusMessage = `${status} on ${formattedDate}.`;
                        }

                        return (
                          <Box className="wrap-items" key={status}>
                            <Box className="check-line">
                              {status === "Delivered" ? (
                                <img src={deliverd} className="color" alt="deliverd" />
                              ) : (
                                <CheckCircleIcon className="color" />
                              )}
                              {index < array.length - 1 && <Box className="line"></Box>}
                            </Box>
                            <Box className="order-container">
                              <Typography className="order-process">{status}</Typography>
                              <Typography className="date">{statusMessage}</Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box className="section-header" style={{ borderBottom: "none" }}>
                <Accordion data-test-id="accordian">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#94A3B8" }} />}
                    id="panel1a-header"
                    aria-controls="panel1a-content"
                  >
                    <Typography className="section-title">
                      {this.translateText(`Payment Summary`)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="accordion-details"
                    data-test-id="accordionDetails"
                  >
                    <Box className="quantity-text">
                      <Typography className="item">
                        {orderDetailsData.attributes.total_items > 1
                          ? `${
                              orderDetailsData.attributes.total_items
                            } ${this.translateText(`${configJSON.itemsTxt}`)}`
                          : `${
                              orderDetailsData.attributes.total_items
                            } ${this.translateText(`${configJSON.itemTxt}`)}`}
                      </Typography>
                      <Typography className="price">
                          €{orderDetailsData.attributes.total_fees?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box className="lines"></Box>
                    <Box className="quantity-text">
                      <Typography className="item">
                        {this.translateText(`${configJSON.totalPricePaidTxt}`)}
                      </Typography>
                      <Typography className="price">
                        €{orderDetailsData.attributes.total_fees?.toFixed(2)}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {orderDetailsData.attributes.status === "Order placed" &&
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  data-test-id="fill-details"
                  onClick={this.handleFillDetailsOpen}
                >
                  {this.translateText(`${configJSON.fillDetailsHeading}`)}
                </Button>}
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                data-test-id="order-again"
                onClick={this.orderAgain}
              >
                {this.translateText(`Order Again`)}
              </Button>
              <Box className="cancle-order-txt">
                <Typography className="contact-support">
                  {this.translateText(`${configJSON.etohSupportText}`)}&nbsp;
                  <span className="link-txt" data-test-id="createEtohSupportChatEvent" onClick={this.createEtohSupportChatEvent}>
                    EtOH {this.translateText(`${configJSON.Support}`)}
                  </span>.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
        {this.state.isFillDetails && (
          <FillOrderDetails
            navigation={undefined}
            id="isFillDetails"
            handleCloseFillDetails={this.handleFillDetailsClose}
            viewDetailsID={this.props.viewDetailsID}
            isFillDetails={this.state.isFillDetails}
            //@ts-ignore
            classes={this.props.classes}
            data-test-id="FillOrderDetails"
          />
        )}
        {this.state.isCartOpen && (
          <ShoppingCartOrders
            navigation={undefined}
            id={""}
            classes={this.props.classes}
            isCartOpen={this.state.isCartOpen}
            handleCloseCartEvent={this.handleCloseCart}
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(ViewOrderDetails)
);
// Customizable Area End
