// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import InviteMembersController, { Props ,configJSON } from "./InviteMembersController.web";
import AppHeader from "../../../../components/src/AppHeader.web";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import AuthLeft from "../../../../components/src/AuthLeft.web";
import { customStyles } from "./TeamsStyles.web";
import FreeSoloCreateOption from "../../../../components/src/FreeSoloCreateOption.web";
import ConfirmActionModal from "./ConfirmActionModal.web";
import WebLoader from "../../../../components/src/WebLoader.web";
import SubscriptionScreenWeb from "./SubscriptionScreen.web";

class InviteMembers extends InviteMembersController {
  constructor(props: Props) {
    super(props);
  }

  renderModal=()=>{
    return(
      <Box>
        <ConfirmActionModal
        type ='invite'
        onCancel={this.onBack}
            isOpen={this.state.isSubscriptionModal}
            handleClose={this.handleCloseCartEvent}
            modalConfirmAction={this.handleCloseCartEvent}
            modalMessage={`To complete the addition of this member, a subscription is necessary. Please proceed with the payment to continue.`}
            confirmBtnTxt={"Add Members"}
            modalHeading={"Subscription Required"}
            cancelBtnTxt={"Don’t Add Members"}
            data-testid = "confirmOption"
          />
      </Box>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <>
      {this.renderModal()}
       {this.state.isLoading && <WebLoader />}
        <Box className={`${classes.authMainWrapper}`}>
          <Box className={`wrapper ${classes.wrapper}`}>
            <AppHeader />
            <Box className={classes.innerWrapper}>
              <Box className={classes.authWrapper}>
                <Box className="auth-inner-block invite-member-block">
                  <AuthLeft />
                  <Box className="auth-right">
                    <Box className="right-inner">
                      <Box className="back-logout-wrap">
                        <Box
                          className="back-btn-wrap"
                          data-testid = "backButton"
                          onClick={this.handleBack}
                        >
                          <i>
                            <svg
                              className="MuiSvgIcon-root"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                            </svg>
                          </i>
                        </Box>
                      </Box>
                      <Box className="right-inner-content">
                        <Typography className="heading" variant="h1">
                          {this.t(`${configJSON.inviteMemberTxt}`)}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.t(`${configJSON.inviteMemeberSubTxt}`)}
                        </Box>

                        <List className={classes.memberList}>
                          {this.state.addedMembers.map((val: any) => {
                            return (
                              <>
                                {val.rowIndex ==
                                this.state.editMember.rowIndex ? (
                                  <Box className="form-border">
                                    <Box component="form">
                                      <Box className="form-info-wrapper invite-info-wrapper">
                                        <Box className="form-row">
                                          <Box className="form-col col6">
                                            <CustomInputWeb
                                              type="text"
                                              label={this.t(`${configJSON.labelFirstName}`)}
                                              value={
                                                this.state.editMember.fName
                                              }
                                              errors={
                                                this.state.editError.fName
                                              }
                                              name="fName"
                                              onChange={this.handleEditChange}
                                            />
                                          </Box>
                                          <Box className="form-col col6">
                                            <CustomInputWeb
                                              type="text"
                                              label={this.t(`${configJSON.lastName}`)}
                                              value={
                                                this.state.editMember.lName
                                              }
                                              errors={
                                                this.state.editError.lName
                                              }
                                              name="lName"
                                              onChange={this.handleEditChange}
                                            />
                                          </Box>
                                        </Box>
                                        <Box className="form-row">
                                          <Box className="form-col">
                                            <CustomInputWeb
                                              type="email"
                                              label={this.t(`${configJSON.Email}`)}
                                              value={
                                                this.state.editMember.email
                                              }
                                              onBlur={this.handleBlur}
                                              errors={
                                                this.state.editError.email
                                              }
                                              name="email"
                                              onChange={this.handleEditChange}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                      <FreeSoloCreateOption
                                        options={this.state.teams.map((val) => {
                                          return {
                                            id: val.id,
                                            title: val.attributes.title,
                                          };
                                        })}
                                        addEditCallback={this.addEditTeam}
                                        deleteCallback={this.deleteTeam}
                                        noOptionTitle={this.t(`${configJSON.ownerTeamNameHyperText}`)}
                                        label={this.t(`${configJSON.chooseTeamSmall}`)}
                                        addLinkTitle={this.t(`${configJSON.ownerCreateTeamNameText}`)}
                                        id="team-id"
                                        value={this.state.editMember.team}
                                        onChange={this.editTeamChangeHandler}
                                        errorMsgNoRecord={this.t(`${configJSON.ownerTeamNameErrorText}`)}
                                        errors={this.state.editError.team}
                                      />

                                      <Box className="form-info-wrapper">
                                        <Typography
                                          className="form-heading"
                                          variant="h2"
                                        >
                                          {this.t(`${configJSON.txtChooseRole}`)}
                                        </Typography>
                                        <FormControl
                                          component="fieldset"
                                          className={`${classes.radioChoose} `}
                                          error={
                                            this.state.editError.role != ""
                                          }
                                        >
                                          <RadioGroup
                                            defaultValue={
                                              this.state.editMember.role
                                            }
                                            className="radio-group"
                                            name="role"
                                            onChange={this.handleEditChange}
                                            value={this.state.editMember.role}
                                          >
                                            <FormControlLabel
                                              value="0"
                                              control={<Radio />}
                                              label={this.t(`${configJSON.txtOwner}`)}
                                            />
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label={this.t(`${configJSON.txtManager}`)}
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label={this.t(`${configJSON.txtEmployee}`)}
                                            />
                                          </RadioGroup>
                                          <FormHelperText>
                                            {this.state.editError.role}
                                          </FormHelperText>
                                        </FormControl>
                                        <FreeSoloCreateOption
                                          options={this.state.jobTitles.map(
                                            (val) => {
                                              return {
                                                id: val.id,
                                                title: val.attributes.title,
                                              };
                                            }
                                          )}
                                          addEditCallback={this.addJobTitle}
                                          addLinkTitle={this.t(`${configJSON.ownerJobTitleHelperText}`)}
                                          noOptionTitle={this.t(`${configJSON.noJobTitleFound}`)} 
                                          label={this.t(`${configJSON.txtJobTitleSmall}`)}
                                          id="job-id"
                                          onChange={this.editTitleChangeHandler}
                                          value={this.state.editMember.jobTitle}
                                          errors={this.state.editError.jobTitle}
                                          deleteCallback={this.deleteJobTitle}
                                          errorMsgNoRecord={this.t(`${configJSON.noJobTitleCreated}`)}                                          
                                        />
                                        <Box className="form-row">
                                          <Box className="form-col">
                                            <CustomInputWeb
                                              type="number"
                                              label={this.t(`${configJSON.ownerHourText}`)}
                                              value={
                                                this.state.editMember
                                                  .hourlyPay || ""
                                              }
                                              errors={
                                                this.state.editError.hourlyPay
                                              }
                                              name="hourlyPay"
                                              onChange={this.handleEditChange}
                                              startAdornment={
                                                <InputAdornment position="start">
                                                  $
                                                </InputAdornment>
                                              }
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box className="team-button-wrapper">
                                        <Button
                                          className={`secondary-btn ${classes.secondaryButton}`}
                                          data-testid="deleteButton"
                                          onClick={this.deleteMember}
                                        >
                                          {this.t(`${configJSON.labelDeleteModalBtnTxt}`)}
                                        </Button>

                                        <Button
                                          className={`primary-btn ${classes.primaryButton}`}
                                          data-testid="updateButton"
                                          onClick={this.updateMembers}
                                        >
                                          {this.t(`${configJSON.labelwebRenameTeamBtntxt}`)}
                                        </Button>
                                        <ConfirmActionModal
                                          isOpen={this.state.isDeletingMember}
                                          handleClose={this.handleClose}
                                          modalConfirmAction={
                                            this.deleteMemberFromList
                                          }
                                          deleteTeamId=""
                                          modalMessage={this.t(`${configJSON.modalConfirmation}`)}
                                          confirmBtnTxt={this.t(`${configJSON.labelDeleteModalBtnTxt}`)}
                                          modalHeading={this.t(`${configJSON.deleteMember}`)}
                                          cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : (
                                  <ListItem
                                    onClick={this.editClickHandler(val)}
                                    key={val.rowIndex}
                                  >
                                    <ListItemText>
                                      <Box className="name-status">
                                        <Box component="p" className="name">
                                          {val.first_name} {val.last_name}
                                        </Box>
                                        <Box
                                          component="span"
                                          className={`status ${val.jobtypeName}`}
                                        >
                                          {val.jobtypeName}
                                        </Box>
                                      </Box>
                                      <Box component="span" className="email">
                                        {val.email}
                                      </Box>
                                      <Box className="memberlist-info">
                                        <Box component="span">
                                          {val.teamTitle}
                                        </Box>
                                        <Box component="span">
                                          {val.job_role}
                                        </Box>
                                        <Box component="span">
                                          {val.hourly_pay}
                                        </Box>
                                      </Box>
                                    </ListItemText>
                                  </ListItem>
                                )}
                              </>
                            );
                          })}
                        </List>
                        {/* <Box className="form-border"> */}
                        <Box component="form">
                          <Box className="form-info-wrapper invite-info-wrapper">
                            <Box className="form-row">
                              <Box className="form-col col6">
                                <CustomInputWeb
                                  isRequired
                                  type="text"
                                  label={this.t(`${configJSON.labelFirstName}`)}
                                  name="fName"
                                  value={this.state.fName}
                                  errors={this.state.error.fName}
                                  onChange={this.handleChange}
                                />
                              </Box>
                              <Box className="form-col col6">
                                <CustomInputWeb
                                  isRequired
                                  type="text"
                                  label={this.t(`${configJSON.lastName}`)}
                                  name="lName"
                                  value={this.state.lName}
                                  errors={this.state.error.lName}
                                  onChange={this.handleChange}
                                />
                              </Box>
                            </Box>
                            <Box className="form-row">
                              <Box className="form-col">
                                <CustomInputWeb
                                  isRequired
                                  type="email"
                                  label={this.t(`${configJSON.Email}`)}
                                  name="email"
                                  value={this.state.email}
                                  onBlur={this.handleBlur}
                                  errors={this.state.error.email}
                                  onChange={this.handleChange}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <FreeSoloCreateOption
                            options={this.state.teams.map((val) => {
                              return {
                                id: val.id,
                                title: val.attributes.title,
                              };
                            })}
                            addEditCallback={this.addEditTeam}
                            deleteCallback={this.deleteTeam}
                            addLinkTitle={this.t(`${configJSON.ownerCreateTeamNameText}`)}
                            noOptionTitle={this.t(`${configJSON.ownerTeamNameHyperText}`)}
                            label={this.t(`${configJSON.chooseTeamSmall}`)}
                            id="team-id"
                            onChange={this.teamChangeHandler}
                            value={this.state.team}
                            errors={this.state.error.team}
                            errorMsgNoRecord={this.t(`${configJSON.ownerTeamNameErrorText}`)}
                            data-testid="addTeamFreeSolo"
                          />
                          {this.state.isDeletingTeam && (
                            <ConfirmActionModal
                              isOpen={this.state.isDeletingTeam}
                              handleClose={this.handleClose}
                              modalConfirmAction={this.deleteTeamFromList}
                              deleteTeamId={this.state.deleteTeamId}
                              modalMessage={this.t(`${configJSON.modalTeamConfirmation}`)}
                              confirmBtnTxt={this.t(`${configJSON.labelDeleteModalBtnTxt}`)}
                              modalHeading={this.t(`${configJSON.deleteTeamTxt}`)}
                              data-testid="confirmDeleteTeam"
                              cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                            />
                          )}
                          <Box className="form-info-wrapper">
                            <Typography className="form-heading" variant="h2">
                              {this.t(`${configJSON.txtChooseRole}`)}
                            </Typography>
                            <FormControl
                              component="fieldset"
                              className={`${classes.radioChoose} ${
                                this.state.error.role ? "error-show" : ""
                              }`}
                              error={this.state.error.role != ""}
                            >
                              <RadioGroup
                                defaultValue="0"
                                className="radio-group"
                                name="role"
                                onChange={this.handleChange}
                                value={this.state.role}
                              >
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label= {this.t(`${configJSON.txtOwner}`)}
                                />
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label= {this.t(`${configJSON.txtManager}`)}
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label= {this.t(`${configJSON.txtEmployee}`)}
                                />
                              </RadioGroup>
                              <FormHelperText>
                                {this.state.error.role}
                              </FormHelperText>
                            </FormControl>
                            <FreeSoloCreateOption
                              options={this.state.jobTitles.map((val) => {
                                return {
                                  id: val.id,
                                  title: val.attributes.title,
                                };
                              })}
                              addEditCallback={this.addJobTitle}
                              addLinkTitle={this.t(`${configJSON.ownerJobTitleHelperText}`)}
                              noOptionTitle={this.t(`${configJSON.noJobTitleFound}`)} 
                              label={this.t(`${configJSON.txtJobTitleSmall}`)}
                              id="job-id"
                              onChange={this.titleChangeHandler}
                              value={this.state.jobTitle}
                              errors={this.state.error.jobTitle}
                              deleteCallback={this.deleteJobTitle}
                              errorMsgNoRecord={this.t(`${configJSON.noJobTitleCreated}`)} 
                            />
                            {this.state.isDeleting && (
                              <ConfirmActionModal
                                isOpen={this.state.isDeleting}
                                handleClose={this.handleClose}
                                modalConfirmAction={this.deleteJobTitleFromList}
                                deleteTeamId={this.state.deleteJobTitleId}
                                modalMessage={this.t(`${configJSON.deleteJobTitleConfirmationTxt}`)}
                                confirmBtnTxt={this.t(`${configJSON.labelDeleteModalBtnTxt}`)}
                                modalHeading={this.t(`${configJSON.jobTitleDeletTxt}`)}
                                data-testid="confirmDeleteTitle"
                                cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                              />
                            )}
                            <Box className="form-row">
                              <Box className="form-col">
                                <CustomInputWeb
                                  isRequired
                                  type="number"
                                  label={`${
                                    this.state.houryPayFocused ||
                                    this.state.hourlyPay
                                      ? this.t(`${configJSON.ownerHourText}`)
                                      : this.t(`${configJSON.hourlyPay}`)
                                  }`}
                                  value={this.state.hourlyPay ?? ""}
                                  errors={this.state.error.hourlyPay}
                                  name="hourlyPay"
                                  onChange={this.handleChange}
                                  onFocus={this.onFocusHourlyPay}
                                  onBlur={this.onBlurHourlyPay}
                                  startAdornment={
                                    this.state.houryPayFocused ||
                                    this.state.hourlyPay ? (
                                      <InputAdornment position="start">
                                        <p style={{ color: "#2b2b2b" }}>$</p>
                                      </InputAdornment>
                                    ) : null
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="team-button-wrapper">
                            <Button
                              className={`secondary-btn ${classes.secondaryButton}`}
                              onClick={this.addMembers}
                              data-testid="addAnother"
                            >
                              {this.t(`${configJSON.addAuthorTxt}`)}
                            </Button>
                            <Button
                              className={`primary-btn ${classes.primaryButton}`}
                              onClick={this.handleFinish}
                              data-testid="finisAndPay"
                            >                              
                              {this.t(`${configJSON.finishAndPayTxt}`)}
                            </Button>
                          </Box>
                        </Box>
                        {/* </Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {this.state.isCartOpen && (
          <SubscriptionScreenWeb
            navigation={undefined}
            id="subscription-screen-id"
            handleCloseCartEvent={this.handleCloseCart}
            isCartOpen={this.state.isCartOpen}
            invitedMember={this.state.currentTotalAddedMembers}
            responseMember={this.state.responseAddedMembers}
            data-test-id="subscription-screen"
          />
          )}
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(InviteMembers);
// Customizable Area End
