import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import { warningIcon } from "./assets";
import { CardMedia } from "@material-ui/core";
import i18n from "../../../web/src/utilities/i18n";
type QuestionType =
  | "Short Text"
  | "Long Text"
  | "Number"
  | "Single Choice"
  | "Multiple Choice"
  | "Attachments";
interface QuestionAttributes {
  id: number;
  question_type: QuestionType;
  title: string;
  options?: string[];
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  created_at: string;
  updated_at: string;
}
interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface OrderItemAttributes {
  price: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  catalogue_service: CatalogueService;
}

interface CatalogueService {
  data: ServiceData;
}

interface ServiceData {
  id: string;
  type: string;
  attributes: ServiceAttributes;
}

interface ServiceAttributes {
  id: number;
  SKU: string;
  title: string;
  etoh_shop_category_id: number;
  etoh_shop_sub_category_id: number;
  short_description: string;
  description: string;
  service_type: string;
  converted_price: string;
  converted_sale_price: string;
  converted_price_weekly: string | null;
  converted_sale_price_weekly: string | null;
  converted_price_monthly: string | null;
  converted_sale_price_monthly: string | null;
  converted_price_yearly: string | null;
  converted_sale_price_yearly: string | null;
  is_featured: boolean;
  buyers_additional_details: boolean;
  created_at: string;
  updated_at: string;
  thumbnails: Thumbnail[];
}
interface Thumbnail {
  id: number;
  filename: string;
  url: string;
}

export interface Question {
  id: string;
  type: string;
  attributes: QuestionAttributes;
}
export interface FileData {
  name: string;
  type: string;
  // Add other properties if needed
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    modalBoxCenter: string;
    fillOrderDetailsModal: string;
    primaryButton: string;
    uploadFileList: string;
    dropdownStyle: string;
    secondaryButton: string;
  };
  handleCloseFillDetails: () => void;
  viewDetailsID: string;
  isFillDetails: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filesUploaded: File[] | null;
  isCancel: boolean;
  fileSelected: FileList | null;
  isLoading: boolean;
  questionsData: Question[];
  selectedOptions: string[];
  selectedRadioOption: string;
  shortQuestionInput: string;
  longQuestionInput: string;
  selectedRadioOptionId: number;
  shortQuestionInputId: number;
  longQuestionInputId: number;
  attachmentId: number;
  searchValue: string;
  viewProductData: any;
  viewfilterProductData: any;
  currentItemIndex: number;
  isFillModalOpen: boolean;
  numberInput: number;
  numberInputId: number;
  multiChoiceID: number;
  openOrderDetails: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getQuestionRequestId: string = "";
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  submitAdditionalFormRequestId: string = "";
  getSingleProductDetailsRequestId: string = "";
  getSearcheddatarequestId: string = "";
  getSorteddatarequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      filesUploaded: [],
      isCancel: false,
      fileSelected: null,
      isLoading: false,
      questionsData: [],
      selectedOptions: [],
      selectedRadioOption: "",
      shortQuestionInput: "",
      longQuestionInput: "",
      selectedRadioOptionId: 0,
      shortQuestionInputId: 0,
      longQuestionInputId: 0,
      attachmentId: 0,
      searchValue: "",
      viewProductData: { attributes: { order_items: { data: [] } } },
      viewfilterProductData: [],
      currentItemIndex: 0,
      isFillModalOpen: false,
      numberInput: 0,
      numberInputId: 0,
      multiChoiceID: 0,
      openOrderDetails:false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        this.createToastNotificationError("Please fill details");
        return;
      } 
      switch (apiRequestCallId) {
        case this.getSingleProductDetailsRequestId:
          this.handleSingleProductDetailsResponse(responseJson);
          break;
    
        case this.getSearcheddatarequestId:
          this.handleSearchDataResponse(responseJson);
          break;
        case this.getSorteddatarequestId:
          this.handleSortDataResponse(responseJson);
          break;
        case this.getQuestionRequestId:
          this.handleQuestionsResponse(responseJson);
          break;
    
        case this.submitAdditionalFormRequestId:
          this.handleAdditionalFormSubmit(responseJson);
          break;
    
        default:
          this.parseApiCatchErrorResponse(errorResponse);
          break;
      }
    
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleSingleProductDetailsResponse(responseJson :any) {
    this.setState({ isLoading: false });
    if (responseJson.data) {
      this.setState({ viewProductData: responseJson.data });
    }
  }
  
  handleSearchDataResponse(responseJson :any) {
    this.setState({ viewfilterProductData: responseJson.order });
  }

  handleSortDataResponse(responseJson :any) {
    this.setState({viewfilterProductData:responseJson.order});
  }
  
  handleQuestionsResponse(responseJson :any) {
    this.setState({ questionsData: responseJson.data });
  }
  
  handleAdditionalFormSubmit(responseJson : any) {
    this.setState({ isLoading: false });
    if (responseJson.data) {
      this.createToastNotificationSuccesss("Your answers added successfully");
  
      const { currentItemIndex, viewProductData } = this.state;
      const products = viewProductData.attributes.order_items.data;
  
      if (currentItemIndex === products.length - 1) {
        this.handleFillDetailsClose();
        this.props.handleCloseFillDetails();
      } else {
        this.handleNextProduct();
      }
    }
  }
  
  async componentDidMount() {
    const lang = localStorage.getItem("lang") || "en";
    await i18n.changeLanguage(lang);
    this.getSingleEtohProduct();
  }

  translationEvent(key: string) {
    return i18n.t(key, { ns: "translation" });
  }
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  handleOnDrop = (files: FileList | null, attachmentId: number) => {
    if (files) {
      const filesArray = Array.from(files);
      this.setState((prevState) => ({
        filesUploaded: [...(prevState.filesUploaded || []), ...filesArray],
        attachmentId,
      }));
    }
  };
  cancelParsingAction = (index: number) => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }

    const updatedFiles = [...(this.state.filesUploaded || [])];
    updatedFiles.splice(index, 1);

    this.setState({
      isCancel: true,
      filesUploaded: updatedFiles,
      fileSelected: null,
    });
  };
  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };
  createToastNotificationSuccesss = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>
    );
  };
  getSingleEtohAdditionalQuestion = (viewQuestionId: number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const endpoint = `${configJSON.fillOrderDetailsApiPath}?item_id=${viewQuestionId}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuestionRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSingleEtohProduct = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const endpoint = `${configJSON.ordeApiPath}/${this.props.viewDetailsID}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleProductDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckboxChange = (option: string, multiId: number) => {
    const { selectedOptions } = this.state;
    const isSelected = selectedOptions.includes(option);
    if (isSelected) {
      this.setState({
        selectedOptions: selectedOptions.filter(
          (item: string) => item !== option
        ),
        multiChoiceID: multiId,
      });
    } else {
      this.setState({
        selectedOptions: [...selectedOptions, option],
        multiChoiceID: multiId,
      });
    }
  };

  handleRadioChange = (event: { target: { value: string } }, id: number) => {
    this.setState({
      selectedRadioOption: event.target.value,
      selectedRadioOptionId: id,
    });
  };

  handleAllInputChange = (
    event: {
      target: { name: string; value: string };
    },
    inputId: number
  ) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      [`${name}Id`]: inputId,
    }));
  };

  generateQuestionsArray = () => {
    const {
      selectedOptions,
      selectedRadioOption,
      selectedRadioOptionId,
      shortQuestionInput,
      longQuestionInput,
      filesUploaded,
      shortQuestionInputId,
      longQuestionInputId,
      attachmentId,
      multiChoiceID,
      numberInputId,
      numberInput,
    } = this.state;

    const questionTypes = [
      {
        type: "Number",
        answer: numberInput,
        question_id: numberInputId,
      },
      {
        type: "Single Choice",
        answer: selectedRadioOption,
        question_id: selectedRadioOptionId,
      },
      {
        type: "Short Text",
        answer: shortQuestionInput,
        question_id: shortQuestionInputId,
      },
      {
        type: "Long Text",
        answer: longQuestionInput,
        question_id: longQuestionInputId,
      },
      {
        type: "Multiple Choice",
        answer: selectedOptions,
        question_id: multiChoiceID,
      },   
    ];

    const filteredQuestionTypes = questionTypes.filter((question) => {
      if (question.type === "Number") {
        return !!numberInput;
      }
      if (question.type === "Single Choice") {
        return !!selectedRadioOption;
      }
      if (question.type === "Short Text") {
        return !!shortQuestionInput;
      }
      if (question.type === "Long Text") {
        return !!longQuestionInput;
      }
      if (question.type === "Multiple Choice") {
        return selectedOptions.length > 0;
      }    
      return false;
    });

    return filteredQuestionTypes.map((question) => ({
      type: question.type,
      correct_answer: question.answer,
      question_id: question.question_id,
    }));
  };

  handleSubmitAdditionalDetails = () => {
    const formData = new FormData();
    this.generateQuestionsArray().forEach((question: any) => {
      formData.append(`answers[][type]`, question.type);
      formData.append(`answers[][correct_answer]`, question.correct_answer);
      formData.append(`answers[][question_id]`, question.question_id);
    });
    formData.append("type", "Attachments");
    formData.append("question_id", this.state.attachmentId.toString());
    (this.state.filesUploaded || []).forEach((file:any) => {
      formData.append("files", file);
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      token: this.userToken.meta.token,
    };

    this.submitAdditionalFormRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.aditionalFormPath}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearch = (text: string) => {
    let orderId  = this.state.viewProductData.id;    
    this.setState({ searchValue: text });
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const endpoint = `bx_block_shopping_cart/search_order?search=${text}&id=${orderId}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearcheddatarequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSortBy = (event: React.ChangeEvent<{ value: unknown }>) => {
    let orderId = this.state.viewProductData.id;
    const selectedValue = event.target.value as string;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const endpoint = `bx_block_shopping_cart/orders/${orderId}?sort_by=${selectedValue}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSorteddatarequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  handleFillDetailsClick = (questionId: number) => {
    this.getSingleEtohAdditionalQuestion(questionId);
    this.getSingleEtohProduct();
    this.setState({ isFillModalOpen: true });
  };
  handleFillDetailsClose = () => {
    this.setState({ isFillModalOpen: false });
  };
  handleNextProduct = () => {
    this.setState({questionsData:[]});
    const { currentItemIndex, viewProductData } = this.state;
    const Products = viewProductData.attributes.order_items.data;   
    if (currentItemIndex < Products.length - 1) {
      this.setState((prevState) => ({
        currentItemIndex: prevState.currentItemIndex + 1,
      }));

      const nextProduct = Products[currentItemIndex + 1];    
      this.getSingleEtohAdditionalQuestion(parseInt(nextProduct?.id));
      this.clearState();
    }
  };
  clearState = () => {
    this.setState({
      selectedOptions: [],
      selectedRadioOption: "",
      selectedRadioOptionId: 0,
      shortQuestionInput: "",
      longQuestionInput: "",
      filesUploaded: [],
      shortQuestionInputId: 0,
      longQuestionInputId: 0,
      attachmentId: 0,
      multiChoiceID: 0,
      numberInputId: 0,
      numberInput: 0,
    });
  };

  handleOrderDetails = () =>{
    this.setState({openOrderDetails:true})
  }
  handleOrderDetailsClose = () =>{
    this.setState({openOrderDetails:false})
  }
  getThumbnailUrl = (thumbnails: Thumbnail[] | null | undefined, defaultView: string) => {
    return thumbnails != null && thumbnails.length > 0
      ? thumbnails[0].url
      : defaultView;
  };
  getAttributesThumbnailUrl = (currentProduct: OrderItem, defaultView: string) => {    
    const thumbnails = 
      currentProduct && 
      currentProduct.attributes && 
      currentProduct.attributes.catalogue_service && 
      currentProduct.attributes.catalogue_service.data && 
      currentProduct.attributes.catalogue_service.data.attributes && 
      currentProduct.attributes.catalogue_service.data.attributes.thumbnails;
  
    return this.getThumbnailUrl(thumbnails, defaultView);
  };  
  getProductsToDisplay = (filteredProducts: any, products: any) => {
    return filteredProducts && filteredProducts.length > 0 ? filteredProducts : products;
  };
  
  // Customizable Area End
}
