// Customizable Area Start
import React, { useEffect, useCallback, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
} from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { activeDash, inActiveDash } from "../../blocks/ordermanagement/src/assets";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {rightArrow, leftArrow, fileIcon, playIcon} from './assets';

interface CustomInputProps {
    isOpen: boolean;
    onClose: () => void;
    viewDetails: any;
    classes: Record<string, string>;
    currentIndex: number;
    currentId: number;
    handleAfterChange: (currentSlide: number) => void;
    isEmailAttachment?: boolean;
}

const ImageModal = (props: CustomInputProps) => {
  const {
    isOpen,
    onClose,
    classes,
    viewDetails,
    currentIndex,
    currentId,
    handleAfterChange,
    isEmailAttachment,
  } = props;

  const checkFileType = (filename: string) => {
    const fileExtension = filename.split('.').pop()?.toLowerCase();
    return fileExtension || '';
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 10000,
    customPaging: (index: number) => (
      <img
        src={index === currentIndex ? activeDash : inActiveDash}
        alt="dot"
      />
    ),
    // nextArrow: (
    //   <IconButton className="next-arrow">
    //     <ArrowForwardIosIcon />
    //     {/* <img src={rightArrow}/> */}
    //   </IconButton>
    // ),
    // prevArrow: (
    //   <IconButton className="prev-arrow">
    //     <ArrowBackIosIcon />
    //     {/* <img src={leftArrow}/> */}
    //   </IconButton>
    // ),
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handleAfterChange(currentIndex > 0 ? currentIndex - 1 : 0);
      } else if (event.key === 'ArrowRight') {
        handleAfterChange(currentIndex < viewDetails.attachments.length - 1 ? currentIndex + 1 : currentIndex);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, handleAfterChange, currentIndex, viewDetails]);

  return (
    <Modal
      className={classes.modalImageDialog}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="image-container">
        <Box className="close-icon" onClick={onClose} data-test-id="handleFiltersClose">
          <CloseIcon className="close-icon" />
        </Box>
        <Box className={`image-wrapper-2 ${classes.carouselWrapper}`}>
          {viewDetails?.attachments?.length > 0 && (
            <Slider key={currentId} {...settings} className="carousel-slider">
              {viewDetails.attachments.map((item: any) => (
                <Box className="slider-item" key={item.id}>
                  <TransformWrapper>
                    <TransformComponent>
                      {(() => {
                        const fileType = checkFileType(item.url);
                        if (fileType === "mp4") {
                          return (
                            <div className="item-inner has-video">
                              <div className="image-video-wrapper">
                                <video controls>
                                  <source src={item.url} type="video/mp4" />
                                </video>
                                <em className="play-icon">
                                  <img src={playIcon} alt="sliderImg" />
                                </em>
                              </div>
                            </div>
                          );
                        } else if (["jpg", "jpeg", "png", "svg"].includes(fileType)) {
                          return (
                            <div className="item-inner has-image">
                              <div className="image-video-wrapper">
                                <img src={item.url} alt="sliderImg" />
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="item-inner has-document">
                              <div className="file-icon">
                                <em>
                                  <img src={fileIcon} alt="File icon" />
                                </em>
                                <p>this.is.a.document.pdf</p>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </TransformComponent>
                  </TransformWrapper>
                </Box>
              ))}
            </Slider>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageModal;
// Customizable Area End
