import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import { CardMedia } from "@material-ui/core";
import { warningIcon } from "./assets";
interface SearchedCategoryData {
  etoh_shop_categories: {
    id: number;
    name: string;
    etoh_shop_sub_categories: {
      id: number;
      name: string;
    }[];
  }[];
}

export interface SubCategory {
  id: string;
  name: string;
}

export interface FilterResult {
  id: string;
  name: string;
  etoh_shop_sub_categories: SubCategory[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: any;
  isMobile?: any;
  toggleMenu: any;
  getProductsById: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  searchQuery: string;
  sortValue: string;
  categoryData: any;
  subCategoryData: any;
  selectedSubCategoryId: string;
  expandedAccordion: any;
  isSorting: boolean;
  filterResults: any;
  isSearching: boolean;
  searcedCategoryData: SearchedCategoryData;
  serviceCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomEtohShopSidebarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getSubCategoryRequestId: string = "";
  getAllCategoryRequestId: string = "";
  getSortCategoryRequestId: string = "";
  getSearchCategoryRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      searchQuery: "",
      sortValue: this.t(`${configJSON.sortByTxt}`),
      categoryData: [],
      subCategoryData: [],
      selectedSubCategoryId: "",
      expandedAccordion: null,
      isSorting: false,
      filterResults: [],
      isSearching: false,
      searcedCategoryData: {
        etoh_shop_categories: [],
      },
      serviceCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  // Customizable Area Start
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  async componentDidMount() {
    this.getAllEtohShopCategory();
    this.getSearchEtohShopCategory()
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.removeLocalStorageElement();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> { 
    if((prevState.searchQuery !== this.state.searchQuery) && this.state.searchQuery === "") {
      this.setState({
        searchQuery: "",
        isSearching: false,
      }, () => this.getAllEtohShopCategory())
    }
  }
  removeLocalStorageElement() {
    localStorage.removeItem("sub_category_id");
    localStorage.removeItem("sub_category_name");
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
      } else {
        switch (apiRequestCallId) {
          case this.getAllCategoryRequestId:
            this.setState({ isLoading: false });
            this.setState({
              categoryData: responseJson.data,
              serviceCount: responseJson.data.length,
            });
            break;
          case this.getSubCategoryRequestId:
            this.setState({ isLoading: false });
            this.setState({ subCategoryData: responseJson.data });
            break;
          case this.getSortCategoryRequestId:
            this.setState({ isLoading: false });
            this.setState({
              filterResults: responseJson.data.etoh_shop_categories,
            });
            break;
          case this.getSearchCategoryRequestId:
            this.setState({ isLoading: false });
            this.setState({
              searcedCategoryData: responseJson.data,
              serviceCount: responseJson.data.etoh_shop_categories.length,
            });

            if (responseJson.data.length === 0) {
              this.setState({
                isSearching: false,
                isSorting: false,
              });
            }
            break;
          default:
            this.parseApiCatchErrorResponse(errorResponse);
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearchInputChange = (event: any) => {
    this.setState(
      { searchQuery: event.target.value, isSearching: true },
      () => {
        this.getSearchEtohShopCategory();
      }
    );

  };

  handleSortByChange = (event: any) => {
    this.setState({ sortValue: event.target.value, isSorting: true }, () => {
      this.getSortedCategory();
    });
  };

  selectedValueEvent = (id: string, name: string) => {
    localStorage.setItem("sub_category_id", id);
    localStorage.setItem("sub_category_name", name);
    this.setState({
      selectedSubCategoryId: id,
    });
    this.props.getProductsById(id, name);
  };

  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

  getAllEtohShopCategory = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCategoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCategory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubEtohShopCategory = (categoryId: string) => {
    this.setState({subCategoryData: []});
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const endpoint = `${configJSON.getSubCategory}?etoh_shop_category_id=${categoryId}`;
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSortedCategory = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const endpoint = `${configJSON.sortByApiPath}?sort_by=${this.state.sortValue}`;
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSortCategoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSearchEtohShopCategory = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchCategoryRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchApiPath}?search_item=${this.state.searchQuery}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
