import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import {
  Chat,
  ChatMessagesResponse,
  ExternalChat,
  GroupedMessages,
  LiveChatEtohData,
  SubscribedUser,
  UserToken,
  chatRoomData,
} from "../../../components/src/ReusableEnums";
import React from "react";
import moment from "moment";
import { subscriptionData } from "../../../components/src/productData";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  liveChatId: string;
  // Customizable Area Start
  classes: Record<string, string>;
  anchorQuickChatEl: HTMLElement | null;
  handleQuickChatPopoverClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quickChatData: Array<Chat>;
  openSingleChat: HTMLElement | null;
  allSinglePersonChat: Array<ChatMessagesResponse>;
  chatTxtMessage: string;
  isLoading: boolean;
  messageId: string;
  fileUploaded: File[] | null;
  preview: string[];
  openQuickNewChat: boolean;
  openExternalQChat: boolean;
  email: string;
  error: {
    email: string;
  };
  subscribedUserData: Array<SubscribedUser>;
  userSortBy: string;
  userSearch: string;
  singleMessage: Array<GroupedMessages>;
  openEmailvarification: boolean;
  pages:number;
  quickItemData:Chat;
  hasNextPageEvent: boolean;
  page: number;
  totalQRecords:number;
  isChatCollapsed: boolean;
  currentUserId: number; 
  isSortModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuickChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userSessionData: string | null;
  userToken: UserToken;
  getAllQuickChatRoomsRequestId: string = "";
  getAllQuickMessageRequestId: string = "";
  createEtohSupportQuickChatRequestId: string = "";
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  createExternalChatRequestId: string = "";
  getAllChatUsersRequestId: string = "";
  createExternalInternalChat: string = "";
  readAllMassegeApiCallId:string = "";
  createQuickChatInternalExternalRequestIdgeeRquestId:string ="";
  etohSupportQuickChatRequestId:string = "";
  chatEndRef: React.RefObject<HTMLDivElement>;
  inviteMemberQuickRequestId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      quickChatData: [],
      openSingleChat: null,
      allSinglePersonChat: [],
      chatTxtMessage: "",
      isLoading: false,
      isChatCollapsed: false,
      messageId: "",
      fileUploaded: null,
      preview: [],
      openQuickNewChat: false,
      openExternalQChat: false,
      email: "",
      error: {
        email: "",
      },
      subscribedUserData: subscriptionData,
      userSortBy: this.translateQChat(`${configJSON.sortByTxt}`),
      userSearch: "",
      singleMessage: [],
      openEmailvarification: false,
      pages:0,
      quickItemData:{id:"", attributes:{}},
      hasNextPageEvent: false,
      page: 1,
      totalQRecords:0,
      currentUserId: 0,
      isSortModalOpen: false, 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData") ;
    this.userToken = JSON.parse(this?.userSessionData || '');
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    this.chatEndRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponseMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
       if (responseData.error) {
        this.setState({ isLoading: false });
        return;
       }
        switch (apiRequestCallID) {
          case this.getAllQuickChatRoomsRequestId:
            this.setState({ isLoading: false });
            this.setState({ quickChatData: responseData.data });
            break;
          case this.getAllQuickMessageRequestId:
            const allMessages = responseData.data;                              
            this.setState(() => ({
              isLoading: false,
              allSinglePersonChat: allMessages,              
            }));
            this.groupAllMessages();
            break;
          case this.createEtohSupportQuickChatRequestId:
            this.setState({ isLoading: false, chatTxtMessage: "", fileUploaded:[], preview:[] });
            this.getAllQuickMessage(this.state.quickItemData.id,this.state.page);
            break;
          case this.getAllChatUsersRequestId:
            this.setState({
              isLoading: false,
              subscribedUserData: responseData.data,
            });
            break;
          case this.createExternalChatRequestId:
            this.handleQuickExternalChatResponse(responseData);  
            break;
          case this.createExternalInternalChat:           
            responseData.errors &&
              this.createToastNotification(
                this.translateQChat(responseData.errors.chat.base[0])
              );
            this.setState({ openQuickNewChat: false });
            this.handleQuickChatRooms();
           break;
            case this.createQuickChatInternalExternalRequestIdgeeRquestId: 
            this.setState({
              isLoading: false,
              chatTxtMessage: "",
              fileUploaded: [],
              preview: [],
            });         
            this.getAllQuickMessage(this.state.quickItemData.id,1);
            break; 
            case this.etohSupportQuickChatRequestId: 
            this.handleEtohSupportLiveChatResponse(responseData);           
            break;
            case this.inviteMemberQuickRequestId:
             if(responseData.message){
                this.createToastNotification(responseData.message);
                this.closeEmailVarificationEvent();
                this.closeExternalChat()
              }else{
                this.createToastNotification(configJSON.errorMessage);
              }
            break; 
          default:
            this.parseApiCatchErrorResponse(errorResponseMsg);
            break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearErrorMessage(prevState);
    this.fetchUsersUpdate(prevState);
    if (prevState.allSinglePersonChat.length !== this.state.allSinglePersonChat.length) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
    }
  }
  scrollToBottom = () => {
    if (this.chatEndRef.current) {
      this.chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  handleKeyDown = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleSubmitMessage();
    }
  };
  handleQuickExternalChatResponse(responseData: ExternalChat) {
    if (responseData.message === "Member succesfully found") {
      this.setState({
        isLoading: false,
        openExternalQChat: false,
      });
      this.createExternalAndInternalChatEvent(
        responseData.account.data,
        "external"
      );
    } else {
      this.setState({
        error: {
          email: `${this.translateQChat("Member profile not found")}`,
        },
        isLoading: false,
      });
    }
  }
  handleEtohSupportLiveChatResponse(responseData: LiveChatEtohData) {
    this.setState({ isLoading: false });
    if (responseData.errors) {
      this.createToastNotification(configJSON.etohSupportAlreadyCreated);
     }else{
      this.createToastNotification(configJSON.etohSupportChatAdded);
     }
     this.closeNewChatQuickEvent();
     this.handleQuickChatRooms();
  }
  async componentDidMount(): Promise<void> {
  const userSessionData = sessionStorage.getItem("userData");
  const userInfo = userSessionData ? JSON.parse(userSessionData) : null;
  const currentUserId = userInfo?.data?.id ? parseInt(userInfo.data.id, 10) : 0;   
  this.setState({ currentUserId });
    const lang = localStorage.getItem("lang") || "en";
    await i18n.changeLanguage(lang);
    this.handleQuickChatRooms();
    this.getAllSubscribedUsers();  
  }
  createToastNotification = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.translateQChat(toastMesssage)}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  groupAllMessages = () => {
    const { allSinglePersonChat } = this.state;
    const groupMessageAsperDate: Array<GroupedMessages> = [];

    allSinglePersonChat.forEach((message) => {
      const createdAtDate = moment(message.attributes.created_at);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "days").startOf("day");

      let dateKey: string;
      switch (true) {
        case createdAtDate.isSame(today, "d"):
          dateKey = "Today";
          break;
        case createdAtDate.isSame(yesterday, "d"):
          dateKey = "Yesterday";
          break;
        default:
          dateKey = createdAtDate.format("MM/DD/YYYY");
      }

      const quickMessageData = groupMessageAsperDate.findIndex(
        (group) => group.date === dateKey
      );

      if (quickMessageData === -1) {
        groupMessageAsperDate.push({ date: dateKey, messages: [message] });
      } else {
        groupMessageAsperDate[quickMessageData].messages.push(message);
      }
    });
    this.setState({ singleMessage: groupMessageAsperDate });
  };
  createExternalAndInternalChatEvent = (data: chatRoomData, type: string) => {
    const userName =
      data.attributes.first_name + " " + data.attributes.last_name;
    const formData = new FormData();
    formData.append("chat[name]", userName);
    formData.append("chat[chat_type]", type);
    formData.append("account_id", data.id);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createExternalInternalChat = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChatForExternalAndInternalUser}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createExternalAndInternalChatMessgaeEvent = () => {
    const headerData = {
      token: this.userToken.meta.token,
    };
    const quickFormData = new FormData();
    quickFormData.append("chat_message[message]", this.state.chatTxtMessage);
    quickFormData.append("chat_message[chat_id]", this.state.quickItemData.id);   
    quickFormData.append("account_id",this.state.quickItemData.attributes.sender?.id);
    if (this.state.fileUploaded) {
      for (const file of this.state.fileUploaded) {
        const filesType = file && file.type.split("/")[0];
        quickFormData.append("chat_message[media_file]", file);
        quickFormData.append("chat_message[media_type]",filesType);
      }
    }  

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.externalAndInternalMessage
    );

    this.createQuickChatInternalExternalRequestIdgeeRquestId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      quickFormData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    this.setState({ isLoading: false })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  translateQChat(keyValue: string) {
    return i18n.t(keyValue, { ns: "translation" });
  }

  fetchMoreData = ()=>{
    this.setState({ hasNextPageEvent: false });
  }
  
  fetchMoreDataEvent = () => {
    const { allSinglePersonChat, page, totalQRecords } = this.state;
    if (allSinglePersonChat.length >= totalQRecords) {
      this.setState({ hasNextPageEvent: false });
      return;
    }

    const newPageNo = page + 1;
    this.getAllQuickMessage(this.state.quickItemData.id, newPageNo);
  }

  handleChatInputChange = (event: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  openSingleChatEvent = (
    event: React.MouseEvent<HTMLElement>,
    item: Chat,
  ) => {
    this.setState(
      { quickItemData: item, openSingleChat: event.currentTarget, page: 1, allSinglePersonChat: [] },
      () => {
        this.getAllQuickMessage(item.id, this.state.page);
        this.markAsReadAllEvent(item.id);
      }
    );
  };

  closeSingleChatEvent = () => {
    this.setState({ openSingleChat: null });
  };

  handleQuickChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllQuickChatRoomsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllQuickMessage = (messageId: string,newPageNo:number) => {
    this.setState({messageId})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllQuickMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendChatRoomMsg}?chat_id=${messageId}&page[number]=${newPageNo}&page[size]=100`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({hasNextPageEvent:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  clearErrorMessage = (prevState: S) => {
    let error = { ...this.state.error };
    const email = this.state.email.trim();
    if (prevState.email !== email) {
      if (email !== "") {
        error.email = "";
      } else if (!configJSON.emailRegex.test(email)) {
        error.email = this.translateQChat(configJSON.emailErrorMsg);
      } else {
        error.email = "";
      }
    }
    if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: error });
    }
  };
  createExternalChatEvent = () => {
    let error = this.state.error;
    let hasAnError = false;

    const email = this.state.email.trim();
    if (email === "") {
      this.setState({
        error: {
          ...this.state.error,
          email: this.translateQChat(configJSON.emailEmptyErrorMsg),
        },
      });
    } else if (!configJSON.emailRegex.test(email)) {
      error.email = this.translateQChat(configJSON.emailErrorMsg);
      this.setState({
        error: error,
      });
      hasAnError = true;
    }

    if (hasAnError) {
      return;
    }
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createExternalChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createExternalChatApi}?email=${this.state.email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  openNewChatEvent = () => {
    this.setState({
      openQuickNewChat: true,
    });
  };
  closeNewChatQuickEvent = () => {
    this.setState({
      openQuickNewChat: false,
    });
  };
  openQuickExternalChatEvent = () => {
    this.setState({
      openExternalQChat: true,
    });
  };
  closeExternalChat = () => {
    this.setState({
      openExternalQChat: false,
    });
  };
  closeEmailVarificationEvent = () => {
    this.setState({ openEmailvarification: false });
  };
  handleInviteMemberEvent = () => {
    this.setState({ openEmailvarification: true });
  };
  openSortModal = () => {
    this.setState({ isSortModalOpen: true });
  };
  closeSortModal = () => {
    this.setState({ isSortModalOpen: false });
  };
  onBrowseEvent = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  };
  handleOnDropEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesData: File[] = Array.from(event.target.files || []);
    const preview: string[] = [];

    filesData.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        preview.push(reader.result as string);
        if (preview.length === filesData.length) {
          this.setState({
            fileUploaded: filesData,
            preview,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };
  handleCloseAttachmentEvent = () => {
    this.setState({ fileUploaded: [], preview: [] });
  };
  handleSubmitMessage = () => {
    const formData = new FormData();
    formData.append("chat_message[chat_id]", this.state.quickItemData.id);
    formData.append("chat_message[message]", this.state.chatTxtMessage);
    if (this.state.fileUploaded) {
      for (const file of this.state.fileUploaded) {
        const fileType = file && file.type.split("/")[0];
        formData.append("chat_message[media_file]", file);
        formData.append("chat_message[media_type]", fileType);
      }
    }
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEtohSupportQuickChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendChatRoomMsg}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchUsersUpdate = (prevState: S) => {
    if (
      prevState.userSortBy !== this.state.userSortBy ||
      prevState.userSearch !== this.state.userSearch
    ) {
      this.getAllSubscribedUsers();
    }
  };
  getAllSubscribedUsers = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.userSortBy;
    let processedValue = "";

    switch (newValue) {
      case "A-Z":
        processedValue = "A-Z";
        break;
      case "Z-A":
        processedValue = "Z-A";
        break;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatUsersRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subscribedUserList}?search_query=${this.state.userSearch}&sort_by=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };  
  markAsReadAllEvent = (chatId:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.readAllMassegeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsRead}?chat_id=${chatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
 
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createEtohSupportChatEvent = () => {
    const formdata = new FormData();
    formdata.append("chat[chat_type]", "etoh_shop_support");
    formdata.append("chat[name]", "EtOH Support");    
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.etohSupportQuickChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  inviteMember = () => {    
    const header = {
      token: this.userToken.meta.token,
    };
    const formData = new FormData();
    formData.append("chat[email]", this.state.email);

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.inviteMemberQuickRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteMemberPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );    
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleNotification(item : any,currentUserId : any)
  {
    if(item.attributes.unread_count !== 0 && (item.attributes.last_message.data.attributes.sender.data.id !== currentUserId))
    {
      return true;
    }
    return false;
  }
  // Customizable Area End
}
