// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Papa from "papaparse";
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./BulkUploadContact.web";
import { toast } from "react-toastify";
import { warningIcon, checkedIcon } from "./assets";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");

export type csvDataType = {
  name: string;
  email: string;
  phone: string;
  group: string;
  company_name: string;
  last_name:string;
  first_name:string;
  business_info: {
    VAT_number:string;
    business_category_id: number;
    company_name:string
  };
  billing_address: {
    address1: string;
    address2:string;
    city: string;
    country: string;
    pin_code: string;
  };
  shipping_address: {
    address1: string;
    address2:string;
    city: string;
    country: string;
    pin_code: string;
  };
  note: {
    content: string;
  };
  contact_groups: {
    group_names: any;
  };
};

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
}

type FileRows = {
  [key: string]: string;
}

type FilesRead = {
  index: number,
  file: File,
  rows: FileRows[]
}

interface S {
  filesUploaded: File | null;
  isFileValidType: boolean;
  isLoading: boolean;
  activeStep: number;
  progress: number;
  isPause: boolean;
  skipped: Set<number>;
  isCancel: boolean;
  timeRemain: number;
  tabValue: string;
  teamsList: any;
  isTeamsTab: boolean;
  membersList: any;
  searchValue: string;
  searchGroupValue: string;
  modalOpen: boolean;
  searchData: any;
  fileDrop: boolean;
  isDeleteFile: boolean;
  fileSelected: FileList | null;
  isDeleteMembers: boolean;
  selected: number[];
  selectedCompanyVal: any;
  selectedGroupVal: any;
  selectedTitleVal: any;
  filteredData: any;
  isFiltering: boolean;
  isFilter: boolean;
  isAscending: boolean | null;
  showLoader: boolean;
  filteredDataTeam: any;
  isFilteringTeam: boolean;
  isAscendingTeam: boolean | null;
  selectedTeamFilter: any;
  importContactData:any;
  groupCount:number;
  showCount:boolean;
  deleteType: string;
  isViewingContact: boolean;
  viewCurrentContact: any;
  viewBulkTabsValue: number;
  showMoreGroups: boolean;
}

interface SS {
  id: any;
}

export default class BulkUploadContactController extends BlockComponent<Props, S, SS> {
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  userSessionData: any;
  userToken: any;
  currentParser: any;
  curProgress: number = 0;
  myRef: any;
  importContactApiId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isFileValidType: true,
      filesUploaded: null,
      progress: 0,
      isLoading: false,
      activeStep: 0,
      isPause: false,
      skipped: new Set(),
      timeRemain: 100,
      isCancel: false,
      isTeamsTab: true,
      tabValue: "1",
      teamsList: [],
      searchValue: "",
      searchGroupValue: "",
      membersList: [],
      searchData: [],
      modalOpen: false,
      isDeleteFile: false,
      fileDrop: false,
      isDeleteMembers: false,
      selected: [],
      fileSelected: null,
      selectedGroupVal: [],
      selectedTitleVal: [],
      selectedCompanyVal: [],
      isFiltering: false,
      filteredData: [],
      showLoader: false,
      isFilter: false,
      isAscending: null,
      filteredDataTeam: [],
      isFilteringTeam: false,
      selectedTeamFilter: [],
      isAscendingTeam: null,
      importContactData:[],
      groupCount:0,
      showCount:false,
      deleteType: "",
      isViewingContact: false,
      viewCurrentContact: [],
      viewBulkTabsValue: 0,
      showMoreGroups: false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");    
    this.userToken = JSON.parse(this.userSessionData);
    this.currentParser = null;
    this.myRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  csvFileSchema: string[] = [
    "First Name",
    "Last Name",
    "Phone",
    "Email",
    "Group",
    "Company Name",
    "VAT Number",
    "Business Category",
    "Billing Country",
    "Billing State",
    "Billing Zip Code / Post Code",
    "Billing Address 1",
    "Billing Address 2",
    "Shipping Country",
    "Shipping State",
    "Shipping Zip Code / Post Code",
    "Shipping Address 1",
    "Shipping Address 2"
  ];
  validJobTypes: string[] = ["owner", "manager", "employee"];
  handleClose = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }
    this.setState({
      isDeleteFile: false,
      fileSelected: null,
      isDeleteMembers: false,
    });
  };

  membersDelete = () => {
    this.setState({
      membersList: this.state.membersList.filter(
        (x: any) => this.state.selected.find((y) => y == x.id) == null
      ),
      isDeleteMembers: false,
    });
  };

  groupsDelete = () => {
    this.setState({
      teamsList: this.state.teamsList.filter(
        (x: any) => this.state.selected.find((y) => y == x.id) == null
      ),
      isDeleteMembers: false,
    });
  }

  multipleDelete = (deleteType: string) => {
    this.setState({
      isDeleteMembers: true,
      deleteType : deleteType
    });
  };
  getSelectedRecords = (selectedRecords: number[]) => {
    this.setState({
      selected: selectedRecords,
    });
  };

  handleClearSelection = (key: any) => {
    const newVal: any[] = [];
    const newState = { [key]: newVal } as Pick<S, keyof S>;
    this.setState({ ...newState, isFiltering: true }, () => {
      this.filterRecords();
    });
  };
  handleClearSelectionTeam = () => {
    this.setState({ selectedTeamFilter: [], isFilteringTeam: true }, () => {
      this.filterGroups();
    });
  };

  removeFile = () => {
    this.setState({
      isDeleteFile: false,
    });
    if (this.state.fileSelected != null) {
      this.readCsv(this.state.fileSelected);
    }
  };
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.membersList != this.state.membersList) {
      this.filterRecords();
      if (this.state.membersList.length > 0) {
        let teamsArray: string[] = this.createTeamsFromCsvFile();
        const teamList = teamsArray.map((group: string, index: number) => {
          return {
            id: index,
            attributes: {
              id: index,
              title: group,
              users: this.state.membersList.filter(
                (x: any) =>
                  x.attributes?.group.find((x: any) => x.title == group) != null
              ).length,
            },
          };
        });
        this.setState({ teamsList: teamList });
      } else {
        this.setState({ teamsList: [] });
      }
    }
  }
  async componentDidMount() {
    const langS = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(langS); 
  }
  getCountEvent = (count:any)=>{
    this.setState({groupCount:count,showCount:true})
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.errors) {
        this.createToastNotification("Something went wrong");
      } else if(responseJson.error) {
        this.createToastNotification(responseJson.error);
      }else if(apiRequestCallId === this.importContactApiId) {
        this.createToastNotificationSuccess("Upload complete. Only new contacts added. Existing email IDs have been skipped")
        const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "ContactsList");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
        
      } 
    }
  }

  createToastNotification = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => <img src={warningIcon} alt="emptydata" />,
    });
  };

  createToastNotificationSuccess = (toastMesssage: string) => {
    toast.success(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => <img src={checkedIcon} alt="checkedIcon" />,
      autoClose:3000,
    });
  };
  
  deleteMemberHandler = (id: any) => {
    this.setState({
      membersList: this.state.membersList.filter((x: any) => x.id != id),
    });
  };

  downloadCsvTemplate = () => {  
    let url = configJSON.baseConfig.baseURL+ configJSON.webDownloadCsvTemplateEndPoint;

    fetch(url, {
      method: "GET",
      headers: { token: this.userToken.meta.token },
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `EtOH_Template_${Math.floor(
            new Date().getTime() + new Date().getSeconds() / 2
          )}.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        this.createToastNotification("Something wen't wrong. Please try again");
      });
  };
  resetFilters = () => {
    if (this.state.isTeamsTab) {
      this.setState({ selectedTeamFilter: [] }, () => {
        this.filterGroups();
      });
    } else {
      this.setState(
        {
          selectedCompanyVal: [],
          selectedTitleVal: [],
          selectedGroupVal: [],
        },
        () => {
          this.filterRecords();
        }
      );
    }
  };

  reUploadFile = () => {
    this.cancelUpload();
    this.setState({
      activeStep: 0,
    });
  };

  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };

  validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.exec(email);
  };
  handleTeamSearch = (newSearchTerm: any) => {
    this.setState(
      {
        searchGroupValue: newSearchTerm.target.value,
        isFilteringTeam: true,
      },
      () => {
        this.filterGroups();
      }
    );
  };

  handleMemberSearch = (newSearchTerm: any) => {
    this.setState(
      {
        searchValue: newSearchTerm.target.value,
        isFiltering: true,
      },
      () => {
        this.filterRecords();
      }
    );
  };
  searchHandler = (val: any) => {
    if (this.state.isTeamsTab) {
      this.handleTeamSearch(val);
    } else {
      this.handleMemberSearch(val);
    }
  };

  filterGroups = () => {
    const { searchGroupValue, isFilteringTeam, selectedTeamFilter, teamsList } =
      this.state;
    let filterDataTeam = teamsList;
    if (isFilteringTeam) {
      filterDataTeam = filterDataTeam.filter((member: any) => {
        if (
          selectedTeamFilter.length > 0 &&
          selectedTeamFilter.find((x: any) => x == member.id) == null
        ) {
          return false;
        }

        return true;
      });
      if (searchGroupValue) {
        filterDataTeam = filterDataTeam.filter((team: any) => {
          const escapedSearchValue = this.escapeRegExp(searchGroupValue.toLowerCase());
          let teamTitle = team.attributes.title.toLowerCase();
          if (teamTitle.search(escapedSearchValue) !== -1) return team;
        });
      }
    }

    filterDataTeam = this.sortTeamRecords(filterDataTeam);
    this.setState({ filteredDataTeam: filterDataTeam });
  };
   escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  sortRecords = (filterData: any) => {
    const { isAscending } = this.state;
    if (isAscending != null) {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = isAscending ? -1 : 1;
      rightIndex = isAscending ? 1 : -1;
      filterData = filterData.sort((member1: any, member2: any) => {
        if (member1.attributes.first_name < member2.attributes.first_name) return leftIndex;
        if (member1.attributes.first_name > member2.attributes.first_name) return rightIndex;
        return 0;
      });
    }   
    return filterData;
  };

  sortTeamRecords = (filterData: any) => {
    const { isAscendingTeam } = this.state;
    if (isAscendingTeam != null) {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = isAscendingTeam ? -1 : 1;
      rightIndex = isAscendingTeam ? 1 : -1;
      filterData = filterData.sort((team1: any, team2: any) => {
        let teamName = team1.attributes.title;
        let team2Name = team2.attributes.title;

        if (teamName < team2Name) return leftIndex;
        if (teamName > team2Name) return rightIndex;
        return 0;
      });
    }
    return filterData;
  };
  searchRecords = (filterData: any) => {
    const { searchValue } = this.state;
    const escapedSearchValue = this.escapeRegExp(searchValue.toLowerCase());
    return filterData.filter((member: any) => {
      let memTitle = member.attributes.first_name.toLowerCase();
      let memLastName = member.attributes.last_name.toLowerCase();
      let memEmail = member.attributes.email.toLowerCase();
      if (
        memTitle.search(escapedSearchValue.toLowerCase()) !== -1 ||
        memEmail.search(escapedSearchValue.toLowerCase()) !== -1 ||
        memLastName.search(escapedSearchValue.toLowerCase()) !== -1
      )
        return memTitle;
    });
  };
  filterRecords = () => {
    const {
      selectedGroupVal,
      selectedCompanyVal,
      selectedTitleVal,
      membersList,
      searchValue,
      isFiltering,
      teamsList,
    } = this.state;
    let filterData = membersList;
    if (isFiltering) {
      filterData = filterData.filter((member: any) => {
        if (
          (selectedGroupVal.length > 0 &&
            selectedGroupVal.find(
              (x: any) =>
                teamsList.find((y: any) => y.id == x).attributes.title ==
                member.attributes.group[0].title
            ) == null) ||
          (selectedCompanyVal.length > 0 &&
            selectedCompanyVal.find((x: any) => x == member.attributes.company_name) ==
            null) ||
          (selectedTitleVal.length > 0 &&
            selectedTitleVal.find(
              (x: any) => x == member.attributes.business_category
            ) == null)
        ) {
          return false;
        }

        return true;
      });
      if (searchValue) {
        filterData = this.searchRecords(filterData);
      }
    }
    filterData = this.sortRecords(filterData);
    this.setState({ filteredData: filterData });
  };

  changeSortByMembers = (e: any) => {
    if (e.target.value == "") {
      this.setState({ isAscending: null }, () => {
        this.filterRecords();
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscending: true }, () => {
        this.filterRecords();
      });
    } else {
      this.setState({ isAscending: false }, () => {
        this.filterRecords();
      });
    }
    
  };
  changeSortByGroups = (e: any) => {
    if (e.target.value == "") {
      this.setState({ isAscendingTeam: null }, () => {
        this.filterGroups();
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscendingTeam: true }, () => {
        this.filterGroups();
      });
    } else {
      this.setState({ isAscendingTeam: false }, () => {
        this.filterGroups();
      });
    }
  };
  changeSortByResponsive = () => {
    if (this.state.isTeamsTab) {
      this.setState({ isAscendingTeam: !this.state.isAscendingTeam }, () => {
        this.filterGroups();
      });
    } else {
      this.setState({ isAscending: !this.state.isAscending }, () => {
        this.filterRecords();
      });
    }
  };

  saveSelectedTeamsOption = (event: { target: { value: any } }, key: any) => {
    if (event.target.value != undefined) {
      const setNewValue = event.target.value.filter((x: any) => x != undefined);
      const newState = { [key]: setNewValue } as Pick<S, keyof S>;
      this.setState({ ...newState, isFilteringTeam: true }, () => {
        this.filterGroups();
      });
    }
  };

  saveSelectedOption = (event: { target: { value: any } }, key: any) => {
    if (event.target.value != undefined) {
      const setNewValue = event.target.value.filter((x: any) => x != undefined);
      const newState = { [key]: setNewValue } as Pick<S, keyof S>;
      this.setState({ ...newState, isFiltering: true }, () => {
        this.filterRecords();
      });
    }
  };
  createTeamsFromCsvFile = (): string[] => {
    let teams: string[] = this.state.membersList.map((member: any) =>
      member.attributes.group.map((x: any) => x.title).join(",")
    );

    teams = teams.reduce(function (acc: any, val: any) {
      return acc.concat(val);
    }, []);

    let teamsArray = [
      ...new Set(teams.map((team) => team.trim()).filter(Boolean)),
    ];

    return teamsArray;
  };
  
  confirmClick = () => {
    this.importContact()
  };
  pauseResumeUpload = () => {
    if (!this.state.isPause) {
      this.currentParser.pause();
    }
    this.setState(
      {
        isPause: !this.state.isPause,
      },
      () => {
        if (this.currentParser != null) {
          if (!this.state.isPause) {
            this.currentParser.resume();
          }
        }
      }
    );
  };
  cancelParsing = () => {
    if (this.currentParser != null) {
      this.currentParser.abort();
      this.currentParser = null;
    }
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }

    this.setState({
      isCancel: true,
      isLoading: false,
      filesUploaded: null,
      fileSelected: null,
      membersList: [],
      isTeamsTab: true,
      searchValue: "",
      isFiltering: false,
      selected: [],
      selectedGroupVal: [],
      selectedCompanyVal: [],
      selectedTitleVal: [],
      filteredData: [],
      isFilter: false,
      isAscending: null,
      isFilteringTeam: false,
      filteredDataTeam: [],
      isAscendingTeam: null,
      selectedTeamFilter: [],
      tabValue: "1",
    });
  };
  cancelUpload = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }

    this.setState({
      isCancel: true,
      isLoading: false,
      filesUploaded: null,
      fileSelected: null,
      membersList: [],
      teamsList: [],
      isTeamsTab: true,
      searchValue: "",
      isFiltering: false,
      selected: [],
      selectedGroupVal: [],
      selectedCompanyVal: [],
      selectedTitleVal: [],
      filteredData: [],
      isFilter: false,
      isAscending: null,
      isFilteringTeam: false,
      filteredDataTeam: [],
      isAscendingTeam: null,
      selectedTeamFilter: [],
      tabValue: "1",
    });
  };

  isStepCompleted(step: number) {
    return this.state.activeStep > step;
  }
  handleNext = () => {
    if (this.state.isLoading) {
      return;
    }
    if (this.state.filesUploaded == null || this.state.membersList.length < 0) {
      this.createToastNotification("Please choose a file");
      return;
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  handleBack = () => {
    window.location.href=("/contacts")
  };

  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  tabsChangeHandler = (e: any, tabValue: string) => {
    this.setState({ tabValue: tabValue, searchValue: "", isAscending: null });
    if (tabValue === "2") {
      this.setState({ isTeamsTab: false });
    } else {
      this.setState({ isTeamsTab: true });
    }
  };

  filterTeamHandler = () => {
    this.handle_modalOpen();
    this.setState({ isFilter: true });
  };

  handle_modalOpen = () => {
    this.setState({ modalOpen: true });
  };
  handle_modalClose = () => {
    const newState = {
      modalOpen: false,
    };
    this.setState(newState);
  };

  parseCsvRow = (row: string): string[] => {
    const values = row.split(',');
    return values.map(value => value.replace(/(^"|"$)/g, '').trim()); 
  };

  

  readCsv = (files: FileList) => {
    this.readCsvBulk(files[0])
  }

  readCsvBulk = (file:File) => {

    let reader = new FileReader();
    reader.readAsText(file);
    let totalSize = 0;
    let csvHeader: string[] = [];
    reader.onload = (event: any) => {
      let csv = event.target.result; 
      let csvArray = csv.split("\n").filter((str:string) => str.trim() !== "");
      const regex=/\r/g
      csvHeader = csvArray[0].replace(regex, "").split(",");
      csvHeader = csvHeader.sort((a, b) => a.localeCompare(b));
      totalSize = csvArray.length-1;
          
        this.parseCsv([file], totalSize,csvHeader,[totalSize]);
      
    };
  }

  parseFilesSequentially = (fileList: File[], index: number,start:number,totalSize:number,resultArray:FilesRead[],fileRows:number[]): Promise<any[]> => {
    return new Promise<FilesRead[]>((resolve, reject) => {
        if (index >= fileList.length) {
            resolve(resultArray); 
            return;
        }

        const file = fileList[index];
        let currentFileRead = resultArray.find(x=>x.index == index) ?? {
          index : index,
          file: file,
          rows: []
        }
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          delimiter: ",",
          
          step: (results, parser) => {          
            return this.handleStep(
              results,
              parser,
              currentFileRead,
              resultArray,
              totalSize,
              fileRows,
              start
            );
          },
          
          complete: async () => {
            await new Promise<void>((resolve, reject) => {
                const intervalId = setInterval(() => {
                    if (currentFileRead.rows.length == fileRows[index] || this.state.isCancel) {
                        clearInterval(intervalId);
                        resolve();
                    }
                }, 250); 
            });
            resultArray.push(currentFileRead)
            if(!this.state.isCancel){
              this.parseFilesSequentially(fileList, index + 1,start,totalSize,resultArray,fileRows)
              .then(resolve)
              .catch(reject);
            }
            else{
              reject()
            }            
          },
        });
    });
  };

  handleStep = async (
    results: Papa.ParseStepResult<unknown>,
    parser: Papa.Parser,
    currentFileRead: FilesRead,
    resultArray: FilesRead[],
    totalSize: number,
    fileRows: number[],
    start: number
  ): Promise<void> => {
    return new Promise<void>((innerresolve) => {
      setTimeout(async () => {
        await new Promise<void>((resolve, reject) => {
          const intervalId = setInterval(() => {
            if (!this.state.isPause) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
  
        if (this.state.isCancel) {
          parser.abort();
        } else {
          currentFileRead.rows.push(
            Object.fromEntries(
              Object.entries(results.data as FileRows).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            )
          );
          let otherRows = resultArray.filter((x) => x.index != currentFileRead.index);
          let otherTotalLength = otherRows.reduce((totalLength, fileRead) => {
            return totalLength + fileRead.rows.length;
          }, 0);
  
          const duration = Date.now() - start;
          const time = (totalSize * duration) / (currentFileRead.rows.length + otherTotalLength);
          let timeRemaining = Math.ceil((time - duration) / 1000);
          this.setState({
            timeRemain : timeRemaining
          })
          this.currentParser = parser;
          let curProgress = Math.round(
            ((currentFileRead.rows.length + otherTotalLength) * 100) / totalSize
          );
          this.setState({
            progress : curProgress
          })
  
          innerresolve();
        }
      }, 1000);
    });
  };

  parseCsv = (files: File[], totalSize: number,csvHeader:string[],fileRows:number[]) => {
    this.setState({
      isLoading: true,
      filesUploaded: files[0],
      progress: 0,
      fileSelected: null,
    });

    let resultArray: FilesRead[] = [];
    let start = Date.now();
    this.curProgress = 0;
    this.parseFilesSequentially(files,0,start,totalSize,resultArray,fileRows)
    .then((resultArray:FilesRead[]) => {
      this.readCsvComplete(resultArray.length>0 ? resultArray[0].rows : []);
     
    })
    .catch((error) => {
        this.cancelUpload()
    });
   
  };
  reFormatData = (input: any) => {
    const sanitizeValue = (value: any) => (value === undefined || value === "" ? "" : value);

    return input.map((entry: any) => {
        const transformedEntry = {
            first_name: sanitizeValue(entry["first name"]),
            last_name: sanitizeValue(entry["last name"]),
            email: sanitizeValue(entry["email"]),
            phone: sanitizeValue(entry["phone"]),
            business_info: {
                company_name: sanitizeValue(entry["company name"]),
                VAT_number: sanitizeValue(entry["vat number"]),
                business_category_id: sanitizeValue(entry["business category"])
            },
            billing_address: {
                country: sanitizeValue(entry["billing country"]),
                city: sanitizeValue(entry["billing state"]),
                pin_code: sanitizeValue(entry["billing zip code / post code"]),
                address1: sanitizeValue(entry["billing address 1"]),
                address2: sanitizeValue(entry["billing address 2"])
            },
            shipping_address: {
                country: sanitizeValue(entry["shipping country"]),
                city: sanitizeValue(entry["shipping state"]),
                pin_code: sanitizeValue(entry["shipping zip code / post code"]),
                address1: sanitizeValue(entry["shipping address 1"]),
                address2: sanitizeValue(entry["shipping address 2"])
            },
            note: {
                content: ""
            },
            contact_groups: {
                group_names: entry["group"] ? entry["group"].split(",").map((g: string) => sanitizeValue(g.trim())) : []
            }
        };
        
        return transformedEntry;
    });
};

  readCsvComplete = (resultArray: FileRows[]) => {
    this.currentParser = null;
    this.formRef.current?.reset();
    this.setState({ progress: 100 }, () => {
      let valuesArray: any[] = [];
      const data= this.reFormatData(resultArray)
      this.setState({importContactData:data})
      valuesArray = data.map((member: csvDataType, index: number) => {
        if (
          member.first_name &&
          member.last_name &&
          member.email &&
          this.validateEmail(member.email)
        ) {
          return {
            id: index + 1,
            attributes: {
              first_name: member.first_name,
              last_name: member.last_name,
              email: member.email,
              phone: member.phone,
              group: [
                ...(member.contact_groups.group_names || []).map((name:any, index:any) => ({
                  title: name,
                  id: index,
                })),
              ],
              company_name: member.business_info.company_name,
              business_category: member.business_info.business_category_id,
              VAT_number: member.business_info.VAT_number,
              billing_address: member.billing_address,
              shipping_address: member.shipping_address,
              created_at: new Date(),
            },
          };
        }
      });
      valuesArray = valuesArray.filter((member) => member != undefined);
      if (valuesArray.length > 0) {
        this.setState({
          isLoading: false,
          membersList: valuesArray,
        });
      } else {
        this.createToastNotification("Empty file. Please choose other file.");
        this.cancelUpload();
      }
    });
  };

  handleOnDrop = (files: FileList | null, event?: any) => {
    let isCsvFile = true;
    this.dragLeave();
    if (files) {
      let fl = files[0];
      if (fl != null) {
        let file_type = fl.type;
        if (file_type.toLowerCase() !== "text/csv") {
          isCsvFile = false;
        }
      }

      this.setState({
        isFileValidType: isCsvFile,
        isPause: false,
        isCancel: false,
      });
      if (!isCsvFile) {
        this.createToastNotification(
          "Incorrect file type. Please download the template provided"
        );
        return;
      }
      this.currentParser = null;

      if (this.state.filesUploaded !== null) {
        this.setState({
          isDeleteFile: true,
          fileSelected: files,
        });
      } else {
        this.readCsv(files);
      }
    }
  };

  importContact = () => {   
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      contacts: this.state.importContactData
    };

    

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importContactApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.importContactApiPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ViewContactDetail = (id:string) => {
    const viewCurrentContact = this.state.membersList.find((contactId:any)=>contactId.id === id)
    this.setState({
      viewCurrentContact : viewCurrentContact,
      isViewingContact: true,
    })
   
  }
  handleCloseViewContactModal = () => {
    this.setState({
      isViewingContact : false,
    })
  }
  setBulkTabsValue = (obj: any, val: any) => {
    this.setState({
      viewBulkTabsValue: val,
    });
  };

  formatCreatedDate = (inputDate: any): React.ReactNode => {
		const date = new Date(inputDate);
		
		// Define month names
		const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
		
		// Extract date components
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		
		// Format the date as "Month Day, Year"
		const formattedDate = `${month} ${day}, ${year}`;
		
		return formattedDate;
	}

}
// Customizable Area End