// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./DirectEmailsStyles.web";
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import { returnWindowLocation, convertToFiles } from "../../../components/src/ReusableFunctions";
import {sendOutline, saveDraft} from "./assets"

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  isMobile?: boolean;
}

interface S {
  tabsValue: any;
  popoveAnchorEl: any;
  isFileValidType: boolean;
  isCancel: boolean;
  isPause: false;
  isLoading: boolean;
  filesUploaded: File[];
  membersList: any;
  fileDrop: boolean;
  isSaveDraft: boolean;
  isMenuDrawerOpened: boolean;
  fromEmailAddress: string;
  emailSubject: string;
  composeEmailContent: string;
  chooseProductsModal: boolean;
  selectedValues: any;
  ccEmailValues: any;
  bccEmailValues: any;
  allDirectEmails: any;
  allDraftEmails:any;
  createEmailError: {
    fromEmailError: string;
    emailSubjectError: string;
    composeEmailError: string;
  };
  emailcount: string;
  composeEmailList: any;
  isViewingEmail: boolean;
  selectedProductIds: any;
  totalproductAmount: any;
  selectedProductsData: any;
  productsQuantity: any;
  viewingAttachedProducts: boolean;
  brevoAPIKey: string;
  brevoAPIKeyError: string;
  isConnectingBrevoAccount: boolean;
  viewEmailDetail: any;
  isBrevoVerified: boolean;
  getFromEmailList: any;
  searchEmailTxt: string;
  isSearching: boolean;
  hasAttachments: boolean;
  emailFilterDate: any;
  sortByValue:string;
  isSorting: boolean;
  selectedContact:string;
  isEmailDraft: boolean;
  marketingCount: any;
  isImageView: boolean;
  currentIndex: any;
  currentId: any;
  emailGroups:any;
  isDraft: boolean;
  currentPage: number;
  emailsPerPage:number;
  anchorElColmun:any;
  checkedColumns :{
    recipients: boolean;
    messages: boolean;
    subjects: boolean;
    date: boolean;
    attachments: boolean;
  };
  pdfCatalogueUrl: string;
  minimizeDirectEmail: boolean;
  showCCFields: boolean;
}
interface SS {
  id: any;
}

export default class DirectEmailsHomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  welcomeTitle: string;
  welcomeHyperTitle: string;
  welcomeButtonText: string;
  getAllDirectEmailsAPIRequestId: string = "";
  createEmailAPIRequestId: string = "";
  getAllContactsAPIRequestId: string = "";
  validateBrevoAPIRequestId: string = "";
  getSingleEmailAPIRequestId: string = "";
  getBrevoCheckStatusRequestId: string = "";
  getFromEmailAPIRequestId: string = "";
  getDirectEmailsCountAPIId: string = "";
  getBrevoGroupsAPICallId:string = "";
  getProductCataloguePdfAPICallId:string = "";
  deleteDirectEmailAPIRequestId: string= "";
  userSessionData: any;
  userToken: any;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.welcomeTitle = configJSON.welcomeTitle;
    this.welcomeHyperTitle = configJSON.welcomeHyperTitle;
    this.welcomeButtonText = configJSON.welcomeButtonText;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    this.state = {
      tabsValue: "",
      popoveAnchorEl: null,
      isFileValidType: true,
      isCancel: false,
      isPause: false,
      isLoading: false,
      filesUploaded: [],
      membersList: [],
      fileDrop: false,
      isSaveDraft: false,
      isMenuDrawerOpened: false,
      fromEmailAddress: "",
      emailSubject: "",
      composeEmailContent: "",
      chooseProductsModal: false,
      selectedValues: [],
      ccEmailValues:[],
      bccEmailValues:[],
      allDirectEmails: [],
      allDraftEmails:[],
      createEmailError: {
        fromEmailError: "",
        emailSubjectError: "",
        composeEmailError: "",
      },
      emailcount: "",
      composeEmailList: [],
      emailGroups:[],
      isViewingEmail: false,
      selectedProductIds: [],
      totalproductAmount: 0,
      productsQuantity: 0,
      selectedProductsData: [],
      viewingAttachedProducts: false,
      brevoAPIKey: "",
      isConnectingBrevoAccount: false,
      brevoAPIKeyError: "",
      viewEmailDetail: [],
      isBrevoVerified: false,
      getFromEmailList: [],
      searchEmailTxt: "",
      isSearching: false,
      hasAttachments: false,
      emailFilterDate: [],
      sortByValue:'',
      isSorting: false,
      selectedContact: '',
      isEmailDraft: false,
      marketingCount: [],
      isImageView: false,
      currentIndex: 0,
      currentId: 0,
      isDraft: false,
      currentPage: 0,
      emailsPerPage:20,
      anchorElColmun: null,
      checkedColumns :{
        recipients: false,
        messages: false,
        subjects: false,
        date: false,
        attachments: false,
      },
      pdfCatalogueUrl: '',
      minimizeDirectEmail: false,
      showCCFields: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponseAPI = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (errorResponseAPI || !responseJson || !apiRequestCallId) {
        this.setState({ isLoading: false });
        return;
      }
      switch (apiRequestCallId) {
        case this.getAllDirectEmailsAPIRequestId:
          this.handleAllEmailsList(responseJson);
          break;
        case this.createEmailAPIRequestId:
          this.handleEmailResponses();
          break;
        case this.getAllContactsAPIRequestId:
          this.handleComposeList(responseJson.data);
          break;
        case this.validateBrevoAPIRequestId:
          this.setState({ isLoading: false });
          if (responseJson.message.response === "success") {
            this.checkBrevoStatus();
            this.composeEmailSuccess("Account validated succesfully");
            this.getDirectEmails();
          } else {
            this.errorToastMessage(responseJson.message);
          }
          break;
        case this.getSingleEmailAPIRequestId:
          this.setState({ isLoading: false });
          this.setState({ viewEmailDetail: responseJson.data.attributes });
          break;
        case this.getBrevoCheckStatusRequestId:
          this.setState({
            isBrevoVerified: responseJson.data.attributes.brevo_verified,
          });
          break;
        case this.getFromEmailAPIRequestId:
          this.setState({ getFromEmailList: responseJson.data.senders });
          break;
        case this.getDirectEmailsCountAPIId:
          this.setState({
            marketingCount : responseJson.marketings,
          })
          break;
        case this.getBrevoGroupsAPICallId:
          this.setState({isLoading: false, emailGroups: responseJson.data.lists });
          break;
        case this.deleteDirectEmailAPIRequestId:
          this.handleDeleteDirectEmails(responseJson);
          break;
        case this.getProductCataloguePdfAPICallId:
          this.setState({
            isLoading : false,
            pdfCatalogueUrl : responseJson.pdf_url,
          })
          break;
        default:
          break;
      }
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.emailSubject !== this.state.emailSubject) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          emailSubjectError: "",
        },
      });
    }
    if (prevState.composeEmailContent !== this.state.composeEmailContent) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          composeEmailError: "",
        },
      });
    }
    
  }

  async componentDidMount() {
    this.getDirectEmails();
    this.fetchToContactLists();
    this.checkBrevoStatus();
    this.getFromEmailAddressList();
    this.getDirectEmailCount();
    this.getAllGroupsFromBrevo();
    const currentPath = returnWindowLocation(window)
    if (currentPath === "/direct-emails-drafts") {
      this.setState({ isDraft: true });
    }
    const langT = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(langT);
  }
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  handleClose = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }
    this.setState({
      isSaveDraft: false,
    });
  };

  

  popoverHandleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({ popoveAnchorEl: event.currentTarget });
    }
  };

  popoverHandleClose = () => {
    this.setState({  popoveAnchorEl: null});
  };

  saveComposeMailasDraft = () => {
    this.setState({   isEmailDraft: true });
  }

  closeDraftEmailModal = () => {
    this.setState({ popoveAnchorEl: null, isEmailDraft: false });
  }

  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  handleOnDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject: FileList;
    if (e.target.files != null) {
      fileObject = e.target.files;
      const arrayLength = this.state.filesUploaded.length;
      //if(arrayLength<6){
      this.setState({
        filesUploaded: [
          ...this.state.filesUploaded,
          ...Object.values(fileObject),
        ],
      });
      //}
    }
  };

  saveDraft = () => {
    this.setState({ isSaveDraft: true });
  };

  removeFileUPload = (index: any) => {
    const updatedArray = [
      ...this.state.filesUploaded.slice(0, index),
      ...this.state.filesUploaded.slice(index + 1),
    ];
    this.setState({ filesUploaded: updatedArray });
  };

  toggleMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: true,
    });
  };
  closeMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: false,
    });
  };
  openChooseProductsModal = () => {
    this.setState({ chooseProductsModal: true });
  };
  closeChooseProductsModal = () => {
    this.generateCataloguePdfs()
    this.setState({ chooseProductsModal: false });
    const {productsQuantity, selectedProductsData} = this.state;
    const updatedProducts = this.state.selectedProductsData.map((product:any) => {
      const productId = product.id;
      const price = product.attributes?.prices[0]?.amount || 0;
      const updatedStockQty = productsQuantity[productId] ?? product.attributes.stock_qty;
      const totalAmount = updatedStockQty * price;
      return {
        ...product,
        attributes: {
          ...product.attributes,
          stock_qty: updatedStockQty,
          totalAmount,
        },
      };
    });
    this.setState(
      {
        selectedProductsData: updatedProducts,
      }
    );
 
  };

  selectedEmailProducts = (
    productsSelected: string[],
    totalAmount: number | null,
    selectedProductsData: [],
    productsQuantity:number | null,
  ) => {
    this.setState(
      {
        selectedProductIds: productsSelected,
        selectedProductsData: selectedProductsData,
        totalproductAmount: totalAmount,
        productsQuantity: productsQuantity,
      },
      () => {
        this.closeChooseProductsModal();
      }
    );
  };
  openAttachedProducts = () => {
    this.setState({
      viewingAttachedProducts: true,
    });
  };

  saveDraftEmail = () => {
    this.setState({
      minimizeDirectEmail: true,
      popoveAnchorEl: null,
    });
  }

  maximizeEmailForm = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
    this.setState({
      minimizeDirectEmail: false,
      popoveAnchorEl: event.currentTarget
    });
  }
  }

  closeFloatEmailForm = () => {
    this.setState({
      minimizeDirectEmail: false,
    })
  }

  handleEmailEscEvent = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.saveDraftEmail();
    }
  }
  closeAttachedProducts = () => {
    this.setState({
      viewingAttachedProducts: false,
    });
  };
  handleToEmailChange = (name: any, value: any) => {
    this.setState({ selectedValues: value });
  };
  handleCCEmailChange = (event: any, value: any) => {
    this.setState({ ccEmailValues: value });
  };
  handleBCCEmailChange = (event: any, value: any) => {
    this.setState({ bccEmailValues: value });
  }
  handleComposeEmailInput = (name: any, value: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleFromAddress = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ fromEmailAddress: event.target.value });
    }
  };
  viewEmailDetail = (mailId: string) => {
    this.setState({ isViewingEmail: true });
    this.getSingleEmaildetail(mailId);
  };
  closeViewDetailMail = () => {
    this.setState({ isViewingEmail: false });
  };
  handleEmailSearch = (searchValue: string) => {
    this.setState(
      {
        searchEmailTxt: searchValue,
        isSearching: searchValue !== "",
      },
      () => this.getDirectEmails()
    );
  };
  toggleHasAttachments = (event: any) => {
    const { checked } = event.target;
    this.setState({ hasAttachments: checked, isSearching: checked }, () =>
      this.getDirectEmails()
    );
  };
  handleEmailFilterDates = (date: any) => {
    this.setState({ emailFilterDate: date }
    );
  };
  handleFilterContacts = (event: {target : {value: string}}) => {
    this.setState({ selectedContact: event.target.value }, () => this.getDirectEmails());
  }
  clearAction = () => {
    this.setState({ emailFilterDate: [] },() => this.getDirectEmails())
  }
  sortEmailsType = (event: any) => {   
    this.setState({
      sortByValue : event.target.value,
      isSorting: true,
    }, () => this.getDirectEmails() )
  }
  getDirectEmails = () => {
    const { searchEmailTxt, hasAttachments, emailFilterDate, isSorting, sortByValue, selectedContact } =
      this.state;
    const header = {
      token: this.userToken.meta.token,
    };
    let url = "";
    let baseURL = configJSON.getDirectEmailsEndPoint;
    if(isSorting && sortByValue === 'Oldest to newest'){
      url += `sort=id&sort_direction=asc&`
    }else {
      url += `sort=id&sort_direction=desc&`
    }

    if (searchEmailTxt !== "") {
      url += `search_key=${searchEmailTxt}&`;
    }
    if (hasAttachments) {
      url += `has_attachments=${hasAttachments}&`;
    }
    if(emailFilterDate.length == 2){
      url += `start_date=${emailFilterDate[0].format("DD-MM-YYYY")}&`;
      url += `end_date=${emailFilterDate[1].format("DD-MM-YYYY")}&`;
    } 
    
    if (selectedContact !== "") {
      url += `to[]=${selectedContact}&`;
    }

    let endPoint = baseURL + url;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDirectEmailsAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createDirectEmails = async () => {
    let errorFlag = false;
    let emailStatus = 'sent';
    const {
      fromEmailAddress,
      emailSubject,
      composeEmailContent,
      selectedProductIds,
      filesUploaded,
      selectedValues,
      ccEmailValues,
      bccEmailValues,
      isEmailDraft,
    } = this.state;
    if(!isEmailDraft){
    let fromEmailError = this.state.createEmailError.fromEmailError;
    let emailSubjectError = this.state.createEmailError.emailSubjectError;
    let composeEmailError =  this.state.createEmailError.composeEmailError
    if (fromEmailAddress === "") {
      fromEmailError = "Please enter from email address";
      errorFlag = true;
    }
    if (emailSubject.trim() === "") {
      emailSubjectError = "Please enter email subject";
      errorFlag = true;
    }
    if(composeEmailContent.trim() === ""){
      composeEmailError = "Please enter email description";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          fromEmailError: fromEmailError,
          emailSubjectError: emailSubjectError,
          composeEmailError: composeEmailError,
        },
      });
      return true;
    }
  }
    const formdata = new FormData();
    const contactIds = selectedValues.filter((item:any) => item.type === 'contact').map((contact:any) => contact.id).join(',');
    const groupIds = selectedValues.filter((item:any) => item.type === 'group').map((group:any) => group.id).join(',');
    const formattedEmailContent = composeEmailContent
  .split('\n\n')
  .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br />').trim()}</p>`)
  .join('');

    if (contactIds) {
      formdata.append('direct_mail[contact_ids]', contactIds);
    }
    if (groupIds) {
      formdata.append('direct_mail[group_ids]', groupIds);
    }
    if(ccEmailValues.length > 0){
      ccEmailValues.forEach((selectedValue: any) => {
        formdata.append(`direct_mail[cc][][email]`, selectedValue.email);
        formdata.append(`direct_mail[cc][][name]`, selectedValue.first_name);
      });
    }
    if(bccEmailValues.length > 0){
      bccEmailValues.forEach((selectedValue: any) => {
        formdata.append(`direct_mail[bcc][][email]`, selectedValue.email);
        formdata.append(`direct_mail[bcc][][name]`, selectedValue.first_name);
      });
    }
 
    formdata.append("direct_mail[sender][email]", fromEmailAddress);
    formdata.append("direct_mail[sender][name]", "test");
    formdata.append("direct_mail[subject]", emailSubject);
    formdata.append("direct_mail[html_content]", formattedEmailContent);
    if (selectedProductIds.length > 0) {
      if(this.state.pdfCatalogueUrl !== ""){
      let fileObj = {url:this.state.pdfCatalogueUrl,filename:"Product_catelogue.pdf",content_type	: "application/pdf"}
      let existingFile = await convertToFiles([fileObj])
      formdata.append("direct_mail[mail_attachments][]", existingFile[0], existingFile[0].name);
      }
      
      selectedProductIds.forEach((productId: any) => {
        formdata.append("direct_mail[product_ids][][id]", productId.toString());
        formdata.append("direct_mail[total_amount]", this.state.totalproductAmount);
        formdata.append("direct_mail[product_ids][][quantity]", this.state.productsQuantity);
      });
    }
    if(isEmailDraft){
      emailStatus = "draft"
    }
    formdata.append("direct_mail[status]", emailStatus);
    if (filesUploaded.length > 0) {
      filesUploaded.forEach((file) => {
        formdata.append("direct_mail[mail_attachments][]", file, file.name);
      });
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEmailAPIRequestId = requestMessage.messageId;
    const header = {
      token: this.userToken.meta.token,
    };

    this.createEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDirectEmailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );

    //
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchToContactLists = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllContactsAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchAllContacts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleComposeList = (composeList: any) => {
    const composeEmailList = composeList.map((contact: any) => {
      return {
        id: contact.attributes.id,
        first_name: contact.attributes.first_name.trim(),
        last_name: contact.attributes.last_name.trim(),
        email: contact.attributes.email.trim(),
        type: 'contact',
      };
    });
    this.setState({
      composeEmailList: composeEmailList,
    });
  };
  handleDeleteDirectEmails = (responseJson:any) => {
    this.setState({isLoading: false})
    this.getDirectEmails();
    this.getDirectEmailCount();
    this.closeViewDetailMail();
    this.composeEmailSuccess(responseJson.message,sendOutline);
  }
  handleAllEmailsList = (responseJson:any) => {
    const allEmails = responseJson.data;
    const drafts = allEmails.filter((item:any) => item.attributes.status === "draft");
    const sent = allEmails.filter((item:any) => item.attributes.status === "sent");
    this.setState({ isLoading: false });
    this.setState({ allDirectEmails: sent, allDraftEmails: drafts});
  }
  handleEmailResponses = () => {
    this.setState({ isLoading: false });
    this.getDirectEmails();
    this.popoverHandleClose();
    if(this.state.isEmailDraft){
      this.composeEmailSuccess("Draft Saved",saveDraft);
    }else{
      this.composeEmailSuccess("Email sent",sendOutline);
    }
   
    this.setState({
        fromEmailAddress : "",
        emailSubject : "",
        composeEmailContent: '',
        selectedProductIds: [],
        filesUploaded: [],
        selectedValues: [],
        isEmailDraft: false,
    })
  }
  handleConnectBrevoAccount = () => {
    this.setState({ isConnectingBrevoAccount: true });
  };
  closeBrevoInput = () => {
    this.setState({ isConnectingBrevoAccount: false });
  };
  handleBrevoAPIKeyInput = (apiKeyVal: string) => {
    this.setState({ brevoAPIKey: apiKeyVal });
  };
  composeEmailSuccess = (toastMessage: string, toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMessage}</div>    
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
       
      }
    );
  };
  errorToastMessage = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  validateBrevoAccount = () => {
    let errorFlag = false;

    let brevoAPIKeyEmpty = this.state.brevoAPIKeyError;
    if (this.state.brevoAPIKey.trim() === "") {
      brevoAPIKeyEmpty = "Please enter brevo api key";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        brevoAPIKeyError: brevoAPIKeyEmpty,
      });
      return true;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      api_key: this.state.brevoAPIKey,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateBrevoAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateAccountBrevo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSingleEmaildetail = (mailId: string) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleMail + `${mailId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkBrevoStatus = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrevoCheckStatusRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getFromEmailAddressList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFromEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSenderList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDirectEmailCount = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDirectEmailsCountAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCounts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    //* Making Network Request
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  viewImageModal = (itemId: number) => {
    const index = this.state.viewEmailDetail?.attachments.findIndex((thumbnail: {
      id: number;
      filename: string;
      url: string;
    }) => thumbnail.id === itemId);
    this.setState({ currentIndex: index, currentId: itemId, isImageView: true });
  };
  viewImageModalClose = () => {
    this.setState({ isImageView: false });
  };
  handleAfterChange = (currentSlide: number) => {
    const {viewEmailDetail} = this.state;
    const currentImage = viewEmailDetail.attachments[currentSlide];
    this.setState({ currentIndex: currentSlide, currentId: currentImage?.id });
  };
  getAllGroupsFromBrevo = () => {
    
    let endPoint;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
   
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrevoGroupsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBrevoGroupLists
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteDirectEmail = (emailId:string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
    let endPoint = configJSON.deleteDirectMail + `/${emailId}`
   
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDirectEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleEmailsPerChange = (event: unknown, newPage: number) => {
   
    this.setState({
      currentPage: newPage
    })
  }
  handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailsPerPage: (parseInt(event.target.value, 20)),
      currentPage: 0

    })
  }
  handleColumnOpenMenu = (event: any) => {
    if (event) {
      this.setState({ anchorElColmun: event.target });
    }
  };
  handleColumnCloseMenu = () => {
    this.setState({ anchorElColmun: null });
  };
  handleCheckedHideColumns = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkedColumns: {
        ...prevState.checkedColumns,
        [name]: checked,
      },
      
    }));
  };
  generateCataloguePdfs = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const catalogueIds = this.state.selectedProductIds
    const catalogueIdsString = catalogueIds.join(',');    
    let endPoint = configJSON.pdfCatalogue + `?catalogue_ids[]=${catalogueIdsString}`
  
 
  const requestMessage: Message = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getProductCataloguePdfAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

 

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  //* Making Network Request
  this.setState({ isLoading: true });

  runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  removeProductAttachments = () => {
    this.setState({
      pdfCatalogueUrl : "",
      selectedProductsData: [],
      selectedProductIds:[],
    })
  }
  showCCBCCFields = (e:any) => {
    e.stopPropagation()
    this.setState((prevState) => ({
      showCCFields: !prevState.showCCFields,
    }));
  }
}
// Customizable Area End
