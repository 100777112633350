// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GlobalHeaderWeb from "../../../customisableuserprofiles/src/GlobalHeader.web";
import TaskListSideBarController ,{ configJSON } from "./TaskListSideBarController.web";
import Tasklist from "./TaskList.web";
import Projects from "./Projects.web";
import { customStyles } from "./TaskListSidebarStyles.web";
import { menu } from "../assets";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  id?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${id ?? ""}${index}`}
      aria-labelledby={`vertical-tab-${id ?? ""}${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any, id: string = "") {
  return {
    id: `vertical-tab-${id}${index}`,
    "aria-controls": `vertical-tabpanel-${id}${index}`,
    value: index,
    key: `${id}${index}`,
  };
}

class TaskListSideBar extends TaskListSideBarController {

  render() {
    const { classes } = this.props;
    const projectSidebar = this.state.projectList;
    const taskListSidebar = this.state.projectTaskList;
    return (
      <Box className={classes.tasklistWrapper}>
        <GlobalHeaderWeb />
        <Box style={{marginTop: "200px"}} className="mobile-heading">
          <Box className="back-btn-wrap">
            <i>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
          </Box>
          <Typography className="heading" variant="h1">
            {this.t(`${configJSON.allProjectsTxt}`)}
          </Typography>
          <Box
            className="profile-menu"
            onClick={this.toggleSidebarDrawer}
            data-test-id="toggleSideBar"
          >
            <img src={menu} alt="menu" />
          </Box>
        </Box> 
        <Box className={classes.tasklistRoot}>
          <Box className={`tasklist-sidebar ${this.state.isSidebarDrawerOpened ? "open" : ""}`}>
            <Box className="sidebar-title">
              <Box className="back-btn-wrap" onClick={this.closeSidebarDrawer} data-test-id="back-btn-wrap">
                <i>
                  <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                  </svg>
                </i>
              </Box>
              <Typography className="heading" variant="h1">
                {this.t(`${configJSON.allProjectTxt}`)}
              </Typography>
            </Box>
            <Accordion className="sidebar-accodion" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Projects-content"
                id="Projects-header"
                className="sidebar-heading"
              >
               {this.t(`${configJSON.projectsTxt}`)}
              </AccordionSummary>
              <AccordionDetails className="sidebar-details">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={this.state.projectsTabsValue}
                  aria-label="Vertical tabs example"
                >
                  <Tab style={{ display: "none" }} {...a11yProps(0)} />
                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">
                          {this.t(`${configJSON.allProjectsTxt}`)}
                        </Box>
                        {/* <Box component="span" className="tab-no">
                          {this.state.projectList.length}
                        </Box> */}
                      </>
                    }
                    {...a11yProps(1)}
                    onClick={() => this.setProjectsTabsValue(null, 1)}
                  />

                  {projectSidebar.map((project: any) => {
                    const projId = Number(project.id) + 1;
                    let returnTabs = [
                      <Tab
                        className={`${
                          this.state.projectsTabsValue == projId &&
                          "Mui-selected"
                        }`}
                        label={
                          <>
                            <Box component="span" className="tab-label">
                              {/* New Wine Launch */}
                              {project.attributes.title}
                            </Box>
                            <Box component="span" className="tab-no">
                              {project.attributes.tasks.data.length ?? 0}
                            </Box>
                          </>
                        }
                        {...a11yProps(projId)}
                        onClick={() => this.setProjectsTabsValue(null, projId)}
                        data-test-id={`project-${projId}`}
                      />,
                    ];
                    if (
                      this.state.expandedProject == projId 
                    ) {
                      let tlArray = this.state.projectTaskList.map((tl) => {
                        return (
                          <Tab
                            className={`sub-tab ${
                              this.state.projectInnerTabsValue == Number(tl.id) &&
                              "Mui-selected"
                            }`}
                            label={
                              <>
                                <Box component="span" className="tab-label">
                                  {tl.attributes.name}
                                </Box>
                                <Box component="span" className="tab-no">
                                  {tl.attributes.tasks.data.length ?? 0}
                                </Box>
                              </>
                            }
                            {...a11yProps(Number(tl.id), "projectInner")}
                            onClick={() =>
                              this.setprojectInnerTabsValue(null, Number(tl.id))
                            }
                            data-test-id={`tasklist-${Number(tl.id)}`}
                          />
                        );
                      });
                      returnTabs.push(...tlArray);
                    }
                    return returnTabs;
                  })}
                </Tabs>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion className="sidebar-accodion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Tasklists-content"
                id="Tasklists-header"
                className="sidebar-heading"
              >
                Tasklists
              </AccordionSummary>
              <AccordionDetails className="sidebar-details">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={this.state.tasklistTabsValue}
                  onChange={this.setTasklistsTabsValue}
                  aria-label="Vertical tabs example"
                >
                  {/*                   
                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">
                          Tasklists 1
                        </Box>
                        <Box component="span" className="tab-no">
                          25
                        </Box>
                      </>
                    }
                    {...a11yProps(1,"tl")}
                  /> 
                  {taskListSidebar.map((tasklist: any) => {
                    return (
                      <Tab
                        label={
                          <>
                            <Box component="span" className="tab-label">
                              {tasklist.attributes.name}
                            </Box>
                            <Box component="span" className="tab-no">
                              {tasklist.id}
                            </Box>
                          </>
                        }
                        {...a11yProps(Number(tasklist.id), "tl")}
                      />
                    );
                  })}
                </Tabs>
              </AccordionDetails>
            </Accordion> */}
            <Accordion className="sidebar-accodion" data-test-id="tabsAccordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Tasks-content"
                id="Tasks-header"
                className="sidebar-heading"
              >
                {this.t(`${configJSON.txtTasks}`)}
              </AccordionSummary>
              <AccordionDetails className="sidebar-details">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={this.state.tasksTabsValue}
                  onChange={this.setTasksTabsValue}
                  aria-label="Vertical tabs example"
                  data-test-id={`allTaskTab`}
                >
                  <Tab style={{ display: "none" }} {...a11yProps(0, "ts")} />
                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">
                          {this.t(`${configJSON.allTasksTxt}`)}
                        </Box>
                        <Box component="span" className="tab-no">
                          {this.state.taskList.length}
                        </Box>
                      </>
                    }
                    {...a11yProps(1, "ts")}
                  />

                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">                          
                          {this.t(`${configJSON.todayTxt}`)}
                        </Box>
                        <Box component="span" className="tab-no">
                          {this.state.todayTask.length}
                        </Box>
                      </>
                    }
                    {...a11yProps(2, "ts")}
                  />
                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">
                          {this.t(`${configJSON.tomorrowTxt}`)}
                        </Box>
                        <Box component="span" className="tab-no">
                          {this.state.tomorrowTask.length}
                        </Box>
                      </>
                    }
                    {...a11yProps(3, "ts")}
                  />
                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">
                          {this.t(`${configJSON.thisWeekTxt}`)}
                        </Box>
                        <Box component="span" className="tab-no">
                          {this.state.thisWeekTask.length}
                        </Box>
                      </>
                    }
                    {...a11yProps(4, "ts")}
                  />
                  <Tab
                    label={
                      <>
                        <Box component="span" className="tab-label">
                          {this.t(`${configJSON.thisMonthTxt}`)}
                        </Box>
                        <Box component="span" className="tab-no">
                          {this.state.thisMonthTask.length}
                        </Box>
                      </>
                    }
                    {...a11yProps(5, "ts")}
                  />
                </Tabs>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className="tasklist-content">
            <TabPanel value={this.state.projectsTabsValue} index={0}></TabPanel>
            <TabPanel
              value={this.state.tasksTabsValue}
              index={0}
              id={"ts"}
            ></TabPanel>
            <TabPanel value={this.state.projectsTabsValue} index={1}>
              <Projects redirectTo={()=>this.redirectTo("AllProject")} updateData={this.callOnUpdate}/>
            </TabPanel>

            {projectSidebar.map((project: any) => {
              return (
                <TabPanel
                  value={this.state.projectsTabsValue}
                  index={Number(project.id) + 1}
                  key={Number(project.id) + 1}
                >
                  <Tasklist
                    projectId={Number(project.id)}
                    updateData={this.callOnUpdate}
                    redirectTo={this.redirectTo}
                  />
                </TabPanel>
              );
            })}

            {taskListSidebar.map((taskList: any) => {
              return (
                <TabPanel
                  value={this.state.projectInnerTabsValue}
                  index={Number(taskList.id)}
                  key={Number(taskList.id) + 1}
                  id={"tl"}
                >
                  <Tasklist
                    taskListId={Number(taskList.id)}
                    updateData={this.callOnUpdate}
                    projectId={this.state.expandedProject-1}
                    redirectTo={this.redirectTo}
                  />
                </TabPanel>
              );
            })}

            <TabPanel value={this.state.tasksTabsValue} index={1} id={"ts"}>
              <Tasklist updateData={this.callOnUpdate} />
            </TabPanel>
            <TabPanel value={this.state.tasksTabsValue} index={2} id={"ts"}>
              <Tasklist tab={1} updateData={this.callOnUpdate} />
            </TabPanel>
            <TabPanel value={this.state.tasksTabsValue} index={3} id={"ts"}>
              <Tasklist tab={2} updateData={this.callOnUpdate} />
            </TabPanel>
            <TabPanel value={this.state.tasksTabsValue} index={4} id={"ts"}>
              <Tasklist tab={3} updateData={this.callOnUpdate} />
            </TabPanel>
            <TabPanel value={this.state.tasksTabsValue} index={5} id={"ts"}>
              <Tasklist tab={4} updateData={this.callOnUpdate} />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(TaskListSideBar);
// Customizable Area End
