Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";
exports.txtHourlyPayout = "HOURLY PAYOUT";

exports.txtMyTeams = "My Teams";
exports.txtOrderHistory = "Order History";
exports.txtTimesheet = "Time Sheet";
exports.txtSchedule = "Schedule";
exports.txtHelpCenter = "Help Center";
exports.txtSupportChat = "Support Chat";
exports.addManuallyTxt="Add Manually";
exports.bulkUploadTxt="Bulk Upload";
exports.txtMyTeamsSubtitle = "Manage your team and move users.";
exports.txtMyTeamsSubtitleEmployee = "See your team and other company members.";
exports.txtOrderHistorySubtitle = "See what you have ordered in the past.";
exports.txtTimesheetSubtitle = "Make and download employee timesheets.";
exports.txtScheduleSubtitle = "Set your working days and time off.";
exports.txtHelpCenterSubtitle = "Raise a ticket or view the status.";
exports.txtSupportChatSubtitle = "Chat with the support team.";

exports.profileEndPoint = "/account_block/profile_list";
exports.methodGET = "GET";
exports.methodPOST = "POST";
exports.methodPUT = "PUT";
exports.methodDELETE = "DELETE";

exports.errorFirstnameNotValid = "First name should not be blank";
exports.errorlastNameNotValid = "Last name should not be blank";
exports.updateProfileEndPoint = "account_block/edit_details";
exports.requestMethodPatch = "PATCH";
exports.contentTypeApiAddDetail = "application/json";
exports.webgetMembersList = "/account_block/member_list";
exports.webUpdateUserEmail = "/account_block/edit_email";
exports.notifications = "bx_block_push_notifications/notifications";
exports.markReadAllNotification = "bx_block_push_notifications/read_all";
exports.webUpdateUserPassword = "account_block/edit_password";
exports.webRemoveMember = "account_block/remove_member";
exports.webUpdateMemberTeam = "account_block/update_member_team";
exports.webEditMemberDetails = "account_block/edit_member_details";
exports.webDeleteJobTitleEndPoint = "account_block/jobtitle_destroy";
exports.renameJobTitleEndPoint = "account_block/title_update";
exports.getJobTitlesEndPoint = "account_block/title_list";
exports.createNewJobTitleEndPoint = "account_block/title_create";
exports.webGetCountryList = "account_block/country";
exports.webGetCityList = "account_block/city";
exports.webGetStateList = "account_block/state";
exports.webTeamsGetEndPoint = "account_block/teams";
exports.errorPasswordNotValid = "Password does not meet requirements";
exports.undoActionAPIEndPint = "account_block/undo"
exports.personalInfo = "Personal Information";
exports.firstName = "First name";
exports.lastName = "Last name";
exports.email="Email";
exports.changeEmail="Change Email";
exports.changePassword="Change Password";
exports.addNewJobtxt ="Add New job Title";
exports.noJobTitle="No Job title found with";
exports.jobTitle="Job title";
exports.noJobTitleCreated ="No job title to create";
exports.deleteJobTitke="Are you sure you want to delete Job title ?";
exports.delete = "Delete";
exports.deleteTitle="Delete Job Title";
exports.hourlyPay= "Hourly Pay";
exports.calculateTxt="This is used to calculate labor cost for projects. This will not be shown to other members."
exports.businessInfoTxt ="Business Information";
exports.companyName="Company Name";
exports.billingAddress="Billing Address";
exports.country="Country";
exports.state="State";
exports.postalCode="Postal Code";
exports.city="City";
exports.companyAddress="Company Address";
exports.save="Save";
exports.newEmail="New Email";
exports.enterNewEmailTxt="Please enter the new email address.";
exports.emailAddress="Email Address";
exports.confirmationTxt=" A confirmation link will be sent to the new email address.The user will need to click on the link to verify new email."
exports.backToEdit="Back to Edit";
exports.resendIn="Resend in";
exports.didntReceiveEmail="Didn't receive the email? Resend";
exports.sendConfirmation="Send Confirmation";
exports.createPassword="Create your new password";
exports.oldPassword = "Old Password";
exports.newPassWord="New Password";
exports.letsInviteMember="Let's invite more members";
exports.myTeamText="My Teams";
exports.inviteMember="Invite Members";
exports.addTeam="Add Team";
exports.selectTeamText="Select the team(s) to add yourself as a member.";
exports.removeConfirmationTxt ="Are you sure you want to remove yourself from {{title}} team?";
exports.remove="Remove";
exports.removeTeam="Remove Team";
exports.members="Members";
exports.dashboard="Dashboard";
exports.inventory="Inventory";
exports.marketing="Marketing";
exports.etohShop="EtOH Shop";
exports.taskList="Tasklist";
exports.createNew="Create New";
exports.notificationsTxt="Notifications";
exports.notificationsSubTxt="Stay up to date with your notifications";
exports.markAllAsRead="Mark all as read";
exports.loading="Loading...";
exports.profile="Profile";
exports.settings="Settings";
exports.helpCenter="Help Center";
exports.logout="Logout";
exports.txtCancel="Cancel";
exports.exitWithoutSave="Exit without saving";
exports.exit="Exit";
exports.saveTxt="Save";
exports.exitSubTxt="Are you sure you want to exit without saving?"
// Customizable Area End
