// Customizable Area Start
import React, { useState } from "react";
import {
  Typography,
  Modal,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { closeGray } from "../../blocks/TaskList/src/assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { extractItemDetails } from "./ReusableFunctions";
import CustomInputWeb from "../src/CustomInput.web";

interface CustomInputProps {
  modalOpen: any;
  modalClose: any;
  classes: any;
  data?: any;
  t: any;
  itemID: string;
}

const EditConsumableModal = (props: CustomInputProps) => {
  const { modalOpen, classes, modalClose, t, itemID, data } = props;
  const result = data.find((item: any) => item.id === itemID);
  const { itemName, dosage } = extractItemDetails(result);
  const [dosageChange, setDosageChange] = useState(dosage);
  const handleChange = (event: any) => {
    setDosageChange(event.target.value);
  };
  const [consumables, setConsumables] = useState(itemName);
  const handleCounsumablesChange = (event: any) => {
    setConsumables(event.target.value);
  };
  const fractionValues = ["1", "1/2", "1/3", "1/4", "1/5"];
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.productListViewModal}>
        <Box className="modal-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
              {t("Edit")}&nbsp;{itemName}
            </Typography>
            <Box component="p" className="sub-txt">
              {t("Please update the information below.")}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link to="#" className="heading-icon" onClick={modalClose}>
              <img src={closeGray} alt="close-icon" />
            </Link>
          </Box>
        </Box>
        <Box className="form-row">
          <Box className="form-col">
            <Box className="form-control">
              <CustomInputWeb
                placeholder={t("Consumables")}
                label={t("Consumables")}
                fullWidth={true}
                autoFocus={true}
                value={consumables}
                name="assigneeSearchText"
                onChange={handleCounsumablesChange}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className="form-row">
          <Box className="form-col">
            <Box className="form-control">
              <FormControl className="select-outer" variant="outlined">
                <InputLabel>{t("Dosage")}</InputLabel>
                <Select
                  label={t("Dosage")}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: {
                      paper: `${classes.dropdownStyle}`,
                    },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  data-test-id="fractionDropdown"
                  disableUnderline
                  value={dosageChange}
                  onChange={handleChange}
                >
                  {fractionValues.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box className="quantity-container">
          <Typography className="liters-text">6.33 liters</Typography>
          <Typography className="sub-text">
            {t("Is the consumable quantity to be used")}
          </Typography>
        </Box>
        <Button className={`primary-btn ${classes.primaryButton}`}>
          {t("Update")}
        </Button>
      </Box>
    </Modal>
  );
};

export default EditConsumableModal;
// Customizable Area End
