import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { calender } from "./assets";
import { toast } from "react-toastify";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  viewBulkTabsValue: number;
  selectedDays: any;
  selectWeekdays: boolean;
  selectWeekends: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  weekdays: any;
  weekends: any;
  workingDaysRequestID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      viewBulkTabsValue: 0,
      selectedDays: [],
      selectWeekdays: false,
      selectWeekends: false,
    };
    this.weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    this.weekends = ["Saturday", "Sunday"];
    this.userSessionData =
      sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.workingDaysRequestID) {
        if (responseJson.data) {
          this.createToastNotificationSuccess(configJSON.successMsg, calender);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
  }

  translateText = (key: string, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  };
  createToastNotificationSuccess = (
    toastMesssagee: string,
    toastIconI: string
  ) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">
          {this.translateText(`${toastMesssagee}`)}
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIconI} alt="emptydata" />,
      }
    );
  };
  setScheduleTab = (obj: any, val: any) => {
    this.setState({
      viewBulkTabsValue: val,
    });
  };
  handleSelectWeekdays = () => {
    const { selectedDays, selectWeekdays } = this.state;

    if (selectWeekdays) {
      this.setState({
        selectWeekdays: false,
        selectedDays: selectedDays.filter(
          (day: any) => !this.weekdays.includes(day)
        ),
      });
    } else {
      const weekdaysToAdd = this.weekdays.filter(
        (day: any) => !selectedDays.includes(day)
      );
      this.setState({
        selectWeekdays: true,
        selectedDays: [...selectedDays, ...weekdaysToAdd],
      });
    }
  };

  handleSelectWeekends = () => {
    const { selectedDays, selectWeekends } = this.state;

    if (selectWeekends) {
      this.setState({
        selectWeekends: false,
        selectedDays: selectedDays.filter(
          (day: any) => !this.weekends.includes(day)
        ),
      });
    } else {
      const weekendsToAdd = this.weekends.filter(
        (day: any) => !selectedDays.includes(day)
      );
      this.setState({
        selectWeekends: true,
        selectedDays: [...selectedDays, ...weekendsToAdd],
      });
    }
  };

  handleDayCheckboxChange = (day: any) => {
    this.setState((prevState) => {
      const { selectedDays } = prevState;
      const isSelected = selectedDays.includes(day);
      const newSelectedDays = isSelected
        ? selectedDays.filter((d: any) => d !== day)
        : [...selectedDays, day];
      return {
        selectedDays: newSelectedDays,
        selectWeekdays: false,
        selectWeekends: false,
      };
    });
  };

  handleCancel = () => {
    this.setState({
      selectedDays: [],
      selectWeekdays: false,
      selectWeekends: false,
    });
  };

  navigateBacktoProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfiles"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  createWorkingDays = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };
    const weekdays = this.state.selectedDays.filter((day: any) =>
      this.weekdays.includes(day)
    );
    const weekends = this.state.selectedDays.filter((day: any) =>
      this.weekends.includes(day)
    );

    const body = {
      data: {
        weekdays,
        weekends,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.workingDaysRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.workingDaysApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
