import React from 'react';
import {
  Modal,
  Box,
  Typography,
  List,
  ListSubheader,
  FormControl,
  Select,
  MenuItem,
  Popover,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { extractItemDetails, getImageForSubCategory } from './ReusableFunctions';
import EditConsumableModal from './EditConsumableModal.web';
import { dotVertical, inventoryBackArrow, mapUrlSvg } from '../../blocks/TaskList/src/assets';
import { configJSON } from '../../blocks/TaskList/src/web/TaskListController.web';

interface InventoryAssetProps {
    classes: any;
    editTask: any;
    allInventoryAssestsModalClose:any;
    openProver: any;
    closeProver: () => void;
    t: (key: string) => string;
    isInventoryAssests:any;
    openConsumableAnchorElMore:any;
    staticID:any;
    openEditConsumable:any;
    openEditConsumableModal:any;
    backToInventory:any;
    closeEditConsumableModal:any;
    itemID:any,
  }
  
  const AllInventoryAssetsViewModal: React.FC<InventoryAssetProps> = ({
    classes,
    isInventoryAssests,
    allInventoryAssestsModalClose,
    openConsumableAnchorElMore,
    editTask,
    openProver,
    closeProver,
    t,
    staticID,
    openEditConsumable,
    openEditConsumableModal,
    backToInventory,
    closeEditConsumableModal,
    itemID
  }) => {
    const productCount = editTask?.attributes.inventory_or_assets.data.length || 0;

  return (
    <Modal
      hideBackdrop
      open={isInventoryAssests}
      onClose={allInventoryAssestsModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box
        className={`filter-dialogbox all-assignee-dialogbox ${classes.modalDialogInventoryAssetsBox}`}
      >
        <Box className="modal-dialoginner">
          <Box className="modal-heading">
            <Box className="filter-heading">
              <Box
                className="auth-back"
                onClick={allInventoryAssestsModalClose}
              >
                <img src={inventoryBackArrow} alt="Back" />
              </Box>
              <Typography className="title-wrapper" component="h2">
                {editTask?.attributes.priority !== 'normal' && (
                  <Box className="priority-status" component="span">
                    {editTask?.attributes.priority === 'critical' ? '!!' : '!!!'}
                  </Box>
                )}{' '}
                {editTask?.attributes.title}
              </Typography>
            </Box>
            <Box
              className="close-icon"
              onClick={allInventoryAssestsModalClose}
              data-test-id="activityLogClose"
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box className="modal-description">
            <Box className="heading-block">
              <Box className="primary-txt">{t(configJSON.inventoryHeadding)}</Box>
              <Box component="p" className="sub-txt">
                {t(configJSON.allInventorySubTxt)}
              </Box>
            </Box>
            <Box className="modal-form" component="form">
              <Box className="form-info-wrapper">
                <List className="tasklist-member">
                  <ListSubheader className="tasklist-total">
                    <Box className="total-member">
                      {productCount} {t(configJSON.inventoryHeadding)}
                    </Box>
                    <FormControl className="select-control sortby-control">
                      <Select
                        data-testid="sortTeams"
                        displayEmpty
                        defaultValue={t(configJSON.txtSortBy)}
                        renderValue={(value:any) => <Box>{value}</Box>}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom',
                          },
                          transformOrigin: {
                            horizontal: 'left',
                            vertical: 'top',
                          },
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value="A-Z">{t(configJSON.aTozTxt)}</MenuItem>
                        <MenuItem value="Z-A">{t(configJSON.zToaTxt)}</MenuItem>
                      </Select>
                    </FormControl>
                  </ListSubheader>
                  <ListSubheader>
                    {editTask?.attributes?.inventory_or_assets?.data.map((item:any) => {
                      const {
                        materialName,
                        colorName,
                        materialRawName,
                        area,
                        serialNo,
                        lotNo,
                        mapUrl,
                      } = extractItemDetails(item);
                      const categoryID = item.attributes.category.static_id;

                      return (
                        <React.Fragment key={item.id}>
                          <Box className="brand-title">
                            <Box className="brand-title-heading">
                              {categoryID === 3 ? (
                                <Box className="map-container">
                                  <Typography className="map-url-title">
                                    {mapUrl}
                                  </Typography>
                                  <img
                                    className="map-image"
                                    onClick={() =>
                                      window.open(mapUrl, '_blank', 'noopener,noreferrer')
                                    }
                                    src={mapUrlSvg}
                                    alt="Map"
                                  />
                                </Box>
                              ) : (
                                <Typography className="brand-title-st">Brand</Typography>
                              )}
                              {categoryID === 3 && (
                                <Typography className="brand-title-st content-dots">
                                  [{serialNo}]
                                </Typography>
                              )}
                              {categoryID === 4 && (
                                <Typography className="brand-title-st content-dots">
                                  [{lotNo}]
                                </Typography>
                              )}
                            </Box>
                            <Box className="image-container">
                              {getImageForSubCategory(
                                item.attributes.sub_category.id,
                                item.attributes
                              )}
                              <img
                                src={dotVertical}
                                onClick={(event:any) =>
                                  openProver(event, item.attributes.category.static_id, item.id)
                                }
                              />
                              <Popover
                                open={Boolean(openConsumableAnchorElMore)}
                                anchorEl={openConsumableAnchorElMore}
                                onClose={closeProver}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                classes={{ paper: classes.popoverStyle }}
                              >
                                <Box className="view-more-dropdown">
                                  {categoryID === 4 && staticID === 4 ? (
                                    <>
                                      <Box className="dropdown-menu-item">
                                        <Typography
                                          className="dropdown-menu-item-text"
                                          onClick={openEditConsumableModal}
                                        >
                                          {t(configJSON.editTxt)}
                                        </Typography>
                                      </Box>
                                      <Box className="dropdown-menu-item">
                                        <Typography
                                          className="dropdown-menu-item-text"
                                          onClick={backToInventory}
                                        >
                                          {t(configJSON.goToInventoryTxt)}
                                        </Typography>
                                      </Box>
                                    </>
                                  ) : (
                                    <Box className="dropdown-menu-item">
                                      <Typography
                                        className="dropdown-menu-item-text"
                                        onClick={backToInventory}
                                      >
                                        {t(configJSON.goToInventoryTxt)}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Popover>
                            </Box>
                          </Box>
                          <Box className="title-contatiner">
                            <Typography className="title">
                              {item.attributes.name}
                            </Typography>
                            {categoryID === 1 && (
                              <Typography className="color-title">{colorName}</Typography>
                            )}
                            {categoryID === 3 && (
                              <Typography className="color-title">{area}</Typography>
                            )}
                            {categoryID === 1 && (
                              <Typography className="color-title content-dots">
                                {materialRawName || materialName}
                              </Typography>
                            )}
                          </Box>
                          <Box className="line"></Box>
                        </React.Fragment>
                      );
                    })}
                    {openEditConsumable && (
                      <EditConsumableModal
                        modalOpen={openEditConsumable}
                        modalClose={closeEditConsumableModal}
                        classes={classes}
                        t={t}
                        data={editTask?.attributes?.inventory_or_assets?.data}
                        itemID={itemID}
                      />
                    )}
                  </ListSubheader>
                </List>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AllInventoryAssetsViewModal;
