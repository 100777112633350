import React from "react";
// Customizable Area Start
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Close, grayChevron, leftArrow, rightArrow } from "./assets";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Modal,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/SchedulingStyles.web";
import TimeOffCalenderController, {
  Props,
  configJSON,
} from "./TimeOffCalenderController.web";
const localizer = momentLocalizer(moment);
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputContent from "../../../components/src/CustomInput.web";
// Customizable Area End

export class TimeOffCalender extends TimeOffCalenderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  calenderEvent = (classes: any) => {
    return (
      <Modal
        open={this.state.isAddTimeOff}
        onClose={this.isAddTimeOffCloseEvent}
        aria-labelledby="sort-modal-title"
        aria-describedby="sort-modal-description"
      >
        <Box className={`view-modal-filter ${classes.modalFilter}`}>
          <Box className="filter-content">
            <Box className="filter-wrapper">
              <Box className="filter-heading">
                <Box>
                  <Typography className="calender-title">
                    {this.translateText(`${configJSON.selectDayTxt}`)}
                  </Typography>
                  <Typography className="calender-sub-title">
                    {this.translateText(`${configJSON.selectDaySubText}`)}
                  </Typography>
                </Box>
                <Box className="heading-center">
                  <Box onClick={this.isAddTimeOffCloseEvent}>
                    <img src={Close} alt="close" />
                  </Box>
                </Box>
              </Box>
              <Box className="date-picker">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.onDateChange}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  selectsRange
                  inline
                  locale="en-GB"
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                  }) => (
                    <Box className="calender-header">
                      <Box onClick={decreaseMonth}>
                        <img src={leftArrow} alt="left" />
                      </Box>
                      <Typography className="txt">
                        {date.toLocaleString("default", {
                          month: "long",
                          year: "numeric",
                        })}
                      </Typography>
                      <Box onClick={increaseMonth}>
                        <img src={rightArrow} alt="left" />
                      </Box>
                    </Box>
                  )}
                />
              </Box>

              <CustomInputContent
                placeholder="Description"
                rows={3}
                isMultiline
                fullWidth
                value={this.state.description}
                onChange={this.handleDescriptionChange}
                data-test-id="handleDescriptionChange"
              />
              <Box className="button-wrapper">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  onClick={this.isAddTimeOffCloseEvent}
                  data-test-id="isAddTimeOffCloseEvent"
                >
                  {this.translateText(`${configJSON.cancel}`)}
                </Button>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  onClick={this.createTimeOffs}
                  data-test-id="createTimeOffs"
                >
                  {this.translateText(`${configJSON.doneTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { events } = this.state;
    const today = new Date();

    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        className={`${classes.schedulingCalenderWrapper} ${classes.calendarContainer}`}
      >
        <Box className="border-content">
          <Box className="time-offtext-content">
            <Typography className="title">
              {this.translateText(`${configJSON.timeOffTxt}`)}
            </Typography>
            <Typography className="sub-title">
              {this.translateText(`${configJSON.timeOffSubTxt}`)}
            </Typography>
          </Box>
          <Box className="heading-time-off">
            <Typography className="days-title">25 Days Off</Typography>
            <FormControl className="select-control">
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                // onChange={(e: any) => this.handleSort(e.target.value)}
                data-test-id="sort-textfield"
                displayEmpty
                defaultValue={this.translateText(`${configJSON.sortByTxt}`)}
                renderValue={(value: any) => {
                  return <Box>{value}</Box>;
                }}
              >
                <MenuItem value="0">A-Z</MenuItem>
                <MenuItem value="1">Z-A</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="heading-time-off2">
            <Typography className="days-sub-title">January</Typography>
            <Typography className="days-sub-title">10 Days off</Typography>
          </Box>
          <Box>
            {events.map((event: any) => (
              <Box className="dates-container" key={event.title}>
                <Box className="heading-time-off3">
                  <Typography className="days-sub-title">
                    {event.title}
                  </Typography>
                  <img src={grayChevron} alt="grayChevron"></img>
                </Box>
                <Box className="dates">
                  <Typography className="txt">
                    {moment(event.start).format("MMM DD, YYYY")}
                  </Typography>{" "}
                  <Typography className="txt">
                    &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography className="txt">
                    {moment(event.end).format("MMM DD, YYYY")}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box className="calender">
          <Calendar
            localizer={localizer}
            events={this.state.allTimeOff}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100%" }}
            popup
            defaultDate={today}
            views={["month"]}
            components={{
              toolbar: (props) => (
                <Box className="topbar-container">
                  <Box className="today-content">
                    <Box className="today-container">
                      <Button
                        onClick={() => props.onNavigate("TODAY")}
                        className={`secondary-btn ${classes.secondaryButton}`}
                      >
                        {this.translateText(`${configJSON.todayTxt}`)}
                      </Button>
                      <Typography
                        className="prev-btn"
                        onClick={() => props.onNavigate("PREV")}
                      >
                        <img src={leftArrow} alt="left" />
                      </Typography>
                    </Box>
                    <Typography
                      className="prev-btn"
                      onClick={() => props.onNavigate("NEXT")}
                    >
                      <img src={rightArrow} alt="left" />
                    </Typography>
                    <Typography className="month-txt">
                      {moment(props.date).format("MMMM YYYY")}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={this.isAddTimeOffEvent}
                      data-test-id="isAddTimeOffEvent"
                      className={`primary-btn ${classes.primaryButton}`}
                    >
                      + {this.translateText(`${configJSON.addTimeOff}`)}
                    </Button>
                  </Box>
                </Box>
              ),
              event: (event: any) => (
                <Box title={event.description}>
                  <Typography className="event-title">{event.title}</Typography>
                </Box>
              ),
            }}
            dayPropGetter={(date: any) => {
              if (moment(date).isSame(today, "day")) {
                return {
                  style: {
                    // backgroundImage: `url(${Oval})`,
                    // backgroundRepeat: "no-repeat",
                  },
                };
              }
              if (moment(date).day() === 6 || moment(date).day() === 0) {
                return {
                  className: classes.weekendDate,
                };
              }
              return {};
            }}
            formats={{
              weekdayFormat: (date, culture, localizer: any) =>
                localizer.format(date, "ddd", culture).toUpperCase(),
            }}
          />
        </Box>
        {this.calenderEvent(classes)}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(TimeOffCalender);
// Customizable Area End
