// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  List,
  ListItem,
  Popover,
  Typography,
  Card,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import GlobalHeaderWebController, {
  Props,
  configJSON,
} from "./GlobalHeaderWebController";
import { bellBadge, menuIcon, chatBubble, cartIcon } from "./assets";
import { Link, NavLink } from "react-router-dom";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { imgLogo } from "../../../components/src/assets";
import ProfileMenu from "../../../components/src/ProfileMenu.web";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckOutlined from "@material-ui/icons/CheckOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import moment from "moment";
import { withHistory } from "../../../components/src/withHistory.web";

import InfiniteScroll from "react-infinite-scroll-component";
import { ShoppingCartOrders } from "../../shoppingcart/src/ShoppingCartOrders.web";
import QuickChat from "../../LiveChat2/src/QuickChat.web";
import RoleSelectionDropdown from "../../../components/src/Role-selection-dropdown";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    HeaderWrapper: {
      padding: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "60px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "60px 24px 24px",
      },
      "&.profile-header": {
        padding: "20px 32px",
        flexDirection: "unset",
        background: "#ffffff",
        boxShadow: "0px 1px 0px 0px #E8ECF2",
        position: "fixed",
        // left: "0",
        // right: "0",
        // top: "0",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 24px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
        "& .header-left": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          "& .auth-logo": {
            maxWidth: "112px",
            marginRight: "120px",
            [theme.breakpoints.down("sm")]: {
              marginRight: "50px",
            },
          },
        },
        "& .header-nav": {
          "& .nav-list": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "0",
            "& .MuiListItem-root": {
              width: "auto",
              padding: "0",
              marginRight: "36px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "32px",
              },
              "& .nav-link": {
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#2B2B2B",
                opacity: "0.37",
                textDecoration: "none",
                fontFamily: "Expletus Sans",
                letterSpacing: "0.1px",
                "&.active": {
                  opacity: "1",
                },
              },
            },
          },
        },
        "& .header-right": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginLeft: "15px",
          "& .link-btn": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19px",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "& .MuiButton-root": {
            minHeight: "40px",
            padding: "10px 9px",
          },
          "& .right-icon": {
            marginLeft: "24px",
            [theme.breakpoints.down("sm")]: {
              marginLeft: "20px",
            },
            "& .icon-btn": {
              padding: "0",
            },
          },
          "& .profile-menu": {
            display: "flex",
            padding: "7px 13px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
            border: "1px solid #E8ECF2",
            "& .profile-icon": {
              marginLeft: "12px",
              width: "26px",
              height: "26px",
              fontSize: "12px",
              lineHeight: "15px",
              background: "#E8ECF2",
            },
          },
          "& .profile-icon": {
            width: "36px",
            height: "36px",
            background: "#ECF1F4",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2B2B2B",
            borderRadius: "100%",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
          },
        },
      },
      "& .auth-logo": {
        maxWidth: "162px",
        width: "100%",
        "& img": {
          maxWidth: "100%",
        },
      },
      "& .auth-back": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& i": {
          marginRight: "10px",
        },
      },
      "& .auth-right": {
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "10px",
        },
        "& .MuiButton-root": {
          marginRight: "15px",
          transition: "none",
          [theme.breakpoints.down("xs")]: {
            marginRight: "10px",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 10px",
        minHeight: "46px",
        minWidth: "unset",
      },
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      width: "auto",
      minWidth: "94px",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    dropMenu: {
      "& .dropmenu-btn": {
        letterSpacing: "0",
        background: "#4ba3b7",
        border: "1px solid #4ba3b7",
        borderRadius: "7px",
        filter: "none",
        boxShadow: "none",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#ffffff",
        fontWeight: 500,
        fontFamily: "Expletus Sans",
        textAlign: "center",
        padding: "12px 10px",
        minHeight: "48px",
        marginBottom: "0",
        transition: "all 0.5s ease-in-out",
        textTransform: "capitalize",
        width: "auto",
        minWidth: "156px",
        [theme.breakpoints.down("xs")]: {
          padding: "8px 6px",
          minHeight: "46px",
          minWidth: "unset",
        },
      },
    },
    dropDropdown: {
      "& .MuiMenu-paper": {
        minWidth: "220px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "31px",
        boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2B2B2B",
          padding: "16px 24px",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          display: "flex",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          paddingBottom: "8px",
        },
      },
    },
    notificationBox: {
      "&.display-center": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      "& .MuiButton-label": {
        textTransform: "initial",
        color: "#4BA3B7",
        fontFamily: "Expletus Sans",
      },
      "& .MuiTypography-body1": {
        fontSize: "14px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.105px",
      },
      "&.btn-mark-as-read": {
        textTransform: "initial",
        color: "#4BA3B7",
        fontSize: "14px",
      },
      "&.title": {
        color: "#000000",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "400",
        fontFamily: "Expletus Sans",
      },
      "&.sub-title": {
        fontSize: "12px",
        marginTop: "6px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
      },
      "&.list": {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
      "&.list-item": {
        padding: "10px 24px 10px 0",
        minHeight: "80px",
      },
      "&. MuiTypography-displayBlock": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "0.0075em",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "0px",
      },
      "&.notify-item-title": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "0.0075em",
        marginRight: "24px",
      },
      "&.notify-item-content": {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
        color: "#94A3B8",
        marginTop: "6px",
      },
      "&.image-box": {
        height: "48px",
        width: "48px",
        minWidth: "48px",
        backgroundColor: "#F2F8FC",
        borderRadius: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "24px",
        marginLeft: "24px",
      },
      "&.image-tasks-list": {
        width: "6px",
        height: "6px",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "&.bg-white": {
        backgroundColor: "#FFFFFF",
      },
      "&.bg-gray": {
        backgroundColor: "#ECF1F4",
        cursor: "pointer",
      },
      "&.divider": {
        backgroundColor: "#000000",
        borderWidth: "1px",
        with: "100%",
      },
      "&.fiber-icon": {
        fontSize: "10px",
        color: "#94A3B8",
        marginLeft: "8px",
        marginRight: "8px",
        marginTop: "6px",
      },
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "orange",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "red",
        borderRadius: 2,
      },
    },
    modalBoxCenter: {
      display: "flex",
      // alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    mergeInfoGroupsModal: {
      // height: "100vh",
      width: "550px",
      position: "relative",
      display: "inline-block",
      overflowY: "auto",
      backgroundColor: "#ffffff",
      // padding: "0px 30px",
      "& .default-cart": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .default-cart-img": {
          marginBottom: "20px",
          width: "320px",
        },
      },
      "& .primary-btn-2": {
        fontSize: "14px",
        width: "75%",
        marginTop: "48px",
        borderRadius: "10px",
        marginBottom: "48px",
      },
      "& .cart-empty-text": {
        fontFamily: "Expletus Sans",
        color: " #2B2B2B",
        fontSize: "26px",
        fontStyle: "normal",
        marginBottom: "8px",
        fontWeight: "500",
        textAlign: "center",
        lineHeight: "26px",
      },
      "& .header-container": {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 30px",
        "& .modal-title-box": {
          margin: "45px 0px",
          "& .title-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26px",
            marginBottom: "8px",
          },
          "& .sub-title-txt": {
            color: "#505050",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "16px",
            letterSpacing: "0.09px",
          },
        },
        "& .heading-bar": {
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          // margin: "20px 20px 0px 0px",
          justifyContent: "end",
          cursor: "pointer",
          "& .close-icon": {
            height: "24px",
            width: "24px",
            color: "#959595",
            cursor: "pointer",
            marginTop: "-30px",
          },
        },
      },
      "& .headder": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 30px",
        "& .checkboxes": {
          "& .MuiFormControlLabel-root": {
            marginRight: "0",
            marginLeft: "-7px",
            "& .MuiFormControlLabel-label": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
            },
          },
          "& .MuiCheckbox-root": {
            color: "#94A3B8",
            padding: "7px",
            "&:hover": {
              backgroundColor: "rgba(54, 145, 166, 0.04)",
            },
            "&.Mui-checked": {
              color: "#4ba3b7",
            },
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& .remove-txt": {
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          marginRight: "24px",
          cursor: "pointer",
          whiteSpace: "nowrap",
          textTransform: "capitalize",
        },
      },
      "& .card-container-flex": {
        // display:"flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 40px",
        "& .card-container": {
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          "& .cart-img": {
            width: "78px",
            height: "78px",
          },
          "& .checkboxes": {
            marginRight: "10px",
            "& .MuiFormControlLabel-root": {
              marginRight: "0",
              marginLeft: "-7px",
              "& .MuiFormControlLabel-label": {
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
              },
            },
            "& .MuiCheckbox-root": {
              color: "#94A3B8",
              padding: "7px",
              "&:hover": {
                backgroundColor: "rgba(54, 145, 166, 0.04)",
              },
              "&.Mui-checked": {
                color: "#4ba3b7",
              },
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
              },
            },
          },
          "& .card-content": {
            marginLeft: "16px",
            "& .cart-heading": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            },
            "& .cart-sub-heading": {
              width: "180px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow:"ellipsis",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              marginTop: "8px",
            },
            "& .price-container": {
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              "& .cart-price-dash-text": {
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "10px",
                textDecoration: "line-through",
              },
              "& .cart-price-text": {
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                marginTop: "10px",
                marginLeft: "10px",
                textTransform: "capitalize",
              },
            },
            "& .cart-price": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              marginTop: "10px",
            },
          },
        },
        "& .card-quantity-container": {
          display: "flex",
          position: "absolute",
          right: "50px",
          alignItems: "center",
          marginTop: "0",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            },
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
                fill: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-input": {
              width: "32px",
              height: "8px",
              fontSize: "12px",
              borderRadius: "20px",
              fontFamily: "Roboto",
              color: "#333333",
              fontWeight: "600",
            },
          },
          "& .secondary-btn": {
            height: "32px",
            width: "32px",
            fontSize: "24px",
            lineHeight: "19px",
            borderRadius: "20px",
            marginLeft: "10px",
          },
          "& .cart-quantity": {
            margin: "12px",
          },
        },
      },
      "& .line": {
        borderBottom: "1px solid #E8ECF2",
        marginTop: "20px",
      },
      "& .cart-subtotal-text": {
        color: "#000000DE",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        marginTop: "16px",
        textAlign: "end",
        paddingBottom: "200px",
        padding: "0px 30px",
      },
      "& .cart-end-section": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 30px",
        marginBottom: "16px",
        "& .cart-end-text": {
          color: "#000000DE",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        },
        "& .cart-end-price-text": {
          color: "#000000DE",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
        },
      },
      "& .cart-end-section-hide": {
        display: "none",
      },
      "& .note-section": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#F6F8DE",
        borderRadius: "12px",
        padding: "12px",
        margin: "30px",
        "& .note-section-txt": {
          color: "#000000DE",
          fontFamily: "Roboto",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
        },
        "& .note-section-des-txt": {
          color: "#000000DE",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
        },
      },
      "& .button-section": {
        background: "#F2F8FC",
        // height: "80px",
        padding: "12px 30px",
        width:"100%",
        display:" inline-block", 
        bottom: 0,
        zIndex: 1000,
        position: "sticky",   
        "& .button-section-total-txt": {
          color: "#000000DE",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: "400",
          textTransform: "uppercase",
          marginBottom: "16px",
        },
        "& .button-section-price-txt": {
          color: "#000000DE",
          fontFamily: "Expletus Sans",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "400",
          marginBottom: "16px",
          "& .span": {
            color: "#000000DE",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "400",
            marginBottom: "16px",
          },
        },
        "& .primary-btn": {
          position: "absolute",
          top: "0px",
          right: "0px",
          height: "100%",
          borderRadius: 0,
        },
      },
    },
    secondaryButtonn: {
      border: "1px solid #E8ECF2",
      background: "transparent",
      fontSize: "14px",
      borderRadius: "7px",
      color: "#4BA3B7",
      lineHeight: "18px",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "Expletus Sans",
      maxWidth: "100%",
      textTransform: "unset",
      // minWidth: "198px",
      padding: "12px 10px",
      marginBottom: "0",
      minHeight: "48px",
      textDecoration: "none",
      cursor: "pointer",
      // transition: "all 0.5s ease-in-out",
      "&:hover": {
        color: "#4BA3B7",
        background: "transparent",
      },
    },
  });

export class GlobalHeaderWeb extends GlobalHeaderWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { notifications } = this.state;
    const fetchMoreData = this.fetchMoreData;

    return (
      <>
        <AppBar
          position="static"
          className={`profile-header ${classes.HeaderWrapper}`}
        >
          <Box className="header-left">
            <Box className="auth-logo">
              <img src={imgLogo} alt="logo" />
            </Box>
            <Box component="nav" className="header-nav">
              <List className="nav-list">
                <ListItem>
                  <RoleSelectionDropdown navigation={this.props.navigation}/>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    // to="/dashboard"
                    to={JSON.parse(localStorage.getItem("selectedRole") || "{}")?.link || "/dashboard"}
                  >
                    {this.t(`${configJSON.dashboard}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink className="nav-link" to="/inventorymanagement">
                    {this.t(`${configJSON.inventory}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/direct-emails"
                    isActive={(match, location) => {
                      return [
                        "/email-campaigns",
                        "/direct-emails",
                        "/contacts",
                        "/groups",
                      ].includes(location.pathname);
                    }}
                  >
                    {this.t(`${configJSON.marketing}`)}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink className="nav-link" to="/etoh-shop">
                    {configJSON.etohShop}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/project"
                  >
                    {this.t(`${configJSON.taskList}`)}
                  </NavLink>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box className="header-right">
            <Link to="#" className={`link-btn`} title="+ Create New">
              + {this.t(`${configJSON.createNew}`)}
            </Link>
            <Box className="right-icon" onClick={this.handleOpenCartEvent}>
              <IconButton
                className="icon-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                disableRipple
              >
                <img src={cartIcon} alt="cartIcon" />
              </IconButton>
            </Box>
            <Box className="right-icon">
              <IconButton
                className="icon-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => this.handleQuictChatPopoverOpen(e)}                
                disableRipple
              >
                <img src={chatBubble} alt="chatBubble" />
              </IconButton>
              <QuickChat navigation={undefined} liveChatId={""} 
              handleQuickChatPopoverClose={this.handleQuickChatPopoverClose}
              classes={this.props.classes} anchorQuickChatEl={this.state.anchorQuickChatEl} />
            </Box>
            <Box className="right-icon">
              <IconButton
                className="icon-btn"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => this.handlePopoverOpen(e)}
                disableRipple
                data-test-id="notification-icon"
              >
                <img src={bellBadge} alt="bellBadge" />
              </IconButton>
              <Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ top: "9px" }}
              >
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "24px",
                      paddingTop: "24px",
                      paddingRight: "24px",
                      paddingBottom: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        className={`${classes.notificationBox} title`}
                      >
                        {this.t(`${configJSON.notificationsTxt}`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={`${classes.notificationBox} sub-title`}
                      >
                        {this.t(`${configJSON.notificationsSubTxt}`)}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        variant="text"
                        className={`${classes.notificationBox} btn-mark-as-read`}
                        onClick={this.markReadAllNotifications}
                        data-test-id="mark-read-all-notifications"
                      >
                        {this.t(`${configJSON.markAllAsRead}`)}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    id="parentBox"
                    style={{ maxHeight: 384, width: 432, overflow: "auto" }}
                  >
                    {notifications && notifications.length > 0 && (
                      <InfiniteScroll
                        dataLength={notifications.length}
                        //next={this.fetchMoreData ? this.fetchMoreData : null}
                        next={fetchMoreData}
                        hasMore={
                          this.state.hasNextPage
                            ? this.state.hasNextPage
                            : false
                        }
                        loader={
                          <Box className="loader" key="loader">
                            <Typography>
                              {this.t(`${configJSON.loading}`)}
                            </Typography>
                          </Box>
                        }
                        className={`${classes.notificationBox} list`}
                        scrollableTarget="parentBox"
                      >
                        {notifications.map((notification: any) => {
                          const isRead: boolean =
                            notification && notification.attributes.is_read;
                          return (
                            <Card
                              className={
                                isRead
                                  ? `${classes.notificationBox} list-item bg-white display-center`
                                  : `${classes.notificationBox} list-item bg-gray display-center`
                              }
                              onClick={() =>
                                this.readNotification(notification.id)
                              }
                              key={`${notification.id}`}
                              data-test-id="unreadnotifications"
                            >
                              <Box
                                className={`${classes.notificationBox} image-box`}
                              >
                                {notification.attributes.is_read ? (
                                  <CheckOutlined />
                                ) : (
                                  <ChatBubbleOutlineIcon />
                                )}
                              </Box>
                              <Box>
                                <Typography>
                                  {notification.attributes.content}
                                </Typography>
                                <Box
                                  className={`${classes.notificationBox} display-center`}
                                >
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className={`${classes.notificationBox} notify-item-content`}
                                  >
                                    {moment(
                                      notification.attributes.created_at
                                    ).format("MMMM Do YYYY, h:mm:ss a")}
                                  </Typography>
                                  <FiberManualRecordIcon
                                    className={`${classes.notificationBox} fiber-icon`}
                                  />
                                  <Typography
                                    variant="body2"
                                    className={`${classes.notificationBox} notify-item-content`}
                                  >
                                    {notification.attributes.notifiable_type}
                                  </Typography>
                                </Box>
                              </Box>
                            </Card>
                          );
                        })}
                      </InfiniteScroll>
                    )}
                  </Box>
                </Card>
              </Popover>
            </Box>
            <Box
              className="right-icon profile-menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              data-test-id="header-click"
              onClick={(e) => this.headerMenuOpen(e)}
            >
              <IconButton
                className="icon-btn"
                name="el1"
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-test-id="header-click"
                disableRipple
              >
                <img src={menuIcon} alt="menuIcon" />
                <Box className="profile-icon">{this.initials}</Box>
              </IconButton>
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={this.state.headerMenuEl2}
              open={Boolean(this.state.headerMenuEl2)}
              onClose={() => this.headerMenuClose()}
              data-test-id="closeMenu"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              getContentAnchorEl={null}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className={`${classes.dropDropdown} drop-dropdown`}
            >
              <MenuItem
                data-test-id="navigateToProfile"
                onClick={this.navigateToProfile}
              >
                {this.t(`${configJSON.profile}`)}
              </MenuItem>
              <MenuItem
                data-test-id="navigateToSettings"
                onClick={this.navigateToSettings}
              >
                {this.t(`${configJSON.settings}`)}
              </MenuItem>
              <MenuItem
                data-test-id="navigateToHelpCenter"
                onClick={this.navigateToHelpCenter}
              >
                {this.t(`${configJSON.helpCenter}`)}
              </MenuItem>
              <MenuItem
                data-test-id="handleLogoutUser"
                onClick={this.handleLogoutUser}
              >
                {this.t(`${configJSON.logout}`)}
              </MenuItem>
            </Menu>
          </Box>
        </AppBar>
        <ShoppingCartOrders
          navigation={undefined}
          id={""}
          classes={this.props.classes}
          isCartOpen={this.state.isCartOpen}
          handleCloseCartEvent={this.handleCloseCartEvent}
        />
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(withHistory(GlobalHeaderWeb))
);
// Customizable Area End
