//Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    Grid
} from "@material-ui/core";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./../EquizStyles.web";

import { Chart } from "react-charts";
export const configJSON = require("./../config");

import {
    sortVariant,
    framePng
} from "./../assets";

import GlobalHeaderWeb from "../../../customisableuserprofiles/src/GlobalHeader.web";

// import { customStyles } from "../EquizStyles.web";
const OwnerDashboard = (props: any) => {

    const { classes } = props;
    const pageURL = window.location.pathname;

    let headerData = [{
        "key": "Total Sales",
        "count": 5000,
        "status": "96 Deals"
    },
    {
        "key": "New Contacts",
        "count": 240,
        "status": "300 Outreaches"
    },
    ];


    let footerData = [
        {
            key: "Initiated",
            count: 30,
            status: "500 € potential earnings"
        },
        {
            key: "Proposals Sent",
            count: 75,
            status: "500 € potential earnings"
        },
        {
            key: "Follow-ups",
            count: 200,
            status: "500 € potential earnings"
        },
        {
            key: "Won",
            count: 15,
            status: "50k € earned"
        },
        {
            key: "Lost",
            count: 65,
            status: "500 € potential earnings"
        }
    ];


    const data = [
        {
            "label": "Series 1",
            "data": [
                {
                    "primary": "2024-10-02T00:00:00.000Z",
                    "secondary": 72
                },
                {
                    "primary": "2024-10-03T00:00:00.000Z",
                    "secondary": 49
                },
                {
                    "primary": "2024-10-04T00:00:00.000Z",
                    "secondary": 70
                },
                {
                    "primary": "2024-10-05T00:00:00.000Z",
                    "secondary": 58
                },
                {
                    "primary": "2024-10-06T00:00:00.000Z",
                    "secondary": 13
                },
                {
                    "primary": "2024-10-07T00:00:00.000Z",
                    "secondary": 86
                },
                {
                    "primary": "2024-10-08T00:00:00.000Z",
                    "secondary": 76
                },
                {
                    "primary": "2024-10-09T00:00:00.000Z",
                    "secondary": 53
                },
                {
                    "primary": "2024-10-10T00:00:00.000Z",
                    "secondary": 49
                },
                {
                    "primary": "2024-10-11T00:00:00.000Z",
                    "secondary": 72
                }
            ]
        },

    ]

    const primaryAxis = {
        getValue: (datum: any) => new Date(datum.primary), // Convert string to Date
    };

    const secondaryAxes = [
        {
            getValue: (datum: any) => datum.secondary, // Use 'secondary' directly
        },
    ];



    const statisticList = (data: any, type: any) => {
        return (
            <>
                {data.map((item: any) => (
                    <Card className={type === 'header' ? "google-card" : "google-card2"} key={item.key}>
                        <CardContent className="content-card">
                            <Box className="right-content">
                                <Box className="quiz-title">
                                    {item.key}
                                </Box>
                                <Box className="quiz-title2">
                                    {item.count}
                                </Box>
                                <Box className="quiz-date">
                                    {item.status}
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </>
        );
    };


    return (
        <Box>
            {/* {this.state.isLoading && <WebLoader />} */}
            <Box
                className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
            >
                <GlobalHeaderWeb pageLink={pageURL} />
                <Box style={{marginTop: "80px"}} className={classes.innerWrapper}>

                    <Box className={classes.teamModuleWrapper}>
                        <Grid container spacing={2}>
                            <Grid item sm={9} xs={9}>
                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            Statistics
                                        </Typography>
                                    </Box>
                                    <Box className="heading-right">
                                        <Box className="filter-list">
                                            <FormControl className="select-control sortby-control">
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: { paper: classes.dropdownClass },
                                                    }}
                                                    // onChange={this.handleSubmittedQuizesSort}
                                                    displayEmpty
                                                    defaultValue={"Last 7 Days"}
                                                    inputProps={{ IconComponent: () => null }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Box>
                                                                <img
                                                                    className="select-icon"
                                                                    src={sortVariant}
                                                                    alt="sortVariant"
                                                                />
                                                                {value}
                                                            </Box>
                                                        );
                                                    }}
                                                    data-test-id="sbmt-quiz-dropdown"
                                                >
                                                    <MenuItem value="Sort By">{"Sort by"}</MenuItem>
                                                    <MenuItem value="A-Z">{"A-Z"}</MenuItem>
                                                    <MenuItem value="Z-A">{"Z-A"}</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <Box sx={{ minWidth: 275 }} className="sales-card-row">
                                            {statisticList(headerData, 'header')}
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            Deals
                                        </Typography>
                                    </Box>
                                    <Box className="heading-right">
                                        <Box className="filter-with-label">
                                            <Box className="search-wrapper">
                                                <FormControl className="select-control teams-sort">
                                                    <Select
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null,
                                                            classes: { paper: classes.dropdownClass }
                                                        }}
                                                        displayEmpty
                                                        defaultValue=""
                                                        // onChange={this.changeSortByTeams}
                                                        data-test-id="sortByTeams"
                                                    >
                                                        <MenuItem value="">{'Last year'}</MenuItem>
                                                        <MenuItem value={1}>A to Z</MenuItem>
                                                        <MenuItem value={2}>Z to A</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Typography style={{ fontWeight: 500, fontSize: "14px" }} variant="h3">
                                                See All
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ width: "100%", height: "100%" }}>
                                        <Box className="google-card-row">
                                            <Box sx={{ width: "1500px", height: "400px" }}>
                                                <Chart
                                                    options={{
                                                        data,
                                                        primaryAxis,
                                                        secondaryAxes,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Box sx={{ minWidth: 275 }} className="sales-card-row">
                                            {statisticList(footerData, 'footer')}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

// export default OwnerDashboard;

export default withStyles(customStyles, { withTheme: true })(OwnerDashboard);
