// Customizable Area Start
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles,
  createData,
  createIndividualData,
  formatDate,TeamMemberHead } from "../../blocks/email-account-registration/src/teams-web/CustomTable.web";
import TableItems from "../../blocks/email-account-registration/src/teams-web/CustomTableItems.web";
import {
  deleteOutline,
  removeMemberOutline,
  addToTeamOutline,
  moveMemberOutline,
} from "../../blocks/email-account-registration/src/assets";



export const configJSON = require("../../blocks/email-account-registration/src/config");

export default function MembersListTable(props: any) {
  const {
    renderMembers,
    individualTeam,
    rederIndividualMembers,
    editMember,
    reactivateMember,
    deactivateMember,
    addMembertoTeam,
    addMultipleMemberTeam,
    moveMembertoTeam,
    removeMemberfromTeam,
    hideActionColumn,
    showDeleteInMembers,
    deleteMember,
    getSelectedRecords,
    multipleDelete,
    multipleSelected,
    hideDeactivate,
    showEditOption,
    showDeactivateOption,
    userRole,
  } = props;

  let rows: any;
  if (individualTeam) {
    rows = rederIndividualMembers.map((member: any) => {
      return createIndividualData(
        member.id,
        member.attributes.original_owner,
        member.attributes.first_name,
        member.attributes.last_name,
        member.attributes.email,
        member.attributes.job_role,
        member.attributes.job_type,
        member.attributes.hourly_pay,
        member.attributes.created_at,
        member.attributes.team,
        member.attributes.country,
        member.attributes.state,
        member.attributes.postal_code,
        member.attributes.city,
        member.attributes.company_place_address,
        member.attributes.company_name
      );
    });
  } else {
    rows = renderMembers.map((member: any) => {
      return createData(
        member.id,
        member.attributes.original_owner,
        member.attributes.first_name,
        member.attributes.last_name,
        member.attributes.email,
        member.attributes.team,
        member.attributes.job_role,
        member.attributes.job_type,
        member.attributes.created_at,
        member.attributes.hourly_pay,
        member.attributes.deactivated,
        member.attributes.country,
        member.attributes.state,
        member.attributes.postal_code,
        member.attributes.city,
        member.attributes.company_place_address,
        member.attributes.company_name
      );
    });
  }
  const classes = useStyles();
  const [selected, setSelected] = React.useState<any[]>([]);
  const [teamSelected, setTeamSelected] = React.useState<any>({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(configJSON.RowsPerPage);

  const memActions = {
    editAction: editMember,
    deActivateAction: deactivateMember,
    reActivateAction: reactivateMember,
    addMemberAction: addMembertoTeam,
    moveMemberAction: moveMembertoTeam,
    removeMemberAction: removeMemberfromTeam,
    hideActionColumn: hideActionColumn,
    showDeleteInMembers: showDeleteInMembers,
    deleteAction: deleteMember,
    showEditOption: showEditOption,
    showDeactivateOption: showDeactivateOption,
  };

  useEffect(() => {
    if (
      getSelectedRecords !== null &&
      typeof getSelectedRecords === "function"
    ) {
      getSelectedRecords(selected);
    }
    if (multipleSelected) {
      multipleSelected(selected);
    }
  }, [selected]);
  if (!individualTeam) {
    useEffect(() => {
      let newSelected: any[] = [];
      if (renderMembers.length > 0) {
        newSelected = selected.filter((x: any) =>
          renderMembers.find((y: any) => y.id == x)
        );
        setSelected(newSelected);
      } else {
        setSelected([]);
      }
    }, [renderMembers]);
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => (n.memId ? n.memId : n.name));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any, data:any) => {
    const selectedIndex = selected.find((x: any) => x == name);

    let newSelected: any[] = [];
    let team={...teamSelected}

    if (selectedIndex == null) {
      newSelected = [...selected, name];
      team = {...team,[name]: data.team}
    } else {
      newSelected = [...selected.filter((x: any) => x != name)];
      delete team[name]
    }
    setTeamSelected(team)
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface ActionIconProps {
    src: string;
    alt: string;
    onClick?: () => void;
  }

  const ActionIcon = ({ src, alt, onClick }: ActionIconProps) => {
    return (
      <div className="delete-icon" onClick={onClick}>
        <img src={src} alt={alt} />
      </div>
    );
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const tableClass = userRole === "manager" ? "mem-list-manager" : userRole === "employee" ? "mem-list-employee" : "";
  const selectionMessage = selected.length
  ? `${selected.length} Member${selected.length > 1 ? "s" : ""} Selected`
  : `${rows.length} Member${rows.length > 1 ? "s" : ""}`;

  const selectedRow = selected.length === 1 ? rows.find((row: any) => row.memId === selected[0]) : null;

  return (
    <div className={classes.root}>
      <Box>
        
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
            <Box className="member-count">
            {userRole !== 'employee' && 
            <>
              {!hideActionColumn && (
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  data-test-id="handle-select-all"
                />
              )}
              </>
              }
              {selectionMessage}
              {userRole !== 'employee' && <>
               {selected.length > 0 && (
                <>
                  {individualTeam && (
                    <>
                      {selectedRow !== null ?  <ActionIcon
                        src={removeMemberOutline}
                        alt="Remove Member"
                        onClick={() => removeMemberfromTeam(selectedRow.name, selectedRow.lastname, selectedRow.memId)}
                      /> :  <ActionIcon
                      src={removeMemberOutline}
                      alt="Remove Member"
                      onClick={() => removeMemberfromTeam()}
                    /> }
                      
                      <ActionIcon
                        src={addToTeamOutline}
                        alt="Add to Team"
                        onClick={addMembertoTeam}
                      />
                      <ActionIcon
                        src={moveMemberOutline}
                        alt="Move Member"
                        onClick={()=>moveMembertoTeam(selected,Object.values(teamSelected).flat().map((data:any)=>data.title))}
                      />
                    </>
                  )}
                  {(!individualTeam && !hideDeactivate) && (
                    <ActionIcon
                      src={addToTeamOutline}
                      alt="Add to Team"
                      onClick={addMultipleMemberTeam}
                    />
                  )}
                </>
              )}
              </> }
             
              {hideDeactivate && selected.length > 0 && (
                <Box
                  className="delete-icon"
                  onClick={() => multipleDelete(selected)}
                  data-testid="delete-icon"
                >
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationTable}
          />
        </Box>
        <TableContainer>
          <Table
            className={`${
              individualTeam
                ? "view-teammember"
                : `view-memberList ${tableClass}`
            } ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TeamMemberHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              tableType="members"
              membersType={individualTeam}
              hideActionColumn={hideActionColumn}
              userRole={userRole}
              moduleType={"teams"}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(
                    row.memId ? row.memId : row.name
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const deActivateClass = `${row.deactivated &&
                    !hideDeactivate &&
                    "disable-member-row"}`;
                  const memFields = {
                    memId: row.memId,
                    isOriginalOwner: row.isOriginalOwner,
                    firstName: row.name,
                    email: row.email,
                    team: row.team,
                    lastName: row.lastname,
                    jobType: row.job_type,
                    jobRole: row.job_role,
                    hourly_pay: row.hourly_pay,
                    isDeactivated: row.deactivated,
                    joinDate: formatDate(row.joinDate),
                    businessInfo: {
                      country: row.country,
                      state: row.state,
                      postal_code: row.postal_code,
                      city: row.city,
                      company_place_address: row.company_place_address,
                      company_name: row.company_name,
                    },
                  };

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.memId}
                      selected={isItemSelected}
                      className={deActivateClass}
                      data-test-id="unAssignedListcheckbox"
                    >
                      <TableItems
                        classes={classes}
                        checkBoxHandler={(event: any) =>
                          handleClick(event, row.memId ? row.memId : row.name, row)
                        }
                        memDetails={memFields}
                        memActions={memActions}
                        memId={row.memId}
                        team={row.team}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        tableType="members"
                        membersType={individualTeam}
                        hideActionColumn={hideActionColumn}
                        userRole={userRole}
                      />
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End
