import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import { returnWindowLocation } from "../../../components/src/ReusableFunctions";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  isMobile?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMenuDrawerOpened: boolean;
  anchorEl: any;
  popoveAnchorEl: any;
  createCampaignEl: any;
  createEmailCampaignForm: boolean;
  activeStep: number;
  stepChangeValue: number;
  sentCampaignList: any;
  isLoading: boolean;
  openViewCampaignModal: boolean;
  emailCampaignType: string;
  showStepperTimeline: boolean;
  previewTemplate: boolean;
  showTimePicker: boolean;
  hours: string;
  minutes: string;
  seconds: string;
  sortCampgnVal: string;
  isSorting: boolean;
  brevoContactLists: any;
  openExistingCampaigns: boolean;
  isBrevoAccountConnected: boolean;
  isConnectingBrevoAccount: boolean;
  brevoAPIKey: string;
  brevoAPIKeyError: string;
  campaignListIds: any;
  emailTemplatesList: any;
  campaignId: string;
  campaignListCount: string;
  utmTrackingValue: string;
  fromName: string;
  sendersList: any[];
  searchListValue: string;
  isSearchLists:boolean;
  sortListDirection: string;
  isSortingLists: boolean;
  campaignTemplateId: string;
  totalRecipientsCount: number;
  totalSubCounts: any;
  anchorElContacts:any;
  anchorElCampaigns:any;
  anchorElDesigns:any;
  hasAttachments: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CfsendinblueapiControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCampaignsRequestId: string = "";
  createEmailCampaignRequestId: string = "";
  getContactListsRequestId: string = "";
  validateBrevoAPIRequestId: string = "";
  getEmailTemplateListAPIRequestId: string = "";
  getBrevoAPIRequestId: string = "";
  getCountsofSendEmailId:string = "";
  popoverRef: React.RefObject<any>;
  userSessionData: any;
  userToken: any;
  isVerifiedBrevo: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.popoverRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isMenuDrawerOpened: false,
      anchorEl: null,
      createCampaignEl: false,
      popoveAnchorEl: null,
      createEmailCampaignForm: false,
      activeStep: 0,
      stepChangeValue: 1,
      sentCampaignList: [],
      campaignListCount: "",
      isLoading: false,
      openViewCampaignModal: false,
      emailCampaignType: "sent",
      showStepperTimeline: false,
      previewTemplate: false,
      showTimePicker: false,
      hours: "HH",
      minutes: "MM",
      seconds: "SS",
      sortCampgnVal: "",
      isSorting: false,
      brevoContactLists: [],
      openExistingCampaigns: false,
      isBrevoAccountConnected: true,
      isConnectingBrevoAccount: false,
      brevoAPIKey: "",
      brevoAPIKeyError: "",
      campaignListIds: [],
      emailTemplatesList: [],
      campaignId: "",
      utmTrackingValue: "",
      fromName: "",
      sendersList: [],
      searchListValue: "",
      isSearchLists: false,
      sortListDirection: "",
      isSortingLists: false,
      campaignTemplateId: '',
      totalRecipientsCount: 0,
      totalSubCounts: [],
      anchorElContacts: null,
      anchorElCampaigns:null,
      anchorElDesigns:null,
      hasAttachments: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      switch (apiRequestCallId) {
        case this.getAllCampaignsRequestId:
          this.setState({ sentCampaignList: responseJson.data.campaigns });
          this.setState({ campaignListCount: responseJson.data.count });
          this.setState({ isLoading: false });

          break;
       
         
        case this.getContactListsRequestId:
          this.setState({ brevoContactLists: responseJson.data.lists });
          this.setState({ isLoading: false });
          break;
        case this.validateBrevoAPIRequestId:
          this.setState({ isLoading: false });
          if (responseJson.message.response === "success") {
            this.getBrevoStatus();
            this.createToastNotificationSuccess(
              "Account validated succesfully"
            );
          } else {
            this.createToastNotificationError(responseJson.message);
          }
          break;
          case this.getEmailTemplateListAPIRequestId:
            this.handleTemplateResponse(responseJson)
            
            break;
       
        case this.getBrevoAPIRequestId:
          this.setState({ isLoading: false });
          this.setState({
            isBrevoAccountConnected:
              responseJson.data.attributes.brevo_verified,
          });
          break;
        case this.getCountsofSendEmailId:
          this.setState({
            totalSubCounts : responseJson.marketings
          })
        break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const langT = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(langT);
    this.getBrevoStatus();
    this.getAllCreatedCampaigns();
    this.getEmailSubCounts();
    this.getAllListsfromBrevo();
    this.getEmailTemplateLists();
    const currentPath = returnWindowLocation(window)
    if (currentPath === "/email-campaigns-drafts") {
      this.setState({emailCampaignType: "draft"});
    }
    if(currentPath === "/email-campaigns-scheduled")
      this.setState({emailCampaignType: "queued"})
  }
  
  t(key: any, variable?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variable });
  }
  toggleMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: true,
    });
  };
  closeMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: false,
    });
  };
  popoverHandleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({
        createCampaignEl: null,
        popoveAnchorEl: event.currentTarget,
        createEmailCampaignForm: true,
      });
    }
  };
  openExistingCampaignModal = () => {
    this.setState({
      openExistingCampaigns: true,
    });
  };
  closeExistingCampaignModal = () => {
    this.setState({
      openExistingCampaigns: false,
    });
  };
 
  createCampaignOpen = (event: any) => {
    if (event) {
      this.setState({ createCampaignEl: event.currentTarget });
    }
  };

  createCampaignClose = () => {
    this.setState({ createCampaignEl: null });
  };

  popoverHandleClose = () => {
    this.setState({ popoveAnchorEl: null });
  };
 

 

  viewCampaignDetails = (campaignId: string) => {
    this.setState({ openViewCampaignModal: true, campaignId: campaignId });
  };
  closeCampaignModal = () => {
    this.setState({ openViewCampaignModal: false });
  };

 
 
 
  // showSentEmails = () => {
  //   this.setState({ emailCampaignType: "sent" }, () => {
  //     this.getAllCreatedCampaigns();
  //   });
  // };

  handleSortCampaign = (event: { target: { value: any } }) => {
    this.setState({ isSorting : true, sortCampgnVal: event.target.value }, () => this.getAllCreatedCampaigns());
  };

  handleSortByLists = (value:any) => {
    if(value === "A-Z"){
      this.setState({
        sortListDirection : 'asc'
      })
    }else if(value === "Z-A"){
      this.setState({
        sortListDirection : 'desc'
      })
    }else {
      this.setState({
        sortListDirection : ''
      })
    }
    this.setState({isSortingLists: true})
  }

  getAllCreatedCampaigns = () => {
    const {isSorting, sortCampgnVal} = this.state;
    let status = "sent";
    const currentPath = returnWindowLocation(window)
    let sortKey = "";
    if(sortCampgnVal === configJSON.newestooldTxt) {
      sortKey = 'desc'
    }else if(sortCampgnVal === configJSON.oldtonewTxt)
      {
        sortKey = 'asc'
      }
      
      if (currentPath === "/email-campaigns-drafts") {
        status = "draft"
      }
      if(currentPath === "/email-campaigns-scheduled"){
        status = "queued"
      }
      
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    
    let baseURL = configJSON.brevoViewAllCampaigns +
    `?status=${status}&`

    if(isSorting && sortCampgnVal !== "Sort by"){
      baseURL += `sort=id&sort_direction=${sortKey}`
    }

    this.getAllCampaignsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // showDraftEmails = () => {
  //   this.setState({ emailCampaignType: "draft" }, () => {
  //     this.getAllCreatedCampaigns();
  //   });
  // };
  // showScheduedEmails = () => {
  //   this.setState({ emailCampaignType: "queued" }, () => {
  //     this.getAllCreatedCampaigns();
  //   });
  // };
  handleBackButton = () => {
    const {activeStep, stepChangeValue} = this.state;
      this.setState({
        activeStep: activeStep - 1,
        stepChangeValue: stepChangeValue - 1,
      });
  }
  
  
 
  handleConnectBrevoAccount = () => {
    this.setState({ isConnectingBrevoAccount: true });
  };
  closeBrevoInput = () => {
    this.setState({ isConnectingBrevoAccount: false });
  };
  handleBrevoAPIKeyInput = (apiKeyVal: string) => {
    this.setState({ brevoAPIKey: apiKeyVal });
  };
  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  createToastNotificationError = (toastMessage: string) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  validateBrevoAccount = () => {
    let errorFlag = false;

    let brevoAPIKeyEmpty = this.state.brevoAPIKeyError;
    if (this.state.brevoAPIKey.trim() === "") {
      brevoAPIKeyEmpty = "Please enter brevo api key";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        brevoAPIKeyError: brevoAPIKeyEmpty,
      });
      return true;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      api_key: this.state.brevoAPIKey,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateBrevoAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateAPIKeyBrevo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
  
  getAllListsfromBrevo = () => {
    const {isSearchLists,searchListValue,isSortingLists,sortListDirection} = this.state;
    let endPoint;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let baseURL =  configJSON.getContactLists;
    if(isSearchLists){
      endPoint = baseURL + `?search_key=${searchListValue}`
    } else if(isSortingLists && sortListDirection !=="") {
      endPoint = baseURL + `?sort=name&sort_direction=${sortListDirection}`
    }else{
      endPoint = baseURL
    }
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactListsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  getEmailTemplateLists = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmailTemplateListAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTemplates
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBrevoStatus = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrevoAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getEmailSubCounts = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountsofSendEmailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCounts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleEmailFilterDates = (date: any) => {
    // //this.setState({ emailFilterDate: date }
    // );
  };
  handleContactsMenuOpen = (event: any) => {
    if (event) {
      this.setState({ anchorElContacts: event.target });
    }
  };
  handleContactsMenuClose = () => {
    this.setState({ anchorElContacts: null });
  };
  handleCampaignsOpen = (event:any) => {
    if (event) {
      this.setState({ anchorElCampaigns: event.target });
    }
  }
  handleCampaignsClose = () => {
    this.setState({
      anchorElCampaigns: null
    })
  }

  handleDesignsOpen = (event:any) => {
    if (event) {
      this.setState({ anchorElDesigns: event.target });
    }
  }
  handleDesignsClose = () => {
    this.setState({
      anchorElDesigns: null
    })
  }
  handleTemplateResponse = (responseJson:any) => {
    if(responseJson.data?.templates.length > 0){
      this.setState({ emailTemplatesList: responseJson.data.templates });
      }
  }
  // Customizable Area End
}
