Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.backButton = "Back";
exports.workingDaysTxt="Working Days";
exports.timeOffTxt="Time Off";
exports.chooseWorkingDaysTxt="Please choose your working days.";
exports.weekEndTxt="Weekends";
exports.weekDaysTxt="Weekdays";
exports.daysOfWeek = [
  { displayName: "MON", checkedName: "Monday" },
  { displayName: "TUE", checkedName: "Tuesday" },
  { displayName: "WED", checkedName: "Wednesday" },
  { displayName: "THU", checkedName: "Thursday" },
  { displayName: "FRI", checkedName: "Friday" },
  { displayName: "SAT", checkedName: "Saturday" },
  { displayName: "SUN", checkedName: "Sunday" },
];
exports.cancel="Cancel";
exports.save="Save";
exports.workingDaysApiEndpoint ="/bx_block_calendar/working_days";
exports.successMsg="Working Days Updated.";
exports.timeOffsApiEndpoint="/bx_block_calendar/time_offs";
exports.timeOffSubTxt ="View your time off schedule";
exports.sortByTxt="Sort by";
exports.addTimeOff="Add time off";
exports.timeSuccess ="Time Off Added.";
exports.selectDayTxt ="Select Day(s) Off";
exports.selectDaySubText ="Please select the day(s) when you will be off.";
exports.doneTxt="Done";
exports.todayTxt="Today"
// Customizable Area End