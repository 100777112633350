import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openViewModal: boolean;
  closeViewModal: () => void;
  campaignViewId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  viewCampaignDetail: any;
  isAdvanceSetting:boolean;
  gAEnabled: boolean;
  attachmentEnable: boolean;
  mirrorLinkEnable: boolean;
  EmbedImagesEnable: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewEmailCampaignControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  viewSingleEmailRequestAPI: string = "";
  userSessionData: any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];
    
    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");

    this.state = {
      // Customizable Area Start
      isLoading: false,
      viewCampaignDetail: [],
      isAdvanceSetting:false,
      EmbedImagesEnable: false,
      gAEnabled: false,
      mirrorLinkEnable: false,
      attachmentEnable: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      if(apiRequestCallId === this.viewSingleEmailRequestAPI){
        this.setState({isLoading : false})
        this.setState({viewCampaignDetail: responseJson.data})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {  
    const lang = localStorage.getItem("lang") || "en"; 
    await(i18n as any).changeLanguage(lang);
    this.viewClickedEmail();
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  viewClickedEmail = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
   
   
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewSingleEmailRequestAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificMail + `?id=${this.props.campaignViewId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAdvanceSettings = () =>{
    this.setState({isAdvanceSetting:true})
  }
  handleCloseAdvanceSettings = () =>{
    this.setState({isAdvanceSetting:false})
  }  
  handleAdvancedCampaignSettings = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name;
    const checked = event.target.checked;
    switch (name) {
      case "EmbedImages":
        this.setState({ EmbedImagesEnable: checked });
        break;
      case "GoogleAnalytics":
        this.setState({ gAEnabled: checked });
        break;
      case "mirrolLink":
        this.setState({ mirrorLinkEnable: checked });
        break;
      case "addAttachment":
        this.setState({ attachmentEnable: checked });
        break;
    }
  };
  // Customizable Area End
}
