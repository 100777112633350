// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./TeamsStyles.web";
import { toast } from "react-toastify";
import {
  memberRevoked,
  checkedIcon,
  teamDeleted,
  memberEdited,
  teamRename,
  memberMoved,
  memberReActivated,
} from "../assets";
import i18n from "../../../../web/src/utilities/i18n";
export const configJSON = require("../config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  location?: any;
  id: string;
}

export interface S {
  tabValue: string;
  isTeamsTab: boolean;
  teamsList: any;
  unAssignedTeamList: any;
  membersList: any;
  excludeTeamsList: any;
  memberListbyTeam: any;
  modalOpen: boolean;
  newTeamName: string;
  renameTeamData: {
    id: string;
    name: string;
  };
  anchorEl : any;
  moveTeamData: {
    teamId: any;
    memberId: string;
    currentTeams: any;
  };
  memberTeamExists: string;
  moveTeamError: {
    choose_team_error: string;
  };
  deleteTeamId: string;
  renameTeamValue: string;
  currentTeamName: string;
  isCreatingTeam: boolean;
  isRenaming: boolean;
  isFilter: boolean;
  isDeleting: boolean;
  isDeactivating: boolean;
  isReactivating: boolean;
  isEditing: boolean;
  isAddingMember: boolean;
  isSearching: boolean;
  isMovingMember: boolean;
  isEditingEmail: boolean;
  isEditingPassword: boolean;
  isDeactivatedActive: boolean;
  isMultipleDelete: boolean;
  multipledeleteIds: any;
  isRemovingmember: boolean;
  isFiltering: boolean;
  isChanged: boolean;
  searchValue: string;
  teamSearchValue: string;
  deActivateMember: {
    deActivateMemberName: string;
    deActivateMemberID: number;
  };
  searchData: any;
  teamSearchData: any;
  filteredData: any;
  filterAPIResponse: any;
  selectedTeamVal: [];
  selectedRoleVal: [];
  selectedTitleVal: any[];
  selected: number[];
  inputFields: [];
  additionalFields: any;
  editMembers: {
    memberId: number;
    firstName: string;
    lastName: string;
    team: string;
    email: string;
    password: string;
    jobType: string;
    jobRole: string;
    hourlyPay: number | null;
    country:string | null ;
    state: string | null;
    city: string | null;
    postal_code: string | null;
    companyAddress: string | null;
    companyName: string | null;
  };
  reActivateMembers: {
    memberId: number;
    firstName: string;
    lastName: string;
    team: string;
    email: string;
    jobType: string;
    jobRole: string;
    hourlyPay: number | null;
  };
  emailSuccessMsg: string;
  isResend: boolean;
  resendSec: number;
  intervalId: NodeJS.Timeout | null;
  showTimer: boolean;
  disable: boolean;
  newEmailAddress: string;
  newEmailAddressError: string;
  newPassword: string;
  newPasswordError: string;
  updatedMemberTeam: {
    teamId: string;
    memberId: string;
  };
  updateMemTeamEmpty: string;
  updateMemError: {
    message: string;
  };
  error: {
    errornewTeamNameNotValid: {
      message: string;
    };
  };
  editMemerror: {
    errorMemFirstName: string;
    errorMemLastName: string;
    errorMemEmail: string;
    errorMemPassword: string;
    errorMemHourlyPay: string;
    errorMemJobRole: string;
    errorMemJobType: string;
  };
  reActivateMemerror: {
    errorReMemFirstName: string;
    errorReMemLastName: string;
    errorReMemEmail: string;
    errorReMemJobRole: string;
    errorReMemChooseTeam: string;
    errorReMemHourlyPay: string;
  };
  reMoveMem: {
    firstName: string;
    lastName: string;
    memID: string;
  };
  isAscendingTeam: boolean | null;
  isAscendingMember: boolean | null;
  filteredDataTeam: any;
  teamFilterTab: any[];
  filteredTeam: any[];
  clearSelectedValues: boolean;
  isLoading: boolean;
  showSkipButton:boolean,
  isCartOpen:boolean,
  isRemoveTeam:boolean;
  removeTeamTitle:string;
  removeTeamId:any;
}

export interface SS {
  id: any;
}

export default class AccountCreationControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  arrayholder: any[];
  fetchAllTeamApiCallId: string = "";
  createTeamApiCallId: string = "";
  unAssignedTeamApiCallId: string = "";
  updateTeamApiCallId: string = "";
  invitedMemListApiCallId: string = "";
  deleteTeamAPICallId: string = "";
  multipledeleteTeamAPICallId: string = "";
  removeMemberTeamAPICallId: string = "";
  searchTeamAPICallId: string = "";
  editMemberAPICallId: string = "";
  deActivateMemberAPICallId: string = "";
  fetchMemberByTeamAPICallId: string = "";
  moveMemberAPICallId: string = "";
  updateMemberTeamAPICallId: string = "";
  updateMemberEmailAPICallId: string = "";
  updatePasswordAPICallId: string = "";
  reActivateMemberAPICallId: string = "";
  undoActionAPICalld: string = "";
  filterMemberAPICallId: string = "";
  teamListExcludeAPICallID: string = "";
  teamFilterAPICallID: string = "";
  getUserQuizesAPICallId: string = "";
  canSkipAPICallId:string = "";
  updateSkipAPICallId:string = "";
  userSessionData: any;
  userToken: any;
  userRole: any;
  teamIDParam: any;
  labelwebTeamsTitle: string;
  labelwebTeamsSubTitle: string;
  labelwebInviteBtn: string;
  labelwebCreateTeam: string;
  labelwebAnotherTeamtxt: string;
  labelwebCreateTeamSubtxt: string;
  labelwebCreateFinishtxt: string;
  labelwebCreateTeamBtntxt: string;
  labelwebCreateTeamtxt: string;
  labelwebRenameTeamBtntxt: string;
  labelwebRenameTeamtxt: string;
  labelCreateTeamModaltxt: string;
  labelRenameTeamModaltxt: string;
  deleteModalTxt: string;
  deactivateModalMessage: string;
  labelDeleteModalBtnTxt: string;
  labelDeactivateMemberBtnTxt: string;
  removeMemberApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      tabValue: "1",
      isTeamsTab: true,
      teamsList: [],
      unAssignedTeamList: [],
      membersList: [],
      memberListbyTeam: [],
      excludeTeamsList: [],
      modalOpen: false,
      newTeamName: "",
      renameTeamData: {
        id: "",
        name: "",
      },
      moveTeamData: {
        teamId: "",
        memberId: "",
        currentTeams: [],
      },
      moveTeamError: {
        choose_team_error: "",
      },
      memberTeamExists: "",
      renameTeamValue: "",
      currentTeamName: "",
      deleteTeamId: "",
      isCreatingTeam: false,
      isRenaming: false,
      isFilter: false,
      isDeleting: false,
      isDeactivating: false,
      isReactivating: false,
      isEditing: false,
      isAddingMember: false,
      isMovingMember: false,
      isEditingEmail: false,
      isEditingPassword: false,
      isDeactivatedActive: false,
      isMultipleDelete: false,
      multipledeleteIds: [],
      isFiltering: false,
      isChanged: false,
      isRemovingmember: false,
      searchValue: "",
      teamSearchValue: "",
      deActivateMember: {
        deActivateMemberName: "",
        deActivateMemberID: 0,
      },
      isSearching: false,
      searchData: [],
      teamSearchData: [],
      filteredData: [],
      filterAPIResponse: [],
      selectedTeamVal: [],
      selectedRoleVal: [],
      selectedTitleVal: [],
      selected: [],
      inputFields: [],
      additionalFields: [{ field: "i1", value: [], id: 0 }],
      editMembers: {
        memberId: 0,
        firstName: "",
        lastName: "",
        team: "",
        email: "",
        password: "********",
        jobRole: "",
        jobType: "",
        hourlyPay: null,
        country: null,
        city: null,
        state: null,
        postal_code: null,
        companyAddress: null,
        companyName: null,
      },
      reActivateMembers: {
        memberId: 0,
        firstName: "",
        lastName: "",
        team: "",
        email: "",
        jobRole: "",
        jobType: "",
        hourlyPay: null,
      },
      emailSuccessMsg: "",
      isResend: false,
      resendSec: 15,
      intervalId: null,
      showTimer: false,
      disable: false,
      newEmailAddress: "",
      newEmailAddressError: "",
      newPassword: "",
      newPasswordError: "",
      updatedMemberTeam: {
        teamId: "",
        memberId: "",
      },
      updateMemTeamEmpty: "",
      updateMemError: {
        message: "",
      },
      error: {
        errornewTeamNameNotValid: {
          message: "",
        },
      },
      editMemerror: {
        errorMemFirstName: "",
        errorMemLastName: "",
        errorMemEmail: "",
        errorMemPassword: "",
        errorMemHourlyPay: "",
        errorMemJobRole: "",
        errorMemJobType: "",
      },
      reActivateMemerror: {
        errorReMemFirstName: "",
        errorReMemLastName: "",
        errorReMemEmail: "",
        errorReMemChooseTeam: "",
        errorReMemJobRole: "",
        errorReMemHourlyPay: "",
      },
      reMoveMem: {
        firstName: "",
        lastName: "",
        memID: "",
      },
      isAscendingTeam: null,
      isAscendingMember: null,
      filteredDataTeam: [],
      teamFilterTab: [],
      filteredTeam: [],
      clearSelectedValues: false,
      isLoading: false,
      showSkipButton:false,
      anchorEl : null,
      isCartOpen: false,
      isRemoveTeam:false,
      removeTeamTitle:"",
      removeTeamId:""
    };

    this.arrayholder = [];
    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.userRole = this.userToken.data.attributes.job_type;
    this.teamIDParam = this.getLastPartOfURL(window.location.pathname);
    this.labelwebTeamsTitle = configJSON.webTeamsTitle;
    this.labelwebTeamsSubTitle = configJSON.webTeamsSubTitle;
    this.labelwebInviteBtn = configJSON.webInviteBtn;
    this.labelwebCreateTeam = configJSON.labelwebCreateTeam;
    this.labelwebCreateTeamSubtxt = configJSON.labelwebCreateTeamSubtxt;
    this.labelwebCreateTeamBtntxt = configJSON.labelwebCreateTeamBtntxt;
    this.labelwebAnotherTeamtxt = configJSON.labelwebAnotherTeamtxt;
    this.labelwebCreateFinishtxt = configJSON.labelwebCreateFinishtxt;
    this.labelwebCreateTeamtxt = configJSON.labelwebCreateTeamtxt;
    this.labelwebRenameTeamBtntxt = configJSON.labelwebRenameTeamBtntxt;
    this.labelwebRenameTeamtxt = configJSON.labelwebRenameTeamtxt;
    this.labelCreateTeamModaltxt = configJSON.labelCreateTeamModaltxt;
    this.labelRenameTeamModaltxt = configJSON.labelRenameTeamModaltxt;
    this.deleteModalTxt = configJSON.DeleteModalMessage;
    this.deactivateModalMessage = configJSON.deactivateModalMessage;
    this.labelDeleteModalBtnTxt = configJSON.labelDeleteModalBtnTxt;
    this.labelDeactivateMemberBtnTxt = configJSON.labelDeactivateMemberBtnTxt;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.fetchAllCreatedTeams();
    this.unAssignedTeamList();
    this.invitedMemList();
    this.getAllUserQuizes();
    this.checkForSkip();
    const lang = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(lang);

    if (
      this.props.navigation.getParam("teamID") !== undefined &&
      this.props.navigation.getParam("teamID") !== "unassigned-members"
    ) {
      this.fetchMembersByTeam();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.showTimer !== this.state.showTimer && this.state.showTimer) {
      const newIntervalId = setInterval(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            resendSec: prevState.resendSec - 1,
          };
        });
      }, 1000);
      this.setState({ intervalId: newIntervalId });
    }
    if (prevState.resendSec !== this.state.resendSec) {
      if (this.state.resendSec == 0) {
        if (this.state.intervalId != null) {
          clearInterval(this.state.intervalId);
          this.setState({ showTimer: false, disable: false });
        }
      }
    }
    if (prevState.teamsList !== this.state.teamsList) {
      this.setState({
        teamFilterTab: this.state.teamFilterTab.filter(
          (x: any) =>
            this.state.teamsList.find((y: any) => y.attributes.id == x) !=
            undefined
        ),
      });
    }
    if (prevState.newPassword !== this.state.newPassword) {
      this.setState({ newPasswordError: "" });
    }
    if (prevState.newEmailAddress !== this.state.newEmailAddress) {
      this.setState({ newEmailAddressError: "" });
    }
    if (prevState.additionalFields !== this.state.additionalFields) {

      this.state.additionalFields.forEach((item: any) => {
        if (item.value !== "") {
          // Set an error message for fields with empty values

          this.setState({
            error: {
              errornewTeamNameNotValid: {
                message: "",
              },
            },
          });
        }
      });
    }
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  getLastPartOfURL = (url: string) => {
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.setState({isLoading: false})
        switch (apiRequestCallId) {
          case this.fetchAllTeamApiCallId: {
            this.handleFetchTeamResponse(responseJson);
            break;
          }
          case this.createTeamApiCallId: {
            this.handleCreateTeamResponse(responseJson);
            break;
          }
          case this.unAssignedTeamApiCallId: {
            this.handleUnAssignedTeam(responseJson);
            break;
          }
          case this.invitedMemListApiCallId: {
            this.handleInvitedMemResponse(responseJson);
            break;
          }

          case this.updateTeamApiCallId: {
            this.handleRenameTeamResponse(responseJson);
            break;
          }
          case this.deleteTeamAPICallId: {
            this.handledeleteTeamResponse(responseJson);
            break;
          }
          case this.multipledeleteTeamAPICallId: {
            this.handleMultipleDeleteResponse(responseJson);
            break;
          }
          case this.editMemberAPICallId: {
            this.handleEditMemberResponse(responseJson);
            break;
          }
          case this.deActivateMemberAPICallId: {
            this.handledeActivateMemberResponse(
              responseJson,
              configJSON.webMemberDeactivated,
              memberRevoked
            );
            break;
          }
          case this.reActivateMemberAPICallId: {
            this.handleReactivaterMemberResponse(
              responseJson,
              responseJson.message,
              memberReActivated
            );
            break;
          }
          case this.fetchMemberByTeamAPICallId: {
            this.handlefetchMemberResponse(responseJson);
            break;
          }
          case this.moveMemberAPICallId: {
            this.handleMoveMemResponse(responseJson);
            break;
          }
          case this.filterMemberAPICallId: {
            this.handleFilterMmemberResponse(responseJson);
            break;
          }
          case this.updateMemberTeamAPICallId: {
            this.handleUpdateMemberTeam(responseJson);
            break;
          }
          case this.updateMemberEmailAPICallId: {
            this.handleEmmailUpdateMember(responseJson);
            break;
          }
          case this.updatePasswordAPICallId: {
            this.handlePasswordUpdateMember(responseJson);
            break;
          }
          case this.removeMemberTeamAPICallId: {
            this.handleRemoveMemberfromTeam(responseJson);
            break;
          }
          case this.undoActionAPICalld: {
            this.handleUndoActionAPI();
            break;
          }
          case this.teamListExcludeAPICallID: {
            this.setState({ excludeTeamsList: responseJson.data });
            break;
          }
          case this.teamFilterAPICallID: {
            this.setState({ filteredTeam: responseJson.data }, () => {
              this.searchTeamRecords(this.state.teamSearchValue);
            });
            break;
          }
          case this.canSkipAPICallId: {
            this.setState({
              showSkipButton: responseJson.data.attributes.can_skip,
            })
            break;
          }
          case this.updateSkipAPICallId:{
            this.handleSkipAPIResponse(responseJson)
            break;
          }
          case this.removeMemberApiCallId:
            this.setState({ isRemoveTeam: false });
            let memDetailsData = responseJson.data.attributes;
            this.setState({
              editMembers: {
                memberId: memDetailsData.id,
                firstName: memDetailsData.first_name,
                lastName: memDetailsData.last_name,
                team: memDetailsData.team,
                email: memDetailsData.email,
                password: this.state.editMembers.password,
                jobType: memDetailsData.job_type,
                jobRole: memDetailsData.job_role,
                hourlyPay: memDetailsData.hourly_pay,
                country: memDetailsData.country,
                state:memDetailsData.state,
                postal_code:memDetailsData.postal_code,
                city:memDetailsData.city,
                companyAddress: memDetailsData.company_place_address,
                companyName: memDetailsData.company_name,
              },
            });
            this.createToastNotification("Removed from Team", memberRevoked);
            break;
          default: {
            //statements;
            break;
          }
        }
      }
    }
  }

  /*Network request functions*/

  teamFilter = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.teamFilterAPICallID = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teamSearchFilter +
        `?search=${""}&choose_team=${JSON.stringify(this.state.teamFilterTab)}`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAllCreatedTeams = () => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAllTeamApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unAssignedTeamList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unAssignedTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webUnassignedTeam
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  invitedMemList = () => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.invitedMemListApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webgetMembersList
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchMembersByTeam = () => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchMemberByTeamAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webgetMembersTeamList +
        this.props.navigation.getParam("teamID")
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllUserQuizes = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserQuizesAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAlUserQuizes
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  tabsChangeHandler = (tabValue: string) => {
    this.setState({ tabValue: tabValue });
    if (tabValue === "2") {
      this.setState({ isTeamsTab: false });
    } else {
      this.setState({ isTeamsTab: true });
    }
  };

  handle_modalOpen = () => {
    this.setState({ modalOpen: true });
  };
  handle_modalClose = () => {
    const newState = {
      error: { errornewTeamNameNotValid: { message: "" } },
      isCreatingTeam: false,
      additionalFields: [{ field: "i1", value: [], id: 0 }],
      isRenaming: false,
      isDeleting: false,
      isEditing: false,
      isDeactivating: false,
      isReactivating: false,
      isAddingMember: false,
      isEditingEmail: false,
      isEditingPassword: false,
      isMultipleDelete: false,
      isRemovingmember: false,
      isMovingMember: false,
      modalOpen: false,
      newEmailAddress: "",
      newEmailAddressError: "",
      newPassword: "",
      newPasswordError: "",
      updateMemError: {
        message: "",
      },
      editMemerror: {
        errorMemFirstName: "",
        errorMemLastName: "",
        errorMemEmail: "",
        errorMemPassword: "",
        errorMemHourlyPay: "",
        errorMemJobRole: "",
        errorMemJobType: "",
      },
      updatedMemberTeam: {
        teamId: "",
        memberId: "",
      },
    };
    this.setState(newState);
  };

  createTeamHandler = () => {
    this.setState({ isCreatingTeam: true });
  };

  renameTeamHandler = (newName: any, id: any) => {
    this.handle_modalOpen();
    this.setState({ isRenaming: true });
    this.setState({
      renameTeamData: {
        id: id,
        name: newName,
      },
    });
    this.setState({ renameTeamValue: newName });
  };

  deleteTeamHandler = (team_id: any) => {
    this.setState({ isDeleting: true });
    this.setState({ deleteTeamId: team_id });
  };

  editMemberHandler = (memDetails: any) => {
    this.setState({ isEditing: true });
    this.handle_modalOpen();
    this.setState({
      editMembers: {
        memberId: memDetails.memId,
        firstName: memDetails.firstName,
        lastName: memDetails.lastName,
        team: memDetails.team,
        email: memDetails.email,
        password: this.state.editMembers.password,
        jobType: memDetails.jobType,
        jobRole: memDetails.jobRole,
        hourlyPay: memDetails.hourly_pay,
        country: memDetails.businessInfo.country,
        state:memDetails.businessInfo.state,
        postal_code:memDetails.businessInfo.postal_code,
        city:memDetails.businessInfo.city,
        companyAddress: memDetails.businessInfo.company_place_address,
        companyName: memDetails.businessInfo.company_name,
      },
    });
  };

  reactivateMemHAndler = (
    memDetails:any,
    team: string,
  ) => {
    this.setState({ isReactivating: true });
    this.handle_modalOpen();
    this.setState({
      reActivateMembers: {
        memberId: memDetails.memId,
        firstName: memDetails.firstName,
        lastName: memDetails.lastName,
        team: team,
        email: memDetails.email,
        jobType: memDetails.jobType,
        jobRole: memDetails.jobRole,
        hourlyPay: memDetails.hourly_pay,
      },
    });
  };

  reActivateChooseTeam = (event: any) => {
    this.setState({
      reActivateMembers: {
        ...this.state.reActivateMembers,
        team: event.target.value,
      },
    });
  };


  deactivateMemHandler = (memberDetails:any) => {
    this.setState({ isDeactivating: true });
    this.setState({
      deActivateMember: {
        deActivateMemberName: `${memberDetails.firstName} ${memberDetails.lastName}`,
        deActivateMemberID: memberDetails.memId,
      },
    });
  };

  moveToTeamHandler = (memberId: string, currentTeams: any) => {
    this.excludeTeamListAPI(memberId);
    this.setState({ isMovingMember: true });
    this.setState({
      moveTeamData: {
        ...this.state.moveTeamData,
        memberId: memberId,
        currentTeams: [...new Set(currentTeams)],
      },
    });
    this.handle_modalOpen();
  };

  removeMemberFromTeam = (
    firstName: string,
    lastName: string,
    memID: string
  ) => {
    this.setState({ isRemovingmember: true });
    this.setState({
      reMoveMem: {
        firstName: firstName,
        lastName: lastName,
        memID: memID,
      },
    });
    this.handle_modalOpen();
  };

  addMembertoTeamHandler = (memID: string) => {
    this.excludeTeamListAPI(memID);
    this.setState({ isAddingMember: true });
    this.setState({
      updatedMemberTeam: {
        ...this.state.updatedMemberTeam,
        memberId: memID,
      },
    });
    this.handle_modalOpen();
  };

  addMemberToTeamChange = (e: any) => {
    this.setState({
      updatedMemberTeam: {
        ...this.state.updatedMemberTeam,
        teamId: e.target.value,
      },
    });
  };

  handleEditBack = () => {
    this.setState({
      isEditingEmail: false,
      isEditingPassword: false,
      isEditing: true,
      newEmailAddress: "",
      newEmailAddressError: "",
    });
  };

  teamValueChangeHandler = (event: any, row: any, i: any) => {
    let newArray = [...this.state.additionalFields];
    newArray[row].value = event.target.value;
    this.setState({ additionalFields: newArray });
  };

  preventReloadonEnter = (e:any) => {
    // Check if the pressed key is Enter (key code 13)
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  /*Network calls functions*/
  createTeams = () => {
    if (this.checkIfTeamTitlesEmpty()) return;
    let teamsToAdd;
    if (this.state.additionalFields.length > 0) {
      teamsToAdd = this.state.additionalFields.map((data: any) => {
        return data.value.trim();
      });
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        title: teamsToAdd,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createTeamApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTeamsEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodPost
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkIfTeamTitlesEmpty = (): boolean => {
    let errorFlag = false;
    let newTeamError = this.state.error.errornewTeamNameNotValid;

    if (this.state.additionalFields.length > 0) {
      this.state.additionalFields.map((data: any) => {
        if (
          data.value.length == 0 || data.value === '' ||
          (typeof data.value === "string" && data.value.trim() === "")
        ) {
          newTeamError = { message: "Team title cannot be empty" };
          errorFlag = true;
        }
      });
    }

    if (errorFlag) {
      this.setState({
        error: {
          errornewTeamNameNotValid: newTeamError,
        },
      });
      return true;
    } else {
      return false;
    }
  };

  addAnotherTeam = () => {
    const rowLength = this.state.additionalFields.length;
    let lastele = this.state.additionalFields[rowLength - 1];
    const item = {
      field: "",
      value: "",
      id: lastele.id + 1,
    };
    this.setState({
      additionalFields: [...this.state.additionalFields, item],
    });
    this.setState({
      error: {
        errornewTeamNameNotValid: { message: "" },
      },
    });
  };

  deleteRow = (rowId: any) => {
    const rowLength = this.state.additionalFields.length;

    if (rowLength > 1) {
      const updateValues = this.state.additionalFields.filter(
        (value: any) => rowId !== value.id
      );

      this.setState({ additionalFields: updateValues });
    }
  };

  updateTeam = () => {
    let errorFlag = false;
    let newTeamError = this.state.error.errornewTeamNameNotValid;
    if (this.state.isRenaming && this.state.renameTeamData.name.trim() === "") {
      newTeamError = { message: "Please enter a team name " };
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({
        error: {
          errornewTeamNameNotValid: newTeamError,
        },
      });
      return true;
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        id: this.state.renameTeamData.id,
        title: this.state.renameTeamData.name,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTeamApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webUpdateTeamsEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteTeamFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      id: this.state.deleteTeamId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTeamAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeleteTeamsEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addMemberToTeamRequest = () => {
    const { selected } = this.state;
    let httpBody = {};
    if (this.state.updatedMemberTeam.teamId == "") {
      this.setState({ updateMemTeamEmpty: "Please choose team" });
      return;
    }
    this.setState({ updateMemTeamEmpty: "" });
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    if (selected.length > 0) {
      const multipleIds = selected.map((item) => {
        return {
          id: item,
        };
      });

      httpBody = {
        account: multipleIds,
        team_id: this.state.updatedMemberTeam.teamId,
      };
      
    } else {
      httpBody = {
        account: [{ id: this.state.updatedMemberTeam.memberId }],
        team_id: this.state.updatedMemberTeam.teamId,
      };
      
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateMemberTeamAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webAddMemberTeamEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  excludeTeamListAPI = (memId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.teamListExcludeAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.excludeMemberTeamAPIEndPoint + memId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  multipleDeleteHandler = (selected: []) => {
    this.setState({ isMultipleDelete: true });
    const ids: any = [];
    selected.forEach((title) => {
      const team = this.state.teamsList.find(
        (team: any) => team.attributes.title === title
      );
      if (team) {
        ids.push(team.id);
      }
    });
    this.setState({ multipledeleteIds: ids });
  };

  getSelectedRecords = (selectedRecords: number[]) => {
    this.setState({
      selected: selectedRecords,
    });
  };

  filterTeamHandler = () => {
    this.handle_modalOpen();
    this.setState({ isFilter: true });
  };

  multipleDeleteTeams = () => {
    const result = JSON.stringify(this.state.multipledeleteIds.map(Number));
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token
    };

    const body = {
      data: {
        ids: result,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.multipledeleteTeamAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webMultipleDeleteEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeMemberTeams = () => {
    const { selected } = this.state;
    let memIdtoRemove = "";
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    if (selected.length > 0) {
      memIdtoRemove = selected.join(); // Join the selected IDs with a comma
    }else{
      memIdtoRemove = this.state.reMoveMem.memID
    }

    const selectedIds = selected.join(); // Join the selected IDs with a comma
    let endpoint = configJSON.webRemoveMemberFromTeam;
    let baseuRL = `${endpoint}[${memIdtoRemove}]&choose_team=${this.getLastPartOfURL(
      window.location.pathname
    )}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeMemberTeamAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseuRL
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodDelete
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createToastNotification = (
    toastMesssage: string,
    toastIcon: string,
    hideUndo?: boolean
  ) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>{" "}
        {!hideUndo && (
          <a
            href="#"
            className="toast-link"
            data-test-id="undo-toast-link"
            onClick={this.undoactionAPICall}
          >
            undo
          </a>
        )}
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata" />,
      }
    );
  };
  createToastNotificationError = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  handleTeamsSearch = (newSearchTerm: { target: { value: string } }) => {
    this.setState({
      teamSearchValue: newSearchTerm.target.value,
    });
    this.searchTeamRecords(newSearchTerm.target.value);
  };

  handleMembersSearch = (newSearchTerm: { target: { value: string } }) => {
    this.setState({
      searchValue: newSearchTerm.target.value,
      isFiltering: true,
    }, this.manageSearchAndFilter);
    
  };

  manageSearchAndFilter = async () => {
    const { membersList, isFiltering, searchValue } = this.state;

    let filterData = membersList;
    if (isFiltering) {
      if (this.state.isChanged) {
        this.filterMembersAPI();
        filterData = this.state.filteredData;
      }

      if (searchValue) {
        filterData = this.searchMemberRecords(filterData);
      }
    }

    this.setState({ filteredData: filterData });
  };

  searchMemberRecords = (filterData: any) => {
    const { searchValue } = this.state;
    return filterData.filter((member: any) => {
      let memTitle = member.attributes.first_name.toLowerCase();
      let memEmail = member.attributes.email.toLowerCase();
      let memLastName = member.attributes.last_name.toLowerCase();
      let memFullName = memTitle + " " + memLastName;
      if (
        memTitle.search(searchValue.toLowerCase()) !== -1 ||
        memLastName.search(searchValue.toLowerCase()) !== -1 ||
        memEmail.search(searchValue.toLowerCase()) !== -1 ||
        memFullName.search(searchValue.toLowerCase()) !== -1
      )
        return memTitle;
    });
  };

  searchTeamRecords = (value: string) => {
    let teamtoShow = this.state.teamsList;

    if (this.state.teamFilterTab.length > 0) {
      teamtoShow = this.state.filteredTeam;
    }

    if (value != "") {
      teamtoShow = teamtoShow.filter((team: any) => {
        let teamTitle = team.attributes.title.toLowerCase();
        if (teamTitle.search(value.toLowerCase()) !== -1) return teamTitle;
      });
    }
    teamtoShow = this.sortTeamRecords(teamtoShow);
    this.setState({ teamSearchData: teamtoShow });
  };

  changeSortByTeams = (e: any) => {
    if (e.target.value == "") {
      this.setState({ isAscendingTeam: null }, () => {
        this.searchTeamRecords(this.state.teamSearchValue);
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscendingTeam: true }, () => {
        this.searchTeamRecords(this.state.teamSearchValue);
      });
    } else {
      this.setState({ isAscendingTeam: false }, () => {
        this.searchTeamRecords(this.state.teamSearchValue);
      });
    }
  };

  changeSortByMembers = (e: any) => {
    if (e.target.value == "") {
      this.setState({ isAscendingMember: null }, () => {
        this.filterMemberRecords();
      });
    } else if (e.target.value == 1) {
      this.setState({ isAscendingMember: true }, () => {
        this.filterMemberRecords();
      });
    } else {
      this.setState({ isAscendingMember: false }, () => {
        this.filterMemberRecords();
      });
    }
  };

  filterMemberRecords = () => {
    let filterDataMembers = [];
    if (this.state.isFiltering) {
      filterDataMembers = this.state.filteredData;
    } else {
      filterDataMembers = this.state.membersList;
    }
    filterDataMembers = this.sortMemberRecords(filterDataMembers);
    this.setState({ filteredData: filterDataMembers });
  };

  sortTeamRecords = (filterData: any) => {
    const { isAscendingTeam } = this.state;
    if (isAscendingTeam === null) {
      return filterData;
    } else if (isAscendingTeam) {
      return filterData.sort((team1: any, team2: any) => {
        return team1.attributes.title.toLowerCase() <
          team2.attributes.title.toLowerCase()
          ? -1
          : 1;
      });
    } else {
      return filterData.sort((team1: any, team2: any) => {
        return team1.attributes.title.toLowerCase() >
          team2.attributes.title.toLowerCase()
          ? -1
          : 1;
      });
    }
  };

  sortMemberRecords = (filterData: any) => {
    const { isAscendingMember } = this.state;
    if (isAscendingMember === null) {
     filterData = this.state.membersList;
     return filterData;
    } else if (isAscendingMember) {
      return filterData.sort((team1: any, team2: any) => {
        return team1.attributes.first_name.toLowerCase() <
          team2.attributes.first_name.toLowerCase()
          ? -1
          : 1;
      });
    } else {
      return filterData.sort((team1: any, team2: any) => {
        return team1.attributes.first_name.toLowerCase() >
          team2.attributes.first_name.toLowerCase()
          ? -1
          : 1;
      });
    }
  };

  handleClearSelection = (key: any) => {
    const newVal: any[] = [];
    const newState = { [key]: newVal } as Pick<S, keyof S>;
    this.setState(newState, () => {
      this.filterMembersAPI();
    });
  };

  handleTeamClearSelection = () => {
    this.setState({ teamFilterTab: [] }, () => {
      this.teamFilter();
    });
  };

  saveSelectedOption = (event: { target: { value: string } }, key: any) => {
    const newState = { [key]: event.target.value } as Pick<S, keyof S>;
    this.setState({ isFiltering: true });
    this.setState({ isChanged: true });
    this.setState(newState, () => {
      this.manageSearchAndFilter();
    });
  };

  saveSelectedTeamOption = (event: { target: { value: any } }, key: any) => {
    if (event.target.value != undefined) {
      const value = event.target.value.filter((x: any) => x != undefined);
      const newState = { [key]: value } as Pick<S, keyof S>;

      this.setState(newState, () => {
        this.teamFilter();
      });
    }
  };
  handledeActivateSwitch = () => {
    this.setState({isFiltering:true});
    this.setState(
      { isDeactivatedActive: !this.state.isDeactivatedActive },
      () => {
        this.filterMembersAPI();
      }
    );
  };

  updateMemberDetails = (key: any, value: any, objectToUpdate: any) => {
    const newState: any = { ...this.state };
    newState[objectToUpdate] = {
      ...newState[objectToUpdate],
      [key]: value,
    };
    this.setState(newState);
  };

  saveEmailAddressValue = (value: string) => {
    this.setState({ newEmailAddress: value });
  };

  savePasswordValue = (value: string) => {
    this.setState({ newPassword: value });
  };

  saveNewTeam = (event: { target: { value: string } }) => {
    this.setState({
      moveTeamData: {
        ...this.state.moveTeamData,
        teamId: event.target.value,
      },
    });
  };
  handleroleChange = (event: any, type: string) => {
    if (type == "editMembers") {
      this.setState({
        editMembers: {
          ...this.state.editMembers,
          jobType: event,
        },
      });
    } else {
      this.setState({
        reActivateMembers: {
          ...this.state.reActivateMembers,
          jobType: event,
        },
      });
    }
  };

  toggleEditingState = () => {
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  changeEmailHandler = () => {
    this.setState({ isEditingEmail: true, isEditing: false });
  };

  changePasswordHandler = () => {
    this.setState({ isEditingPassword: true, isEditing: false });
  };
  handleRemoveTeam = (teamId: any , title:string) =>{
    this.setState({isRemoveTeam: true , removeTeamId:teamId , removeTeamTitle:title})
  }
  handleCloseTeam = () =>{
    this.setState({isRemoveTeam: false})
  }
  removeMemberApiCall = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      choose_team: JSON.stringify(this.state.removeTeamId),
      ids: this.userToken.data.id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeMemberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webRemoveMember
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  checkFieldsValidation = (errorState: any) => {
    let errorFlag = false;
    let firstNameError = errorState.errorMemFirstName;
    let lastNameError = errorState.errorMemLastName;
    let emailError = errorState.errorMemEmail;
    let passwordError = errorState.errorMemPassword;
    let hourlyPayError = errorState.errorMemHourlyPay;
    let jobRoleError = errorState.errorMemJobRole;
    let jobTypeError = errorState.errorMemJobType;

    if (this.state.editMembers.firstName.trim() === "") {
      firstNameError = "Please enter first name";
      errorFlag = true;
    }

    if (this.state.editMembers.lastName.trim() === "") {
      lastNameError = "Please enter last name";
      errorFlag = true;
    }
    if (this.state.editMembers.email.trim() === "") {
      emailError = "Please enter email ";
      errorFlag = true;
    }
    if (this.state.editMembers.password.trim() === "") {
      passwordError = "Please enter password";
      errorFlag = true;
    }

    if (this.state.editMembers.hourlyPay === null) {
      hourlyPayError = "Please enter hourly rate";
      errorFlag = true;
    }

    if (this.state.editMembers.jobRole.trim() === "") {
      jobRoleError = "Please enter job title";
      errorFlag = true;
    }
    if (this.state.editMembers.jobType == "") {
      jobRoleError = "Please choose job role";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        editMemerror: {
          errorMemFirstName: firstNameError,
          errorMemLastName: lastNameError,
          errorMemEmail: emailError,
          errorMemPassword: passwordError,
          errorMemHourlyPay: hourlyPayError,
          errorMemJobRole: jobRoleError,
          errorMemJobType: jobTypeError,
        },
      });
      return true;
    } else {
      return false;
    }
  };

  checkReactivateValidation = (errorState: any) => {
    let errorFlag = false;
    let firstNameError = errorState.errorReMemFirstName;
    let lastNameError = errorState.errorReMemLastName;
    let emailError = errorState.errorReMemEmail;
    let jobTitleError = errorState.errorReMemJobRole;
    let chooseTeamError = errorState.errorReMemChooseTeam;
    let hourlyPayError = errorState.errorReMemHourlyPay;

    if (this.state.reActivateMembers.firstName.trim() === "") {
      firstNameError = "Please enter first name";
      errorFlag = true;
    }

    if (this.state.reActivateMembers.lastName.trim() === "") {
      lastNameError = "Please enter last name";
      errorFlag = true;
    }
    if (this.state.reActivateMembers.email.trim() === "") {
      emailError = "Please enter email ";
      errorFlag = true;
    }
    if (this.state.reActivateMembers.jobRole.trim() === "") {
      jobTitleError = "Please enter job Title";
      errorFlag = true;
    }
    if (this.state.reActivateMembers.team.length === 0) {
      chooseTeamError = "Please select any team";
      errorFlag = true;
    }

    if (this.state.reActivateMembers.hourlyPay === null) {
      hourlyPayError = "Please enter hourly rate";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        reActivateMemerror: {
          errorReMemFirstName: firstNameError,
          errorReMemLastName: lastNameError,
          errorReMemEmail: emailError,
          errorReMemJobRole: jobTitleError,
          errorReMemChooseTeam: chooseTeamError,
          errorReMemHourlyPay: hourlyPayError,
        },
      });
      return true;
    } else {
      return false;
    }
  };

  updateMemProfile = () => {
    if (this.checkFieldsValidation(this.state.editMemerror)) return;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        first_name: this.state.editMembers.firstName,
        last_name: this.state.editMembers.lastName,
        hourly_pay: this.state.editMembers.hourlyPay,
        job_role: this.state.editMembers.jobRole,
        job_type: this.state.editMembers.jobType,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editMemberAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webEditMemEndPoint + this.state.editMembers.memberId
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMemberEmail = () => {
    if (this.state.newEmailAddress.trim() === "") {
      this.setState({ newEmailAddressError: "Please Enter email address" });
    } else {
      const header = {
        "Content-Type": "application/json",
        token: this.userToken.meta.token,
      };

      const body = {
        data: {
          new_email: this.state.newEmailAddress,
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateMemberEmailAPICallId = requestMessage.messageId;

      //* Adding endpoint

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateMemberEmail + this.state.editMembers.memberId
      );

      //* Adding header
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      //* Adding Request Method

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );
      this.setState({
        isLoading : true
      })
      //* Making Network Request
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  updateMemberPassword = () => {
    if (this.state.newPassword.trim() === "") {
      this.setState({ newPasswordError: "Please Enter password" });
    } else if (
      this.state.newPassword.trim() !== "" &&
      !this.isMatched(this.state.newPassword)
    ) {
      this.setState({ newPasswordError: configJSON.errorPasswordNotValid });
    } else {
      const header = {
        "Content-Type": "application/json",
        token: this.userToken.meta.token,
      };

      const body = {
        data: {
          new_password: this.state.newPassword,
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updatePasswordAPICallId = requestMessage.messageId;

      //* Adding endpoint

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateMemberPassword + this.state.editMembers.memberId
      );

      //* Adding header
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      //* Adding Request Method

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );

      //* Making Network Request
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  isMatched = (inputPassword: string) => {
    let isContainsUpperCase = () => /[A-Z]/.test(inputPassword);
    let isContainsLowerCase = () => /[a-z]/.test(inputPassword);
    let isContainsNumber = () => /\d/.test(inputPassword);
    let isMinLength = () => /.{8,}$/.test(inputPassword);
    if (
      isContainsLowerCase() &&
      isContainsUpperCase() &&
      isContainsNumber() &&
      isMinLength() &&
      inputPassword !== ""
    ) {
      return true;
    }
  };

  deactivateMem = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deActivateMemberAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeactivateMemberEndPoint +
        this.state.deActivateMember.deActivateMemberID
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPut
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  reactivateMem = () => {
    let jobTypeValue;
    if (this.checkReactivateValidation(this.state.reActivateMemerror)) return;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    if (this.state.reActivateMembers.jobType == "owner") {
      jobTypeValue = 0;
    } else if (this.state.reActivateMembers.jobType == "manager") {
      jobTypeValue = 1;
    } else {
      jobTypeValue = 2;
    }

    const body = {
      data: {
        first_name: this.state.reActivateMembers.firstName,
        last_name: this.state.reActivateMembers.lastName,
        email: this.state.reActivateMembers.email,
        choose_team: this.state.reActivateMembers.team,
        job_type: jobTypeValue,
        job_role: this.state.reActivateMembers.jobRole,
        hourly_pay: this.state.reActivateMembers.hourlyPay,
      },
    };

    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reActivateMemberAPICallId = requestMessage.messageId;

    // //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webReactivateMemberEndPoint +
        this.state.reActivateMembers.memberId
    );

    // //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    // //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );

    // //* Making Network Request
   runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  moveMembertoTeam = () => {
    if (this.state.moveTeamData.teamId == "") {
      this.setState({
        moveTeamError: {
          choose_team_error: "Please select the team",
        },
      });
      return;
    }
    this.setState({ moveTeamError: { choose_team_error: "" } });
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let remove_team = "";

    if (
      this.getLastPartOfURL(window.location.pathname) !== "unassigned-members"
    ) {
      remove_team = this.getLastPartOfURL(window.location.pathname);
    }
    
    const httpBody = {
      team_ids: [`${this.state.moveTeamData.teamId}`],
      id: this.state.moveTeamData.memberId,
      remove_team: remove_team,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moveMemberAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webMoveMemberEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterMembersAPI = () => {
    const {
      selectedTeamVal,
      selectedRoleVal,
      selectedTitleVal,
      isDeactivatedActive,
    } = this.state;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const baseURL = configJSON.webFilterMembers;
    const job_roleString = selectedRoleVal.map((role) => `"${role}"`).join(",");
    const job_Type: any = [];
    const deActivateVal = isDeactivatedActive ? isDeactivatedActive : [];
    if (selectedTitleVal.includes("owner")) {
      job_Type.push(0);
    }
    if (selectedTitleVal.includes("manager")) {
      job_Type.push(1);
    }
    if (selectedTitleVal.includes("employee")) {
      job_Type.push(2);
    }

    let url = `${baseURL}&deactivated=${deActivateVal}`;
    if (selectedTeamVal.length > 0) {
      url += `&choose_team=[${selectedTeamVal}]`;
    }
    if (job_roleString != "") {
      url += `&job_role=[${job_roleString}]`;
    }
    if (job_Type.length > 0) {
      url += `&job_type=[${job_Type}]`;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterMemberAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );
    this.setState({isLoading: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  undoactionAPICall = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.undoActionAPICalld = requestMessage.messageId;

    // //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoActionAPIEndPint
    );

    // //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodPost
    );

    // //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  /* Handles all responses for network calls*/
  handleFetchTeamResponse = (responseJson: {
    errors: { token?: string }[];
    data: any[];
  }) => {
    const { errors, data } = responseJson;
    if (errors && errors.length > 0 && errors[0]?.token) {
      sessionStorage.removeItem("userData");
      this.props.navigation.navigate("EmailAccountLogin");
    }
    if (errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }
    this.setState({ teamsList: data });
  };

  handleCreateTeamResponse = (responseJson: {
    failed_teams: string;
    data: [];
  }) => {
    if (responseJson.failed_teams) {
      this.setState({
        error: {
          errornewTeamNameNotValid: {
            message: responseJson.failed_teams,
          },
        },
      });
      return;
    }

    this.createToastNotification(configJSON.webTeamCreatedMessage, checkedIcon);
    this.setState({
      isCreatingTeam: false,
      additionalFields: [{ field: "i1", value: "", id: 0 }],
    });
    this.setState({isCreatingTeam: false})
    this.fetchAllCreatedTeams();
    this.teamFilter();
    if(this.state.isAddingMember){
      this.excludeTeamListAPI(this.state.updatedMemberTeam.memberId) 
    }
  };

  handleUnAssignedTeam = (responseJson: { errors: []; data: [] }) => {
    const { errors, data } = responseJson;
    if (errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }
    if (data.length > 0) {
      this.setState({ unAssignedTeamList: responseJson.data });
    }
  };

  handleInvitedMemResponse = (responseJson: {
    errors: [];
    user: { data: [] };
    members: { data: [] };
  }) => {
    if (responseJson.errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }

    let emptyUser: any[] = [];
    let members: any[] = responseJson.members.data;
    emptyUser.push(...members);
    this.setState({ membersList: emptyUser });
  };

  handleRenameTeamResponse = (responseJson: {
    errors: { message: string }[];
    data: [];
  }) => {
    if (!responseJson.errors) {
      this.createToastNotification(
        configJSON.webTeamRenamedSuccess,
        teamRename
      );
      this.setState({ isRenaming: false });
      this.handle_modalClose();
      this.fetchAllCreatedTeams();
      this.teamFilter();
    } else {
      this.setState({
        error: {
          errornewTeamNameNotValid: {
            message: configJSON.renameTeamError,
          },
        },
      });
    }
  };
  handledeleteTeamResponse = (responseJson: { errors: []; data: [] }) => {
    if (responseJson.errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }
    this.createToastNotification(configJSON.webTeamDeleteSuccess, teamDeleted);
    this.handle_modalClose();
    this.fetchAllCreatedTeams();
    this.unAssignedTeamList();
    this.invitedMemList();
    this.teamFilter();
  };
  handleMultipleDeleteResponse = (responseJson: {
    errors: [];
    message: string;
  }) => {
    if (responseJson.errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }
    this.setState({ multipledeleteIds: [] });
    this.createToastNotification(responseJson.message, teamDeleted);
    this.handle_modalClose();
    this.fetchAllCreatedTeams();
    this.unAssignedTeamList();
    this.teamFilter();
  };
  handleEditMemberResponse = (responseJson: { errors: []; data: [] }) => {
    if (responseJson.errors) {
      return;
    }
    this.createToastNotification(
      configJSON.webMemberEditedSucces,
      memberEdited
    );
    this.setState({ isEditing: false });
    this.handle_modalClose();
    this.invitedMemList();
    this.unAssignedTeamList();
    if (
      this.props.navigation.getParam("teamID") !== undefined &&
      this.props.navigation.getParam("teamID") !== "unassigned-members"
    ) {
      this.fetchMembersByTeam();
    }
    if (this.state.isFiltering) {
      this.filterMembersAPI();
    }
  };

  handledeActivateMemberResponse = (
    responseJson: { errors: []; data: [] },
    toastNotification: string,
    toastIcon: string
  ) => {
    if (responseJson.errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }

    this.createToastNotification(toastNotification, toastIcon);
    this.handle_modalClose();
    this.invitedMemList();
    if (this.state.isFiltering) {
      this.filterMembersAPI();
    }
  };

  handleReactivaterMemberResponse = (
    responseJson: { message: []; errors: [] },
    toastNotification: string,
    toastIcon: string
  ) => {
    if (responseJson.errors) {
      this.createToastNotificationError(
        "An error has occured. Please try again later."
      );
      return;
    }

    this.createToastNotification(toastNotification, toastIcon, true);
    this.handle_modalClose();
    this.invitedMemList();
    this.fetchAllCreatedTeams();
    this.unAssignedTeamList();
    this.teamFilter();
    if (this.state.isFiltering) {
      this.filterMembersAPI();
    }
  };

  handlefetchMemberResponse = (responseJson: {
    errors: [];
    accounts: [];
    data: { accounts: []; team: { title: string } };
  }) => {
    if (responseJson.errors) {
      return;
    }
    let newMembersList = responseJson.data.accounts;
    this.setState({ memberListbyTeam: newMembersList });
    this.setState({ currentTeamName: responseJson.data.team.title });
  };
  handleMoveMemResponse = (responseJson: { error: { message: string } }) => {
    if (responseJson.error) {
      this.setState({ memberTeamExists: responseJson.error.message });
      this.createToastNotificationError(responseJson.error.message);
      return;
    }

    this.createToastNotification(configJSON.webMemberMovedSuccess, memberMoved);
    this.handle_modalClose();
    this.fetchMembersByTeam();
    this.unAssignedTeamList();
  };

  handleEmmailUpdateMember = (responseJson: {
    errors: { message: string }[];
    data: any;
  }) => {
    if (responseJson.errors && responseJson.errors.length > 0) {
      this.setState({
        newEmailAddressError: responseJson.errors[0].message,
      });
    } else {
      this.setState({
        emailSuccessMsg: "An email has been sent",
        isResend: true,
        showTimer: true,
        resendSec: 60,
        disable: true,
        isLoading : false,
      });
    }
  };

  handlePasswordUpdateMember = (responseJson: {
    errors: { message: string }[];
    data: any;
  }) => {
    if (responseJson.errors) return;
    this.createToastNotification("Password updated successfully", checkedIcon);
    this.handle_modalClose();
  };

  handleUpdateMemberTeam = (responseJson: { error: any; data: [] }) => {
    if (responseJson.error) {
      this.setState({
        updateMemError: {
          message: responseJson.error.message,
        },
      });
      this.createToastNotificationError(responseJson.error.message);
    } else {
      this.createToastNotification("Member Added to Team", checkedIcon);
      this.fetchAllCreatedTeams();
      this.invitedMemList();
      this.handle_modalClose();
      this.unAssignedTeamList();
      this.teamFilter();
    }
  };

  handleRemoveMemberfromTeam = (responseJson: { error: any; data: [] }) => {
    if (responseJson.error) return;
    this.handle_modalClose();
    this.createToastNotification("Member removed", memberRevoked);
    this.fetchMembersByTeam();
  };
  handleFilterMmemberResponse = (responseJson: {
    errors: [];
    user: { data: [] };
    members: { data: [] };
  }) => {
    let emptyUser: any[] = [];
    let user: any | undefined = responseJson?.user?.data;
    let members: any[] | undefined = responseJson?.members?.data;
    if (members !== undefined && members.length > 0) {
      emptyUser.push(...members);
    }

    this.setState({ filteredData: emptyUser });
  };
  handleUndoActionAPI = () => {
    this.fetchAllCreatedTeams();
    this.invitedMemList();
    this.fetchMembersByTeam();
    this.unAssignedTeamList();
    this.teamFilter();
  };
  checkForSkip = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.canSkipAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkAcountStatus
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodGet
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSkipButtonClick = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateSkipAPICallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSkip
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPut
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSkipAPIResponse = (responseJson: any) => {
    this.setState({
      showSkipButton: responseJson.data.attributes.can_skip,
    })
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EquizDashboardWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleOpenCart = () => {
    this.setState({ isCartOpen: true });
  };
  handleCloseCart = () => {
    this.setState({ isCartOpen: false });
  };
}
// Customizable Area End