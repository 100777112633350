export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const contactListEmpty = require("../assets/contact_list_empty.png");
export const viewColumn = require("../assets/view_column.svg");
export const sortVariant = require("../assets/sort_variant.svg");
export const close = require("../assets/close.png");
export const new_page = require("../assets/new_page.png");
export const clipboardCheckOutline = require("../assets/clipboard_check_outline.png");
export const productExample = require("../assets/product_example.png");
export const dot = require("../assets/dot.png");
export const star = require("../assets/star.png");
export const uploadImg = require("../assets/upload-icon.png");
export const warningIcon = require("../assets/warning-red.png");
export const sortIcon = require("../assets/sort-icon.png");
export const filterSmIcon = require("../assets/filter-icon.png");
export const checkBoxEmpty = require("../assets/check-empty.png")
export const checkBoxFilled = require("../assets/check-filled.png")
export const deleteGray= require("../assets/delete-gray.png");
export const callMerge= require("../assets/call-merge.png");
export const accountMultiplePlus= require("../assets/account-multiple-plus.png");
export const handshake= require("../assets/handshake.png");
export const emailFast= require("../assets/email-fast.png");
export const fileEdit= require("../assets/file-edit.png");
export const bottle= require("../assets/bottle.png");
export const pencil= require("../assets/pencil-outline.svg");
export const plus= require("../assets/plus.png");
export const paperClip= require("../assets/paper-clip.png");
export const productImg= require("../assets/product-img.png");
export const expand = require("../assets/expand.png");
export const checkBlack = require("../assets/check-black.png");
export const deleteOutline = require("../assets/delete-outline.png");
export const addToOtherGroup = require("../assets/add_to_another_group.png");
export const removeFromGroup = require("../assets/remove_from_group.png");
export const editContactAvatarRound = require("../assets/view_icon.svg");
export const multipleAccount = require("../assets/account-multiple-plus-outline.svg");
export const keyboardBack = require("../assets/keyboard-backspace.svg");
export const vector = require("../assets/account-multiple-plus-outline.png")
export const renamed = require("../assets/account-multiple-plus-outline (1).png");
export const callMergeUndo= require("../assets/call-merge.svg");
export const checkDefault = require("../assets/ic_check_box_outline_blank.png"); 
export const checked = require("../assets/ic_check_box_outline_blank (1).png"); 
export const menu = require("../assets/menu.png");
export const downArrow = require("../assets/down.png");
export const accountPlus = require("../assets/account-multiple-plus-outline-icon-2.svg");
export const accountMulti = require("../assets/account-multiple-plus-outline-icon.svg");
export const deletIcon = require("../assets/delete-outline-icon.svg");
export const handShake = require("../assets/handshake.svg");
export const searchIcon = require("../assets/ic_search.svg");
export const editSuccessIcon = require("../assets/account-edit.svg");
export const createContactIcon = require("../assets/acoountPlus.svg");
export const noteActionIcon = require("../assets/Note-added.svg")
export const checkedIcon = require("../assets/save-draft.svg")

